/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #2196f3;
  --indigo: #1565c0;
  --purple: #9c27b0;
  --pink: #f06292;
  --red: #e53935;
  --orange: #fb8c00;
  --yellow: #fbc02d;
  --green: #66bb6a;
  --teal: #4db6ac;
  --cyan: #81d4fa;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --light-gray: #f7f9f9;
  --dark-viridian: #1c2833;
  --sky: #64b5f6;
  --navy: #0d47a1;
  --berry: #b71c1c;
  --rose: #e91e63;
  --orchid: #ba68c8;
  --blush: #ef5350;
  --scarlet: #d32f2f;
  --carrot: #ef6c00;
  --dark-gray: #202020;
  --primary: #2196f3;
  --secondary: #4db6ac;
  --success: #66bb6a;
  --info: #81d4fa;
  --warning: #fbc02d;
  --danger: #e53935;
  --light: #f7f9f9;
  --dark: #1c2833;
  --indigo: #1565c0;
  --purple: #9c27b0;
  --pink: #f06292;
  --orange: #fb8c00;
  --sky: #64b5f6;
  --navy: #0d47a1;
  --berry: #b71c1c;
  --rose: #e91e63;
  --orchid: #ba68c8;
  --blush: #ef5350;
  --scarlet: #d32f2f;
  --carrot: #ef6c00;
  --dark-gray: #202020;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1280px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  color: #717171;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #2196f3;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #6ab8f7;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: #202020;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 400;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #f4f6f7;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote, .blockquote-2, .blockquote-3 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #a1a1a1;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 100%;
  color: #f06292;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 100%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.1875rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 100%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1276px;
  }
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1280px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1276px;
  }
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}

.row-cols-1 > * {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.row-cols-4 > * {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-md-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1280px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #717171;
}

.table th,
.table td {
  padding: 0.875rem;
  vertical-align: top;
  border-top: 1px solid #e0e0e0;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e0e0e0;
}

.table tbody + tbody {
  border-top: 2px solid #e0e0e0;
}

.table-sm th,
.table-sm td {
  padding: 0.3125rem 0.625rem;
}

.table-bordered {
  border: 1px solid #e0e0e0;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #e0e0e0;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #717171;
  background-color: rgba(0, 0, 0, 0.05);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c1e2fc;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #8cc8f9;
}

.table-hover .table-primary:hover {
  background-color: #a9d7fb;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a9d7fb;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #cdebe8;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #a2d9d4;
}

.table-hover .table-secondary:hover {
  background-color: #bbe4e0;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #bbe4e0;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d4ecd5;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #afdcb2;
}

.table-hover .table-success:hover {
  background-color: #c2e4c4;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #c2e4c4;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #dcf3fe;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #bde9fc;
}

.table-hover .table-info:hover {
  background-color: #c3ebfd;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #c3ebfd;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #feedc4;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fdde92;
}

.table-hover .table-warning:hover {
  background-color: #fee5ab;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fee5ab;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f8c8c6;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f19896;
}

.table-hover .table-danger:hover {
  background-color: #f5b2af;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f5b2af;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfd;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #f0f0f0;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #f0f0f0;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bfc3c6;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #898f95;
}

.table-hover .table-dark:hover {
  background-color: #b2b6ba;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b2b6ba;
}

.table-indigo,
.table-indigo > th,
.table-indigo > td {
  background-color: #bdd4ed;
}

.table-indigo th,
.table-indigo td,
.table-indigo thead th,
.table-indigo tbody + tbody {
  border-color: #85afde;
}

.table-hover .table-indigo:hover {
  background-color: #a9c7e8;
}

.table-hover .table-indigo:hover > td,
.table-hover .table-indigo:hover > th {
  background-color: #a9c7e8;
}

.table-purple,
.table-purple > th,
.table-purple > td {
  background-color: #e3c3e9;
}

.table-purple th,
.table-purple td,
.table-purple thead th,
.table-purple tbody + tbody {
  border-color: #cc8fd6;
}

.table-hover .table-purple:hover {
  background-color: #dab0e2;
}

.table-hover .table-purple:hover > td,
.table-hover .table-purple:hover > th {
  background-color: #dab0e2;
}

.table-pink,
.table-pink > th,
.table-pink > td {
  background-color: #fbd3e0;
}

.table-pink th,
.table-pink td,
.table-pink thead th,
.table-pink tbody + tbody {
  border-color: #f7adc6;
}

.table-hover .table-pink:hover {
  background-color: #f9bcd0;
}

.table-hover .table-pink:hover > td,
.table-hover .table-pink:hover > th {
  background-color: #f9bcd0;
}

.table-orange,
.table-orange > th,
.table-orange > td {
  background-color: #fedfb8;
}

.table-orange th,
.table-orange td,
.table-orange thead th,
.table-orange tbody + tbody {
  border-color: #fdc37a;
}

.table-hover .table-orange:hover {
  background-color: #fed49f;
}

.table-hover .table-orange:hover > td,
.table-hover .table-orange:hover > th {
  background-color: #fed49f;
}

.table-sky,
.table-sky > th,
.table-sky > td {
  background-color: #d4eafc;
}

.table-sky th,
.table-sky td,
.table-sky thead th,
.table-sky tbody + tbody {
  border-color: #aed9fa;
}

.table-hover .table-sky:hover {
  background-color: #bcdefa;
}

.table-hover .table-sky:hover > td,
.table-hover .table-sky:hover > th {
  background-color: #bcdefa;
}

.table-navy,
.table-navy > th,
.table-navy > td {
  background-color: #bbcbe5;
}

.table-navy th,
.table-navy td,
.table-navy thead th,
.table-navy tbody + tbody {
  border-color: #819fce;
}

.table-hover .table-navy:hover {
  background-color: #a9bdde;
}

.table-hover .table-navy:hover > td,
.table-hover .table-navy:hover > th {
  background-color: #a9bdde;
}

.table-berry,
.table-berry > th,
.table-berry > td {
  background-color: #ebbfbf;
}

.table-berry th,
.table-berry td,
.table-berry thead th,
.table-berry tbody + tbody {
  border-color: #da8989;
}

.table-hover .table-berry:hover {
  background-color: #e5acac;
}

.table-hover .table-berry:hover > td,
.table-hover .table-berry:hover > th {
  background-color: #e5acac;
}

.table-rose,
.table-rose > th,
.table-rose > td {
  background-color: #f9c0d3;
}

.table-rose th,
.table-rose td,
.table-rose thead th,
.table-rose tbody + tbody {
  border-color: #f48aae;
}

.table-hover .table-rose:hover {
  background-color: #f7a9c3;
}

.table-hover .table-rose:hover > td,
.table-hover .table-rose:hover > th {
  background-color: #f7a9c3;
}

.table-orchid,
.table-orchid > th,
.table-orchid > td {
  background-color: #ecd5f0;
}

.table-orchid th,
.table-orchid td,
.table-orchid thead th,
.table-orchid tbody + tbody {
  border-color: #dbb0e2;
}

.table-hover .table-orchid:hover {
  background-color: #e4c2e9;
}

.table-hover .table-orchid:hover > td,
.table-hover .table-orchid:hover > th {
  background-color: #e4c2e9;
}

.table-blush,
.table-blush > th,
.table-blush > td {
  background-color: #fbcfce;
}

.table-blush th,
.table-blush td,
.table-blush thead th,
.table-blush tbody + tbody {
  border-color: #f7a6a4;
}

.table-hover .table-blush:hover {
  background-color: #f9b8b6;
}

.table-hover .table-blush:hover > td,
.table-hover .table-blush:hover > th {
  background-color: #f9b8b6;
}

.table-scarlet,
.table-scarlet > th,
.table-scarlet > td {
  background-color: #f3c5c5;
}

.table-scarlet th,
.table-scarlet td,
.table-scarlet thead th,
.table-scarlet tbody + tbody {
  border-color: #e89393;
}

.table-hover .table-scarlet:hover {
  background-color: #efb0b0;
}

.table-hover .table-scarlet:hover > td,
.table-hover .table-scarlet:hover > th {
  background-color: #efb0b0;
}

.table-carrot,
.table-carrot > th,
.table-carrot > td {
  background-color: #fbd6b8;
}

.table-carrot th,
.table-carrot td,
.table-carrot thead th,
.table-carrot tbody + tbody {
  border-color: #f7b37a;
}

.table-hover .table-carrot:hover {
  background-color: #fac8a0;
}

.table-hover .table-carrot:hover > td,
.table-hover .table-carrot:hover > th {
  background-color: #fac8a0;
}

.table-dark-gray,
.table-dark-gray > th,
.table-dark-gray > td {
  background-color: #c1c1c1;
}

.table-dark-gray th,
.table-dark-gray td,
.table-dark-gray thead th,
.table-dark-gray tbody + tbody {
  border-color: #8b8b8b;
}

.table-hover .table-dark-gray:hover {
  background-color: #b4b4b4;
}

.table-hover .table-dark-gray:hover > td,
.table-hover .table-dark-gray:hover > th {
  background-color: #b4b4b4;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.05);
}

.table .thead-dark th {
  color: #fff;
  background-color: #1c2833;
  border-color: #2a3b4c;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #e0e0e0;
}

.table-dark {
  color: #fff;
  background-color: #1c2833;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #2a3b4c;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1279.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.7em + 0.75rem + 4px);
  padding: 0.375rem 1.125rem;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.7;
  color: #717171;
  background-color: transparent;
  background-clip: padding-box;
  border: 2px solid #e0e0e0;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #717171;
}

.form-control:focus {
  color: #717171;
  background-color: transparent;
  border-color: #2196f3;
  outline: 0;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.form-control::-webkit-input-placeholder {
  color: #717171;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #717171;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #717171;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #717171;
  opacity: 1;
}

.form-control::placeholder {
  color: #717171;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #717171;
  background-color: transparent;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 2px);
  padding-bottom: calc(0.375rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.7;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 2px);
  padding-bottom: calc(0.5rem + 2px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 2px);
  padding-bottom: calc(0.25rem + 2px);
  font-size: 0.6875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.75rem;
  line-height: 1.7;
  color: #717171;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 4px);
  padding: 0.25rem 0.875rem;
  font-size: 0.6875rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 4px);
  padding: 0.5rem 1.375rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.3125rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #66bb6a;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5625rem 0.9375rem;
  margin-top: .1rem;
  font-size: 0.75rem;
  line-height: 1.7;
  color: #212529;
  background-color: #66bb6a;
  border-radius: 0.1875rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #66bb6a;
  padding-right: calc(1.7em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2366bb6a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.425em + 0.1875rem) center;
  background-size: calc(0.85em + 0.375rem) calc(0.85em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #66bb6a;
  box-shadow: 0 0 0 0.2rem rgba(102, 187, 106, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.7em + 0.75rem);
  background-position: top calc(0.425em + 0.1875rem) right calc(0.425em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #66bb6a;
  padding-right: calc(0.75em + 2.6875rem);
  background: url("../svg/caret-down.svg") no-repeat right 1.125rem center/11px 14px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2366bb6a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") transparent no-repeat center right 2.125rem/calc(0.85em + 0.375rem) calc(0.85em + 0.375rem);
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #66bb6a;
  box-shadow: 0 0 0 0.2rem rgba(102, 187, 106, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #66bb6a;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #66bb6a;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #66bb6a;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #89cb8c;
  background-color: #89cb8c;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(102, 187, 106, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #66bb6a;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #66bb6a;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #66bb6a;
  box-shadow: 0 0 0 0.2rem rgba(102, 187, 106, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e53935;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5625rem 0.9375rem;
  margin-top: .1rem;
  font-size: 0.75rem;
  line-height: 1.7;
  color: #fff;
  background-color: #e53935;
  border-radius: 0.1875rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #e53935;
  padding-right: calc(1.7em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e53935' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e53935' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.425em + 0.1875rem) center;
  background-size: calc(0.85em + 0.375rem) calc(0.85em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #e53935;
  box-shadow: 0 0 0 0.2rem rgba(229, 57, 53, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.7em + 0.75rem);
  background-position: top calc(0.425em + 0.1875rem) right calc(0.425em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #e53935;
  padding-right: calc(0.75em + 2.6875rem);
  background: url("../svg/caret-down.svg") no-repeat right 1.125rem center/11px 14px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e53935' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e53935' stroke='none'/%3e%3c/svg%3e") transparent no-repeat center right 2.125rem/calc(0.85em + 0.375rem) calc(0.85em + 0.375rem);
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #e53935;
  box-shadow: 0 0 0 0.2rem rgba(229, 57, 53, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #e53935;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #e53935;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #e53935;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #eb6562;
  background-color: #eb6562;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(229, 57, 53, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e53935;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e53935;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e53935;
  box-shadow: 0 0 0 0.2rem rgba(229, 57, 53, 0.25);
}

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 600;
  color: #717171;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  padding: 0.625rem 1.625rem;
  font-size: 0.75rem;
  line-height: 1.6;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #717171;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0c83e2;
  border-color: #0c7cd5;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0c83e2;
  border-color: #0c7cd5;
  box-shadow: 0 0 0 0.2rem rgba(66, 166, 245, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0c7cd5;
  border-color: #0b75c9;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 166, 245, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #4db6ac;
  border-color: #4db6ac;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #409d94;
  border-color: #3c948b;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #409d94;
  border-color: #3c948b;
  box-shadow: 0 0 0 0.2rem rgba(104, 193, 184, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #4db6ac;
  border-color: #4db6ac;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #3c948b;
  border-color: #398a83;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(104, 193, 184, 0.5);
}

.btn-success {
  color: #212529;
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.btn-success:hover {
  color: #fff;
  background-color: #4dae52;
  border-color: #49a54e;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #4dae52;
  border-color: #49a54e;
  box-shadow: 0 0 0 0.2rem rgba(92, 165, 96, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #212529;
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #49a54e;
  border-color: #459c49;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 165, 96, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #81d4fa;
  border-color: #81d4fa;
}

.btn-info:hover {
  color: #212529;
  background-color: #5cc7f9;
  border-color: #50c3f8;
}

.btn-info:focus, .btn-info.focus {
  color: #212529;
  background-color: #5cc7f9;
  border-color: #50c3f8;
  box-shadow: 0 0 0 0.2rem rgba(115, 186, 219, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #212529;
  background-color: #81d4fa;
  border-color: #81d4fa;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #212529;
  background-color: #50c3f8;
  border-color: #44bff8;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(115, 186, 219, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #fbc02d;
  border-color: #fbc02d;
}

.btn-warning:hover {
  color: #212529;
  background-color: #fab507;
  border-color: #f0ad05;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #fab507;
  border-color: #f0ad05;
  box-shadow: 0 0 0 0.2rem rgba(218, 169, 44, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #fbc02d;
  border-color: #fbc02d;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #f0ad05;
  border-color: #e4a404;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 169, 44, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #e53935;
  border-color: #e53935;
}

.btn-danger:hover {
  color: #fff;
  background-color: #d8201c;
  border-color: #cd1e1a;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #d8201c;
  border-color: #cd1e1a;
  box-shadow: 0 0 0 0.2rem rgba(233, 87, 83, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #e53935;
  border-color: #e53935;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #cd1e1a;
  border-color: #c11d19;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 87, 83, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f7f9f9;
  border-color: #f7f9f9;
}

.btn-light:hover {
  color: #212529;
  background-color: #e1e9e9;
  border-color: #dae3e3;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e1e9e9;
  border-color: #dae3e3;
  box-shadow: 0 0 0 0.2rem rgba(215, 217, 218, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f7f9f9;
  border-color: #f7f9f9;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae3e3;
  border-color: #d3dede;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 217, 218, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #1c2833;
  border-color: #1c2833;
}

.btn-dark:hover {
  color: #fff;
  background-color: #0e151a;
  border-color: #0a0e12;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #0e151a;
  border-color: #0a0e12;
  box-shadow: 0 0 0 0.2rem rgba(62, 72, 82, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #1c2833;
  border-color: #1c2833;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #0a0e12;
  border-color: #05080a;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(62, 72, 82, 0.5);
}

.btn-indigo {
  color: #fff;
  background-color: #1565c0;
  border-color: #1565c0;
}

.btn-indigo:hover {
  color: #fff;
  background-color: #11539e;
  border-color: #104d92;
}

.btn-indigo:focus, .btn-indigo.focus {
  color: #fff;
  background-color: #11539e;
  border-color: #104d92;
  box-shadow: 0 0 0 0.2rem rgba(56, 124, 201, 0.5);
}

.btn-indigo.disabled, .btn-indigo:disabled {
  color: #fff;
  background-color: #1565c0;
  border-color: #1565c0;
}

.btn-indigo:not(:disabled):not(.disabled):active, .btn-indigo:not(:disabled):not(.disabled).active,
.show > .btn-indigo.dropdown-toggle {
  color: #fff;
  background-color: #104d92;
  border-color: #0f4787;
}

.btn-indigo:not(:disabled):not(.disabled):active:focus, .btn-indigo:not(:disabled):not(.disabled).active:focus,
.show > .btn-indigo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 124, 201, 0.5);
}

.btn-purple {
  color: #fff;
  background-color: #9c27b0;
  border-color: #9c27b0;
}

.btn-purple:hover {
  color: #fff;
  background-color: #802091;
  border-color: #771e86;
}

.btn-purple:focus, .btn-purple.focus {
  color: #fff;
  background-color: #802091;
  border-color: #771e86;
  box-shadow: 0 0 0 0.2rem rgba(171, 71, 188, 0.5);
}

.btn-purple.disabled, .btn-purple:disabled {
  color: #fff;
  background-color: #9c27b0;
  border-color: #9c27b0;
}

.btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
.show > .btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #771e86;
  border-color: #6e1b7c;
}

.btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(171, 71, 188, 0.5);
}

.btn-pink {
  color: #fff;
  background-color: #f06292;
  border-color: #f06292;
}

.btn-pink:hover {
  color: #fff;
  background-color: #ed3f7a;
  border-color: #ec3372;
}

.btn-pink:focus, .btn-pink.focus {
  color: #fff;
  background-color: #ed3f7a;
  border-color: #ec3372;
  box-shadow: 0 0 0 0.2rem rgba(242, 122, 162, 0.5);
}

.btn-pink.disabled, .btn-pink:disabled {
  color: #fff;
  background-color: #f06292;
  border-color: #f06292;
}

.btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled).active,
.show > .btn-pink.dropdown-toggle {
  color: #fff;
  background-color: #ec3372;
  border-color: #ea286a;
}

.btn-pink:not(:disabled):not(.disabled):active:focus, .btn-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 122, 162, 0.5);
}

.btn-orange {
  color: #212529;
  background-color: #fb8c00;
  border-color: #fb8c00;
}

.btn-orange:hover {
  color: #fff;
  background-color: #d57700;
  border-color: #c87000;
}

.btn-orange:focus, .btn-orange.focus {
  color: #fff;
  background-color: #d57700;
  border-color: #c87000;
  box-shadow: 0 0 0 0.2rem rgba(218, 125, 6, 0.5);
}

.btn-orange.disabled, .btn-orange:disabled {
  color: #212529;
  background-color: #fb8c00;
  border-color: #fb8c00;
}

.btn-orange:not(:disabled):not(.disabled):active, .btn-orange:not(:disabled):not(.disabled).active,
.show > .btn-orange.dropdown-toggle {
  color: #fff;
  background-color: #c87000;
  border-color: #bb6800;
}

.btn-orange:not(:disabled):not(.disabled):active:focus, .btn-orange:not(:disabled):not(.disabled).active:focus,
.show > .btn-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 125, 6, 0.5);
}

.btn-sky {
  color: #212529;
  background-color: #64b5f6;
  border-color: #64b5f6;
}

.btn-sky:hover {
  color: #fff;
  background-color: #40a4f4;
  border-color: #349ef3;
}

.btn-sky:focus, .btn-sky.focus {
  color: #fff;
  background-color: #40a4f4;
  border-color: #349ef3;
  box-shadow: 0 0 0 0.2rem rgba(90, 159, 215, 0.5);
}

.btn-sky.disabled, .btn-sky:disabled {
  color: #212529;
  background-color: #64b5f6;
  border-color: #64b5f6;
}

.btn-sky:not(:disabled):not(.disabled):active, .btn-sky:not(:disabled):not(.disabled).active,
.show > .btn-sky.dropdown-toggle {
  color: #fff;
  background-color: #349ef3;
  border-color: #2898f3;
}

.btn-sky:not(:disabled):not(.disabled):active:focus, .btn-sky:not(:disabled):not(.disabled).active:focus,
.show > .btn-sky.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(90, 159, 215, 0.5);
}

.btn-navy {
  color: #fff;
  background-color: #0d47a1;
  border-color: #0d47a1;
}

.btn-navy:hover {
  color: #fff;
  background-color: #0a377e;
  border-color: #093272;
}

.btn-navy:focus, .btn-navy.focus {
  color: #fff;
  background-color: #0a377e;
  border-color: #093272;
  box-shadow: 0 0 0 0.2rem rgba(49, 99, 175, 0.5);
}

.btn-navy.disabled, .btn-navy:disabled {
  color: #fff;
  background-color: #0d47a1;
  border-color: #0d47a1;
}

.btn-navy:not(:disabled):not(.disabled):active, .btn-navy:not(:disabled):not(.disabled).active,
.show > .btn-navy.dropdown-toggle {
  color: #fff;
  background-color: #093272;
  border-color: #082d66;
}

.btn-navy:not(:disabled):not(.disabled):active:focus, .btn-navy:not(:disabled):not(.disabled).active:focus,
.show > .btn-navy.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 99, 175, 0.5);
}

.btn-berry {
  color: #fff;
  background-color: #b71c1c;
  border-color: #b71c1c;
}

.btn-berry:hover {
  color: #fff;
  background-color: #961717;
  border-color: #8b1515;
}

.btn-berry:focus, .btn-berry.focus {
  color: #fff;
  background-color: #961717;
  border-color: #8b1515;
  box-shadow: 0 0 0 0.2rem rgba(194, 62, 62, 0.5);
}

.btn-berry.disabled, .btn-berry:disabled {
  color: #fff;
  background-color: #b71c1c;
  border-color: #b71c1c;
}

.btn-berry:not(:disabled):not(.disabled):active, .btn-berry:not(:disabled):not(.disabled).active,
.show > .btn-berry.dropdown-toggle {
  color: #fff;
  background-color: #8b1515;
  border-color: #801414;
}

.btn-berry:not(:disabled):not(.disabled):active:focus, .btn-berry:not(:disabled):not(.disabled).active:focus,
.show > .btn-berry.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(194, 62, 62, 0.5);
}

.btn-rose {
  color: #fff;
  background-color: #e91e63;
  border-color: #e91e63;
}

.btn-rose:hover {
  color: #fff;
  background-color: #cd1453;
  border-color: #c1134e;
}

.btn-rose:focus, .btn-rose.focus {
  color: #fff;
  background-color: #cd1453;
  border-color: #c1134e;
  box-shadow: 0 0 0 0.2rem rgba(236, 64, 122, 0.5);
}

.btn-rose.disabled, .btn-rose:disabled {
  color: #fff;
  background-color: #e91e63;
  border-color: #e91e63;
}

.btn-rose:not(:disabled):not(.disabled):active, .btn-rose:not(:disabled):not(.disabled).active,
.show > .btn-rose.dropdown-toggle {
  color: #fff;
  background-color: #c1134e;
  border-color: #b61249;
}

.btn-rose:not(:disabled):not(.disabled):active:focus, .btn-rose:not(:disabled):not(.disabled).active:focus,
.show > .btn-rose.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(236, 64, 122, 0.5);
}

.btn-orchid {
  color: #fff;
  background-color: #ba68c8;
  border-color: #ba68c8;
}

.btn-orchid:hover {
  color: #fff;
  background-color: #ad4cbe;
  border-color: #a844b9;
}

.btn-orchid:focus, .btn-orchid.focus {
  color: #fff;
  background-color: #ad4cbe;
  border-color: #a844b9;
  box-shadow: 0 0 0 0.2rem rgba(196, 127, 208, 0.5);
}

.btn-orchid.disabled, .btn-orchid:disabled {
  color: #fff;
  background-color: #ba68c8;
  border-color: #ba68c8;
}

.btn-orchid:not(:disabled):not(.disabled):active, .btn-orchid:not(:disabled):not(.disabled).active,
.show > .btn-orchid.dropdown-toggle {
  color: #fff;
  background-color: #a844b9;
  border-color: #a040b0;
}

.btn-orchid:not(:disabled):not(.disabled):active:focus, .btn-orchid:not(:disabled):not(.disabled).active:focus,
.show > .btn-orchid.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 127, 208, 0.5);
}

.btn-blush {
  color: #fff;
  background-color: #ef5350;
  border-color: #ef5350;
}

.btn-blush:hover {
  color: #fff;
  background-color: #ec312d;
  border-color: #eb2521;
}

.btn-blush:focus, .btn-blush.focus {
  color: #fff;
  background-color: #ec312d;
  border-color: #eb2521;
  box-shadow: 0 0 0 0.2rem rgba(241, 109, 106, 0.5);
}

.btn-blush.disabled, .btn-blush:disabled {
  color: #fff;
  background-color: #ef5350;
  border-color: #ef5350;
}

.btn-blush:not(:disabled):not(.disabled):active, .btn-blush:not(:disabled):not(.disabled).active,
.show > .btn-blush.dropdown-toggle {
  color: #fff;
  background-color: #eb2521;
  border-color: #ea1a16;
}

.btn-blush:not(:disabled):not(.disabled):active:focus, .btn-blush:not(:disabled):not(.disabled).active:focus,
.show > .btn-blush.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 109, 106, 0.5);
}

.btn-scarlet {
  color: #fff;
  background-color: #d32f2f;
  border-color: #d32f2f;
}

.btn-scarlet:hover {
  color: #fff;
  background-color: #b52626;
  border-color: #ab2424;
}

.btn-scarlet:focus, .btn-scarlet.focus {
  color: #fff;
  background-color: #b52626;
  border-color: #ab2424;
  box-shadow: 0 0 0 0.2rem rgba(218, 78, 78, 0.5);
}

.btn-scarlet.disabled, .btn-scarlet:disabled {
  color: #fff;
  background-color: #d32f2f;
  border-color: #d32f2f;
}

.btn-scarlet:not(:disabled):not(.disabled):active, .btn-scarlet:not(:disabled):not(.disabled).active,
.show > .btn-scarlet.dropdown-toggle {
  color: #fff;
  background-color: #ab2424;
  border-color: #a02222;
}

.btn-scarlet:not(:disabled):not(.disabled):active:focus, .btn-scarlet:not(:disabled):not(.disabled).active:focus,
.show > .btn-scarlet.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 78, 78, 0.5);
}

.btn-carrot {
  color: #fff;
  background-color: #ef6c00;
  border-color: #ef6c00;
}

.btn-carrot:hover {
  color: #fff;
  background-color: #c95b00;
  border-color: #bc5500;
}

.btn-carrot:focus, .btn-carrot.focus {
  color: #fff;
  background-color: #c95b00;
  border-color: #bc5500;
  box-shadow: 0 0 0 0.2rem rgba(241, 130, 38, 0.5);
}

.btn-carrot.disabled, .btn-carrot:disabled {
  color: #fff;
  background-color: #ef6c00;
  border-color: #ef6c00;
}

.btn-carrot:not(:disabled):not(.disabled):active, .btn-carrot:not(:disabled):not(.disabled).active,
.show > .btn-carrot.dropdown-toggle {
  color: #fff;
  background-color: #bc5500;
  border-color: #af4f00;
}

.btn-carrot:not(:disabled):not(.disabled):active:focus, .btn-carrot:not(:disabled):not(.disabled).active:focus,
.show > .btn-carrot.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 130, 38, 0.5);
}

.btn-dark-gray {
  color: #fff;
  background-color: #202020;
  border-color: #202020;
}

.btn-dark-gray:hover {
  color: #fff;
  background-color: #0d0d0d;
  border-color: #070707;
}

.btn-dark-gray:focus, .btn-dark-gray.focus {
  color: #fff;
  background-color: #0d0d0d;
  border-color: #070707;
  box-shadow: 0 0 0 0.2rem rgba(65, 65, 65, 0.5);
}

.btn-dark-gray.disabled, .btn-dark-gray:disabled {
  color: #fff;
  background-color: #202020;
  border-color: #202020;
}

.btn-dark-gray:not(:disabled):not(.disabled):active, .btn-dark-gray:not(:disabled):not(.disabled).active,
.show > .btn-dark-gray.dropdown-toggle {
  color: #fff;
  background-color: #070707;
  border-color: black;
}

.btn-dark-gray:not(:disabled):not(.disabled):active:focus, .btn-dark-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 65, 65, 0.5);
}

.btn-outline-primary {
  color: #2196f3;
  border-color: #2196f3;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #2196f3;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.5);
}

.btn-outline-secondary {
  color: #4db6ac;
  border-color: #4db6ac;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #4db6ac;
  border-color: #4db6ac;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 182, 172, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #4db6ac;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #4db6ac;
  border-color: #4db6ac;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 182, 172, 0.5);
}

.btn-outline-success {
  color: #66bb6a;
  border-color: #66bb6a;
}

.btn-outline-success:hover {
  color: #212529;
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 187, 106, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #66bb6a;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #212529;
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(102, 187, 106, 0.5);
}

.btn-outline-info {
  color: #81d4fa;
  border-color: #81d4fa;
}

.btn-outline-info:hover {
  color: #212529;
  background-color: #81d4fa;
  border-color: #81d4fa;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(129, 212, 250, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #81d4fa;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #81d4fa;
  border-color: #81d4fa;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(129, 212, 250, 0.5);
}

.btn-outline-warning {
  color: #fbc02d;
  border-color: #fbc02d;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #fbc02d;
  border-color: #fbc02d;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 192, 45, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #fbc02d;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #fbc02d;
  border-color: #fbc02d;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 192, 45, 0.5);
}

.btn-outline-danger {
  color: #e53935;
  border-color: #e53935;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #e53935;
  border-color: #e53935;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 57, 53, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #e53935;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #e53935;
  border-color: #e53935;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 57, 53, 0.5);
}

.btn-outline-light {
  color: #f7f9f9;
  border-color: #f7f9f9;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f7f9f9;
  border-color: #f7f9f9;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 249, 249, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f7f9f9;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f7f9f9;
  border-color: #f7f9f9;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 249, 249, 0.5);
}

.btn-outline-dark {
  color: #1c2833;
  border-color: #1c2833;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #1c2833;
  border-color: #1c2833;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 40, 51, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #1c2833;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #1c2833;
  border-color: #1c2833;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 40, 51, 0.5);
}

.btn-outline-indigo {
  color: #1565c0;
  border-color: #1565c0;
}

.btn-outline-indigo:hover {
  color: #fff;
  background-color: #1565c0;
  border-color: #1565c0;
}

.btn-outline-indigo:focus, .btn-outline-indigo.focus {
  box-shadow: 0 0 0 0.2rem rgba(21, 101, 192, 0.5);
}

.btn-outline-indigo.disabled, .btn-outline-indigo:disabled {
  color: #1565c0;
  background-color: transparent;
}

.btn-outline-indigo:not(:disabled):not(.disabled):active, .btn-outline-indigo:not(:disabled):not(.disabled).active,
.show > .btn-outline-indigo.dropdown-toggle {
  color: #fff;
  background-color: #1565c0;
  border-color: #1565c0;
}

.btn-outline-indigo:not(:disabled):not(.disabled):active:focus, .btn-outline-indigo:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-indigo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(21, 101, 192, 0.5);
}

.btn-outline-purple {
  color: #9c27b0;
  border-color: #9c27b0;
}

.btn-outline-purple:hover {
  color: #fff;
  background-color: #9c27b0;
  border-color: #9c27b0;
}

.btn-outline-purple:focus, .btn-outline-purple.focus {
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.5);
}

.btn-outline-purple.disabled, .btn-outline-purple:disabled {
  color: #9c27b0;
  background-color: transparent;
}

.btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active,
.show > .btn-outline-purple.dropdown-toggle {
  color: #fff;
  background-color: #9c27b0;
  border-color: #9c27b0;
}

.btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.5);
}

.btn-outline-pink {
  color: #f06292;
  border-color: #f06292;
}

.btn-outline-pink:hover {
  color: #fff;
  background-color: #f06292;
  border-color: #f06292;
}

.btn-outline-pink:focus, .btn-outline-pink.focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 98, 146, 0.5);
}

.btn-outline-pink.disabled, .btn-outline-pink:disabled {
  color: #f06292;
  background-color: transparent;
}

.btn-outline-pink:not(:disabled):not(.disabled):active, .btn-outline-pink:not(:disabled):not(.disabled).active,
.show > .btn-outline-pink.dropdown-toggle {
  color: #fff;
  background-color: #f06292;
  border-color: #f06292;
}

.btn-outline-pink:not(:disabled):not(.disabled):active:focus, .btn-outline-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 98, 146, 0.5);
}

.btn-outline-orange {
  color: #fb8c00;
  border-color: #fb8c00;
}

.btn-outline-orange:hover {
  color: #212529;
  background-color: #fb8c00;
  border-color: #fb8c00;
}

.btn-outline-orange:focus, .btn-outline-orange.focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 140, 0, 0.5);
}

.btn-outline-orange.disabled, .btn-outline-orange:disabled {
  color: #fb8c00;
  background-color: transparent;
}

.btn-outline-orange:not(:disabled):not(.disabled):active, .btn-outline-orange:not(:disabled):not(.disabled).active,
.show > .btn-outline-orange.dropdown-toggle {
  color: #212529;
  background-color: #fb8c00;
  border-color: #fb8c00;
}

.btn-outline-orange:not(:disabled):not(.disabled):active:focus, .btn-outline-orange:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 140, 0, 0.5);
}

.btn-outline-sky {
  color: #64b5f6;
  border-color: #64b5f6;
}

.btn-outline-sky:hover {
  color: #212529;
  background-color: #64b5f6;
  border-color: #64b5f6;
}

.btn-outline-sky:focus, .btn-outline-sky.focus {
  box-shadow: 0 0 0 0.2rem rgba(100, 181, 246, 0.5);
}

.btn-outline-sky.disabled, .btn-outline-sky:disabled {
  color: #64b5f6;
  background-color: transparent;
}

.btn-outline-sky:not(:disabled):not(.disabled):active, .btn-outline-sky:not(:disabled):not(.disabled).active,
.show > .btn-outline-sky.dropdown-toggle {
  color: #212529;
  background-color: #64b5f6;
  border-color: #64b5f6;
}

.btn-outline-sky:not(:disabled):not(.disabled):active:focus, .btn-outline-sky:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-sky.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(100, 181, 246, 0.5);
}

.btn-outline-navy {
  color: #0d47a1;
  border-color: #0d47a1;
}

.btn-outline-navy:hover {
  color: #fff;
  background-color: #0d47a1;
  border-color: #0d47a1;
}

.btn-outline-navy:focus, .btn-outline-navy.focus {
  box-shadow: 0 0 0 0.2rem rgba(13, 71, 161, 0.5);
}

.btn-outline-navy.disabled, .btn-outline-navy:disabled {
  color: #0d47a1;
  background-color: transparent;
}

.btn-outline-navy:not(:disabled):not(.disabled):active, .btn-outline-navy:not(:disabled):not(.disabled).active,
.show > .btn-outline-navy.dropdown-toggle {
  color: #fff;
  background-color: #0d47a1;
  border-color: #0d47a1;
}

.btn-outline-navy:not(:disabled):not(.disabled):active:focus, .btn-outline-navy:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-navy.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(13, 71, 161, 0.5);
}

.btn-outline-berry {
  color: #b71c1c;
  border-color: #b71c1c;
}

.btn-outline-berry:hover {
  color: #fff;
  background-color: #b71c1c;
  border-color: #b71c1c;
}

.btn-outline-berry:focus, .btn-outline-berry.focus {
  box-shadow: 0 0 0 0.2rem rgba(183, 28, 28, 0.5);
}

.btn-outline-berry.disabled, .btn-outline-berry:disabled {
  color: #b71c1c;
  background-color: transparent;
}

.btn-outline-berry:not(:disabled):not(.disabled):active, .btn-outline-berry:not(:disabled):not(.disabled).active,
.show > .btn-outline-berry.dropdown-toggle {
  color: #fff;
  background-color: #b71c1c;
  border-color: #b71c1c;
}

.btn-outline-berry:not(:disabled):not(.disabled):active:focus, .btn-outline-berry:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-berry.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(183, 28, 28, 0.5);
}

.btn-outline-rose {
  color: #e91e63;
  border-color: #e91e63;
}

.btn-outline-rose:hover {
  color: #fff;
  background-color: #e91e63;
  border-color: #e91e63;
}

.btn-outline-rose:focus, .btn-outline-rose.focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5);
}

.btn-outline-rose.disabled, .btn-outline-rose:disabled {
  color: #e91e63;
  background-color: transparent;
}

.btn-outline-rose:not(:disabled):not(.disabled):active, .btn-outline-rose:not(:disabled):not(.disabled).active,
.show > .btn-outline-rose.dropdown-toggle {
  color: #fff;
  background-color: #e91e63;
  border-color: #e91e63;
}

.btn-outline-rose:not(:disabled):not(.disabled):active:focus, .btn-outline-rose:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-rose.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5);
}

.btn-outline-orchid {
  color: #ba68c8;
  border-color: #ba68c8;
}

.btn-outline-orchid:hover {
  color: #fff;
  background-color: #ba68c8;
  border-color: #ba68c8;
}

.btn-outline-orchid:focus, .btn-outline-orchid.focus {
  box-shadow: 0 0 0 0.2rem rgba(186, 104, 200, 0.5);
}

.btn-outline-orchid.disabled, .btn-outline-orchid:disabled {
  color: #ba68c8;
  background-color: transparent;
}

.btn-outline-orchid:not(:disabled):not(.disabled):active, .btn-outline-orchid:not(:disabled):not(.disabled).active,
.show > .btn-outline-orchid.dropdown-toggle {
  color: #fff;
  background-color: #ba68c8;
  border-color: #ba68c8;
}

.btn-outline-orchid:not(:disabled):not(.disabled):active:focus, .btn-outline-orchid:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-orchid.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(186, 104, 200, 0.5);
}

.btn-outline-blush {
  color: #ef5350;
  border-color: #ef5350;
}

.btn-outline-blush:hover {
  color: #fff;
  background-color: #ef5350;
  border-color: #ef5350;
}

.btn-outline-blush:focus, .btn-outline-blush.focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 83, 80, 0.5);
}

.btn-outline-blush.disabled, .btn-outline-blush:disabled {
  color: #ef5350;
  background-color: transparent;
}

.btn-outline-blush:not(:disabled):not(.disabled):active, .btn-outline-blush:not(:disabled):not(.disabled).active,
.show > .btn-outline-blush.dropdown-toggle {
  color: #fff;
  background-color: #ef5350;
  border-color: #ef5350;
}

.btn-outline-blush:not(:disabled):not(.disabled):active:focus, .btn-outline-blush:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-blush.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 83, 80, 0.5);
}

.btn-outline-scarlet {
  color: #d32f2f;
  border-color: #d32f2f;
}

.btn-outline-scarlet:hover {
  color: #fff;
  background-color: #d32f2f;
  border-color: #d32f2f;
}

.btn-outline-scarlet:focus, .btn-outline-scarlet.focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 47, 47, 0.5);
}

.btn-outline-scarlet.disabled, .btn-outline-scarlet:disabled {
  color: #d32f2f;
  background-color: transparent;
}

.btn-outline-scarlet:not(:disabled):not(.disabled):active, .btn-outline-scarlet:not(:disabled):not(.disabled).active,
.show > .btn-outline-scarlet.dropdown-toggle {
  color: #fff;
  background-color: #d32f2f;
  border-color: #d32f2f;
}

.btn-outline-scarlet:not(:disabled):not(.disabled):active:focus, .btn-outline-scarlet:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-scarlet.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 47, 47, 0.5);
}

.btn-outline-carrot {
  color: #ef6c00;
  border-color: #ef6c00;
}

.btn-outline-carrot:hover {
  color: #fff;
  background-color: #ef6c00;
  border-color: #ef6c00;
}

.btn-outline-carrot:focus, .btn-outline-carrot.focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 108, 0, 0.5);
}

.btn-outline-carrot.disabled, .btn-outline-carrot:disabled {
  color: #ef6c00;
  background-color: transparent;
}

.btn-outline-carrot:not(:disabled):not(.disabled):active, .btn-outline-carrot:not(:disabled):not(.disabled).active,
.show > .btn-outline-carrot.dropdown-toggle {
  color: #fff;
  background-color: #ef6c00;
  border-color: #ef6c00;
}

.btn-outline-carrot:not(:disabled):not(.disabled):active:focus, .btn-outline-carrot:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-carrot.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 108, 0, 0.5);
}

.btn-outline-dark-gray {
  color: #202020;
  border-color: #202020;
}

.btn-outline-dark-gray:hover {
  color: #fff;
  background-color: #202020;
  border-color: #202020;
}

.btn-outline-dark-gray:focus, .btn-outline-dark-gray.focus {
  box-shadow: 0 0 0 0.2rem rgba(32, 32, 32, 0.5);
}

.btn-outline-dark-gray.disabled, .btn-outline-dark-gray:disabled {
  color: #202020;
  background-color: transparent;
}

.btn-outline-dark-gray:not(:disabled):not(.disabled):active, .btn-outline-dark-gray:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark-gray.dropdown-toggle {
  color: #fff;
  background-color: #202020;
  border-color: #202020;
}

.btn-outline-dark-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(32, 32, 32, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #2196f3;
  text-decoration: none;
}

.btn-link:hover {
  color: #6ab8f7;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.9375rem 1.9375rem;
  font-size: 0.875rem;
  line-height: 1.45;
  border-radius: 0.3125rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.3125rem 1rem;
  font-size: 0.6875rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 13.75rem;
  padding: 0.625rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #717171;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.1875rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1280px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.3125rem 1.875rem;
  clear: both;
  font-weight: 400;
  color: #717171;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #2196f3;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #bdc3c7;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.75rem 1.875rem;
  margin-bottom: 0;
  font-size: 0.75rem;
  color: #202020;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.3125rem 1.875rem;
  color: #717171;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -2px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.21875rem;
  padding-left: 1.21875rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.453125rem;
  padding-left: 1.453125rem;
}

.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -2px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -2px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: -ms-flexbox;
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -2px;
}

.input-group-prepend {
  margin-right: -2px;
}

.input-group-append {
  margin-left: -2px;
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 1.125rem;
  margin-bottom: 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.7;
  color: #717171;
  text-align: center;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0.08);
  border: 2px solid #e0e0e0;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 4px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1.375rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.3125rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 4px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.875rem;
  font-size: 0.6875rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2.125rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.7rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 0.875rem;
  height: 1.2875rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #2196f3;
  background-color: #2196f3;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #2196f3;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #cae6fc;
  border-color: #cae6fc;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #717171;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #d7dbdd;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.4125rem;
  left: -1.5rem;
  display: block;
  width: 0.875rem;
  height: 0.875rem;
  pointer-events: none;
  content: "";
  background-color: #d7dbdd;
  border: #adb5bd solid 2px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.4125rem;
  left: -1.5rem;
  display: block;
  width: 0.875rem;
  height: 0.875rem;
  content: "";
  background: no-repeat 50% / 61% 61%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.1875rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("../svg/check-white.svg");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #2196f3;
  background-color: #2196f3;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(33, 150, 243, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(33, 150, 243, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("../svg/check-radio.svg");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(33, 150, 243, 0.5);
}

.custom-switch {
  padding-left: 2.75rem;
}

.custom-switch .custom-control-label::before {
  left: -2.75rem;
  width: 2.125rem;
  pointer-events: all;
  border-radius: 50%;
}

.custom-switch .custom-control-label::after {
  top: calc(0.4125rem + 4px);
  left: calc(-2.75rem + 4px);
  width: 1rem;
  height: 1rem;
  background-color: #adb5bd;
  border-radius: 50%;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #d7dbdd;
  -webkit-transform: translateX(1.25rem);
  transform: translateX(1.25rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(33, 150, 243, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: 2.6875rem;
  padding: 0.375rem 2.125rem 0.375rem 1.125rem;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.7;
  color: #717171;
  vertical-align: middle;
  background: transparent url("../svg/caret-down.svg") no-repeat right 1.125rem center/11px 14px;
  border: 2px solid #e0e0e0;
  border-radius: 0.1875rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #2196f3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
}

.custom-select:focus::-ms-value {
  color: #717171;
  background-color: transparent;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1.125rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #717171;
}

.custom-select-sm {
  height: 1.875rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.875rem;
  font-size: 0.6875rem;
}

.custom-select-lg {
  height: 3.375rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.375rem;
  font-size: 0.875rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 2.6875rem;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 2.6875rem;
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #2196f3;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 2.6875rem;
  padding: 0.375rem 1.125rem;
  font-weight: 400;
  line-height: 1.7;
  color: #717171;
  background-color: transparent;
  border: 2px solid #e0e0e0;
  border-radius: 0.1875rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.7em + 0.75rem);
  padding: 0.375rem 1.125rem;
  line-height: 1.7;
  color: #717171;
  content: "Browse";
  background-color: #2196f3;
  border-left: inherit;
  border-radius: 0 0.1875rem 0.1875rem 0;
}

.custom-range {
  width: 100%;
  height: 1.275rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 0.875rem;
  height: 0.875rem;
  margin-top: -0.375rem;
  background-color: #2196f3;
  border: 0;
  border-radius: 50%;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #45a7f5;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.125rem;
  color: transparent;
  cursor: pointer;
  background-color: #e2e4e6;
  border-color: transparent;
  border-radius: 0.0625rem;
}

.custom-range::-moz-range-thumb {
  width: 0.875rem;
  height: 0.875rem;
  background-color: #2196f3;
  border: 0;
  border-radius: 50%;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #45a7f5;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.125rem;
  color: transparent;
  cursor: pointer;
  background-color: #e2e4e6;
  border-color: transparent;
  border-radius: 0.0625rem;
}

.custom-range::-ms-thumb {
  width: 0.875rem;
  height: 0.875rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #2196f3;
  border: 0;
  border-radius: 50%;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #45a7f5;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.125rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.4375rem;
}

.custom-range::-ms-fill-lower {
  background-color: #e2e4e6;
  border-radius: 0.0625rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #e2e4e6;
  border-radius: 0.0625rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 1.25rem 1.5rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #717171;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef rgba(0, 0, 0, 0.07);
}

.nav-tabs .nav-link.disabled {
  color: #717171;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 1.3125rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2196f3;
}

.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.8125rem 1rem;
}

.navbar .container,
.navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 1.14375rem;
  padding-bottom: 1.14375rem;
  margin-right: 1rem;
  font-size: 1.125rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1279.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1280px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: #1c2833;
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #1c2833;
}

.navbar-light .navbar-nav .nav-link {
  color: #1c2833;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #1c2833;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: #496985;
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #1c2833;
}

.navbar-light .navbar-toggler {
  color: #1c2833;
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%231c2833' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: #1c2833;
}

.navbar-light .navbar-text a {
  color: #1c2833;
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: #1c2833;
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: #fff;
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23fff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: #fff;
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.3125rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.875rem;
}

.card-title {
  margin-bottom: 1rem;
}

.card-subtitle {
  margin-top: -0.5rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.875rem;
}

.card-header {
  padding: 1rem 1.875rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.card-header:first-child {
  border-radius: 0.3125rem 0.3125rem 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 1rem 1.875rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.card-footer:last-child {
  border-radius: 0 0 0.3125rem 0.3125rem;
}

.card-header-tabs {
  margin-right: -0.9375rem;
  margin-bottom: -1rem;
  margin-left: -0.9375rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.875rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  font-size: 0.6875rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0;
}

.breadcrumb-item {
  display: -ms-flexbox;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: rgba(0, 0, 0, 0.5);
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.3125rem 0.5rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #717171;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #717171;
  text-decoration: none;
  background-color: #f5f7f7;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.page-item.disabled .page-link {
  color: #717171;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.3125rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.pagination-sm .page-link {
  padding: 0.125rem 0.3125rem;
  font-size: 0.75rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.95em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.1875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #2196f3;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0c7cd5;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #4db6ac;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #3c948b;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(77, 182, 172, 0.5);
}

.badge-success {
  color: #212529;
  background-color: #66bb6a;
}

a.badge-success:hover, a.badge-success:focus {
  color: #212529;
  background-color: #49a54e;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(102, 187, 106, 0.5);
}

.badge-info {
  color: #212529;
  background-color: #81d4fa;
}

a.badge-info:hover, a.badge-info:focus {
  color: #212529;
  background-color: #50c3f8;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(129, 212, 250, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #fbc02d;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #f0ad05;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(251, 192, 45, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #e53935;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #cd1e1a;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(229, 57, 53, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f7f9f9;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae3e3;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(247, 249, 249, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #1c2833;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #0a0e12;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(28, 40, 51, 0.5);
}

.badge-indigo {
  color: #fff;
  background-color: #1565c0;
}

a.badge-indigo:hover, a.badge-indigo:focus {
  color: #fff;
  background-color: #104d92;
}

a.badge-indigo:focus, a.badge-indigo.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(21, 101, 192, 0.5);
}

.badge-purple {
  color: #fff;
  background-color: #9c27b0;
}

a.badge-purple:hover, a.badge-purple:focus {
  color: #fff;
  background-color: #771e86;
}

a.badge-purple:focus, a.badge-purple.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.5);
}

.badge-pink {
  color: #fff;
  background-color: #f06292;
}

a.badge-pink:hover, a.badge-pink:focus {
  color: #fff;
  background-color: #ec3372;
}

a.badge-pink:focus, a.badge-pink.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(240, 98, 146, 0.5);
}

.badge-orange {
  color: #212529;
  background-color: #fb8c00;
}

a.badge-orange:hover, a.badge-orange:focus {
  color: #212529;
  background-color: #c87000;
}

a.badge-orange:focus, a.badge-orange.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(251, 140, 0, 0.5);
}

.badge-sky {
  color: #212529;
  background-color: #64b5f6;
}

a.badge-sky:hover, a.badge-sky:focus {
  color: #212529;
  background-color: #349ef3;
}

a.badge-sky:focus, a.badge-sky.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(100, 181, 246, 0.5);
}

.badge-navy {
  color: #fff;
  background-color: #0d47a1;
}

a.badge-navy:hover, a.badge-navy:focus {
  color: #fff;
  background-color: #093272;
}

a.badge-navy:focus, a.badge-navy.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(13, 71, 161, 0.5);
}

.badge-berry {
  color: #fff;
  background-color: #b71c1c;
}

a.badge-berry:hover, a.badge-berry:focus {
  color: #fff;
  background-color: #8b1515;
}

a.badge-berry:focus, a.badge-berry.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(183, 28, 28, 0.5);
}

.badge-rose {
  color: #fff;
  background-color: #e91e63;
}

a.badge-rose:hover, a.badge-rose:focus {
  color: #fff;
  background-color: #c1134e;
}

a.badge-rose:focus, a.badge-rose.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5);
}

.badge-orchid {
  color: #fff;
  background-color: #ba68c8;
}

a.badge-orchid:hover, a.badge-orchid:focus {
  color: #fff;
  background-color: #a844b9;
}

a.badge-orchid:focus, a.badge-orchid.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(186, 104, 200, 0.5);
}

.badge-blush {
  color: #fff;
  background-color: #ef5350;
}

a.badge-blush:hover, a.badge-blush:focus {
  color: #fff;
  background-color: #eb2521;
}

a.badge-blush:focus, a.badge-blush.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(239, 83, 80, 0.5);
}

.badge-scarlet {
  color: #fff;
  background-color: #d32f2f;
}

a.badge-scarlet:hover, a.badge-scarlet:focus {
  color: #fff;
  background-color: #ab2424;
}

a.badge-scarlet:focus, a.badge-scarlet.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(211, 47, 47, 0.5);
}

.badge-carrot {
  color: #fff;
  background-color: #ef6c00;
}

a.badge-carrot:hover, a.badge-carrot:focus {
  color: #fff;
  background-color: #bc5500;
}

a.badge-carrot:focus, a.badge-carrot.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(239, 108, 0, 0.5);
}

.badge-dark-gray {
  color: #fff;
  background-color: #202020;
}

a.badge-dark-gray:hover, a.badge-dark-gray:focus {
  color: #fff;
  background-color: #070707;
}

a.badge-dark-gray:focus, a.badge-dark-gray.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(32, 32, 32, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3125rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 1.1875rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.1875rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #114e7e;
  background-color: #2196f3;
  border-color: #9dd1fa;
}

.alert-primary hr {
  border-top-color: #85c6f9;
}

.alert-primary .alert-link {
  color: #0b3251;
}

.alert-secondary {
  color: #285f59;
  background-color: #4db6ac;
  border-color: #b1dfda;
}

.alert-secondary hr {
  border-top-color: #9fd8d1;
}

.alert-secondary .alert-link {
  color: #193b37;
}

.alert-success {
  color: #356137;
  background-color: #66bb6a;
  border-color: #bce1bd;
}

.alert-success hr {
  border-top-color: #aad9ac;
}

.alert-success .alert-link {
  color: #234024;
}

.alert-info {
  color: #436e82;
  background-color: #81d4fa;
  border-color: #c8ecfd;
}

.alert-info hr {
  border-top-color: #afe4fc;
}

.alert-info .alert-link {
  color: #325260;
}

.alert-warning {
  color: #836417;
  background-color: #fbc02d;
  border-color: #fde3a3;
}

.alert-warning hr {
  border-top-color: #fcdb8a;
}

.alert-warning .alert-link {
  color: #58430f;
}

.alert-danger {
  color: #771e1c;
  background-color: #e53935;
  border-color: #f4a8a6;
}

.alert-danger hr {
  border-top-color: #f1928f;
}

.alert-danger .alert-link {
  color: #4e1412;
}

.alert-light {
  color: #808181;
  background-color: #f7f9f9;
  border-color: #fbfcfc;
}

.alert-light hr {
  border-top-color: #ecf1f1;
}

.alert-light .alert-link {
  color: #676767;
}

.alert-dark {
  color: #0f151b;
  background-color: #1c2833;
  border-color: #9ba0a5;
}

.alert-dark hr {
  border-top-color: #8e9399;
}

.alert-dark .alert-link {
  color: black;
}

.alert-indigo {
  color: #0b3564;
  background-color: #1565c0;
  border-color: #98bbe3;
}

.alert-indigo hr {
  border-top-color: #84aede;
}

.alert-indigo .alert-link {
  color: #061d36;
}

.alert-purple {
  color: #51145c;
  background-color: #9c27b0;
  border-color: #d3a0dc;
}

.alert-purple hr {
  border-top-color: #ca8dd5;
}

.alert-purple .alert-link {
  color: #2c0b32;
}

.alert-pink {
  color: #7d334c;
  background-color: #f06292;
  border-color: #f8bacf;
}

.alert-pink hr {
  border-top-color: #f6a3bf;
}

.alert-pink .alert-link {
  color: #592436;
}

.alert-orange {
  color: #834900;
  background-color: #fb8c00;
  border-color: #fdcc8f;
}

.alert-orange hr {
  border-top-color: #fdc176;
}

.alert-orange .alert-link {
  color: #502d00;
}

.alert-sky {
  color: #345e80;
  background-color: #64b5f6;
  border-color: #bbdefb;
}

.alert-sky hr {
  border-top-color: #a3d2fa;
}

.alert-sky .alert-link {
  color: #25435c;
}

.alert-navy {
  color: #072554;
  background-color: #0d47a1;
  border-color: #95aed6;
}

.alert-navy hr {
  border-top-color: #83a0cf;
}

.alert-navy .alert-link {
  color: #031025;
}

.alert-berry {
  color: #5f0f0f;
  background-color: #b71c1c;
  border-color: #df9b9b;
}

.alert-berry hr {
  border-top-color: #d98888;
}

.alert-berry .alert-link {
  color: #330808;
}

.alert-rose {
  color: #791033;
  background-color: #e91e63;
  border-color: #f59cba;
}

.alert-rose hr {
  border-top-color: #f385aa;
}

.alert-rose .alert-link {
  color: #4c0a20;
}

.alert-orchid {
  color: #613668;
  background-color: #ba68c8;
  border-color: #e1bde7;
}

.alert-orchid hr {
  border-top-color: #d9aae0;
}

.alert-orchid .alert-link {
  color: #422546;
}

.alert-blush {
  color: #7c2b2a;
  background-color: #ef5350;
  border-color: #f8b3b2;
}

.alert-blush hr {
  border-top-color: #f69c9b;
}

.alert-blush .alert-link {
  color: #561e1d;
}

.alert-scarlet {
  color: #6e1818;
  background-color: #d32f2f;
  border-color: #eca3a3;
}

.alert-scarlet hr {
  border-top-color: #e88e8e;
}

.alert-scarlet .alert-link {
  color: #440f0f;
}

.alert-carrot {
  color: #7c3800;
  background-color: #ef6c00;
  border-color: #f8be8f;
}

.alert-carrot hr {
  border-top-color: #f7b077;
}

.alert-carrot .alert-link {
  color: #492100;
}

.alert-dark-gray {
  color: #111111;
  background-color: #202020;
  border-color: #9d9d9d;
}

.alert-dark-gray hr {
  border-top-color: #909090;
}

.alert-dark-gray .alert-link {
  color: black;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #eaeded;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #2196f3;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
}

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.1875rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #717171;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5625rem 1.875rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #717171;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  -ms-flex-direction: row;
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.1875rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.1875rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.1875rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.1875rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.1875rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.1875rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.1875rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.1875rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1280px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.1875rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.1875rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #114e7e;
  background-color: #c1e2fc;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #114e7e;
  background-color: #a9d7fb;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #114e7e;
  border-color: #114e7e;
}

.list-group-item-secondary {
  color: #285f59;
  background-color: #cdebe8;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #285f59;
  background-color: #bbe4e0;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #285f59;
  border-color: #285f59;
}

.list-group-item-success {
  color: #356137;
  background-color: #d4ecd5;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #356137;
  background-color: #c2e4c4;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #356137;
  border-color: #356137;
}

.list-group-item-info {
  color: #436e82;
  background-color: #dcf3fe;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #436e82;
  background-color: #c3ebfd;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #436e82;
  border-color: #436e82;
}

.list-group-item-warning {
  color: #836417;
  background-color: #feedc4;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #836417;
  background-color: #fee5ab;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #836417;
  border-color: #836417;
}

.list-group-item-danger {
  color: #771e1c;
  background-color: #f8c8c6;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #771e1c;
  background-color: #f5b2af;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #771e1c;
  border-color: #771e1c;
}

.list-group-item-light {
  color: #808181;
  background-color: #fdfdfd;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #808181;
  background-color: #f0f0f0;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #808181;
  border-color: #808181;
}

.list-group-item-dark {
  color: #0f151b;
  background-color: #bfc3c6;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #0f151b;
  background-color: #b2b6ba;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #0f151b;
  border-color: #0f151b;
}

.list-group-item-indigo {
  color: #0b3564;
  background-color: #bdd4ed;
}

.list-group-item-indigo.list-group-item-action:hover, .list-group-item-indigo.list-group-item-action:focus {
  color: #0b3564;
  background-color: #a9c7e8;
}

.list-group-item-indigo.list-group-item-action.active {
  color: #fff;
  background-color: #0b3564;
  border-color: #0b3564;
}

.list-group-item-purple {
  color: #51145c;
  background-color: #e3c3e9;
}

.list-group-item-purple.list-group-item-action:hover, .list-group-item-purple.list-group-item-action:focus {
  color: #51145c;
  background-color: #dab0e2;
}

.list-group-item-purple.list-group-item-action.active {
  color: #fff;
  background-color: #51145c;
  border-color: #51145c;
}

.list-group-item-pink {
  color: #7d334c;
  background-color: #fbd3e0;
}

.list-group-item-pink.list-group-item-action:hover, .list-group-item-pink.list-group-item-action:focus {
  color: #7d334c;
  background-color: #f9bcd0;
}

.list-group-item-pink.list-group-item-action.active {
  color: #fff;
  background-color: #7d334c;
  border-color: #7d334c;
}

.list-group-item-orange {
  color: #834900;
  background-color: #fedfb8;
}

.list-group-item-orange.list-group-item-action:hover, .list-group-item-orange.list-group-item-action:focus {
  color: #834900;
  background-color: #fed49f;
}

.list-group-item-orange.list-group-item-action.active {
  color: #fff;
  background-color: #834900;
  border-color: #834900;
}

.list-group-item-sky {
  color: #345e80;
  background-color: #d4eafc;
}

.list-group-item-sky.list-group-item-action:hover, .list-group-item-sky.list-group-item-action:focus {
  color: #345e80;
  background-color: #bcdefa;
}

.list-group-item-sky.list-group-item-action.active {
  color: #fff;
  background-color: #345e80;
  border-color: #345e80;
}

.list-group-item-navy {
  color: #072554;
  background-color: #bbcbe5;
}

.list-group-item-navy.list-group-item-action:hover, .list-group-item-navy.list-group-item-action:focus {
  color: #072554;
  background-color: #a9bdde;
}

.list-group-item-navy.list-group-item-action.active {
  color: #fff;
  background-color: #072554;
  border-color: #072554;
}

.list-group-item-berry {
  color: #5f0f0f;
  background-color: #ebbfbf;
}

.list-group-item-berry.list-group-item-action:hover, .list-group-item-berry.list-group-item-action:focus {
  color: #5f0f0f;
  background-color: #e5acac;
}

.list-group-item-berry.list-group-item-action.active {
  color: #fff;
  background-color: #5f0f0f;
  border-color: #5f0f0f;
}

.list-group-item-rose {
  color: #791033;
  background-color: #f9c0d3;
}

.list-group-item-rose.list-group-item-action:hover, .list-group-item-rose.list-group-item-action:focus {
  color: #791033;
  background-color: #f7a9c3;
}

.list-group-item-rose.list-group-item-action.active {
  color: #fff;
  background-color: #791033;
  border-color: #791033;
}

.list-group-item-orchid {
  color: #613668;
  background-color: #ecd5f0;
}

.list-group-item-orchid.list-group-item-action:hover, .list-group-item-orchid.list-group-item-action:focus {
  color: #613668;
  background-color: #e4c2e9;
}

.list-group-item-orchid.list-group-item-action.active {
  color: #fff;
  background-color: #613668;
  border-color: #613668;
}

.list-group-item-blush {
  color: #7c2b2a;
  background-color: #fbcfce;
}

.list-group-item-blush.list-group-item-action:hover, .list-group-item-blush.list-group-item-action:focus {
  color: #7c2b2a;
  background-color: #f9b8b6;
}

.list-group-item-blush.list-group-item-action.active {
  color: #fff;
  background-color: #7c2b2a;
  border-color: #7c2b2a;
}

.list-group-item-scarlet {
  color: #6e1818;
  background-color: #f3c5c5;
}

.list-group-item-scarlet.list-group-item-action:hover, .list-group-item-scarlet.list-group-item-action:focus {
  color: #6e1818;
  background-color: #efb0b0;
}

.list-group-item-scarlet.list-group-item-action.active {
  color: #fff;
  background-color: #6e1818;
  border-color: #6e1818;
}

.list-group-item-carrot {
  color: #7c3800;
  background-color: #fbd6b8;
}

.list-group-item-carrot.list-group-item-action:hover, .list-group-item-carrot.list-group-item-action:focus {
  color: #7c3800;
  background-color: #fac8a0;
}

.list-group-item-carrot.list-group-item-action.active {
  color: #fff;
  background-color: #7c3800;
  border-color: #7c3800;
}

.list-group-item-dark-gray {
  color: #111111;
  background-color: #c1c1c1;
}

.list-group-item-dark-gray.list-group-item-action:hover, .list-group-item-dark-gray.list-group-item-action:focus {
  color: #111111;
  background-color: #b4b4b4;
}

.list-group-item-dark-gray.list-group-item-action.active {
  color: #fff;
  background-color: #111111;
  border-color: #111111;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #313131;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #313131;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.42rem 1.22rem rgba(23, 32, 42, 0.12);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.3125rem;
}

.toast:not(:last-child) {
  margin-bottom: 1.25rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.625rem 1.25rem;
  color: #6c757d;
  background-color: transparent;
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 1.25rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3125rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.7;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3125rem - 1px);
  border-top-right-radius: calc(0.3125rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.7;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.875rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1.625rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3125rem - 1px);
  border-bottom-left-radius: calc(0.3125rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1280px) {
  .modal-xl {
    max-width: 1276px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 1;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #202020;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #202020;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #202020;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #202020;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.5625rem 0.9375rem;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  background-color: #202020;
  border-radius: 0.1875rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.1875rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.1875rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.1875rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.1875rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #202020;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.1875rem - 1px);
  border-top-right-radius: calc(0.1875rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #717171;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 12%;
  color: #fff;
  text-align: center;
  opacity: 0.75;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 1;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 12%;
  margin-left: 12%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 2px;
  margin-right: 5px;
  margin-left: 5px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #2196f3 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0c7cd5 !important;
}

.bg-secondary {
  background-color: #4db6ac !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #3c948b !important;
}

.bg-success {
  background-color: #66bb6a !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #49a54e !important;
}

.bg-info {
  background-color: #81d4fa !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #50c3f8 !important;
}

.bg-warning {
  background-color: #fbc02d !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #f0ad05 !important;
}

.bg-danger {
  background-color: #e53935 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #cd1e1a !important;
}

.bg-light {
  background-color: #f7f9f9 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae3e3 !important;
}

.bg-dark {
  background-color: #1c2833 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #0a0e12 !important;
}

.bg-indigo {
  background-color: #1565c0 !important;
}

a.bg-indigo:hover, a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
  background-color: #104d92 !important;
}

.bg-purple {
  background-color: #9c27b0 !important;
}

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #771e86 !important;
}

.bg-pink {
  background-color: #f06292 !important;
}

a.bg-pink:hover, a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #ec3372 !important;
}

.bg-orange {
  background-color: #fb8c00 !important;
}

a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #c87000 !important;
}

.bg-sky {
  background-color: #64b5f6 !important;
}

a.bg-sky:hover, a.bg-sky:focus,
button.bg-sky:hover,
button.bg-sky:focus {
  background-color: #349ef3 !important;
}

.bg-navy {
  background-color: #0d47a1 !important;
}

a.bg-navy:hover, a.bg-navy:focus,
button.bg-navy:hover,
button.bg-navy:focus {
  background-color: #093272 !important;
}

.bg-berry {
  background-color: #b71c1c !important;
}

a.bg-berry:hover, a.bg-berry:focus,
button.bg-berry:hover,
button.bg-berry:focus {
  background-color: #8b1515 !important;
}

.bg-rose {
  background-color: #e91e63 !important;
}

a.bg-rose:hover, a.bg-rose:focus,
button.bg-rose:hover,
button.bg-rose:focus {
  background-color: #c1134e !important;
}

.bg-orchid {
  background-color: #ba68c8 !important;
}

a.bg-orchid:hover, a.bg-orchid:focus,
button.bg-orchid:hover,
button.bg-orchid:focus {
  background-color: #a844b9 !important;
}

.bg-blush {
  background-color: #ef5350 !important;
}

a.bg-blush:hover, a.bg-blush:focus,
button.bg-blush:hover,
button.bg-blush:focus {
  background-color: #eb2521 !important;
}

.bg-scarlet {
  background-color: #d32f2f !important;
}

a.bg-scarlet:hover, a.bg-scarlet:focus,
button.bg-scarlet:hover,
button.bg-scarlet:focus {
  background-color: #ab2424 !important;
}

.bg-carrot {
  background-color: #ef6c00 !important;
}

a.bg-carrot:hover, a.bg-carrot:focus,
button.bg-carrot:hover,
button.bg-carrot:focus {
  background-color: #bc5500 !important;
}

.bg-dark-gray {
  background-color: #202020 !important;
}

a.bg-dark-gray:hover, a.bg-dark-gray:focus,
button.bg-dark-gray:hover,
button.bg-dark-gray:focus {
  background-color: #070707 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #2196f3 !important;
}

.border-secondary {
  border-color: #4db6ac !important;
}

.border-success {
  border-color: #66bb6a !important;
}

.border-info {
  border-color: #81d4fa !important;
}

.border-warning {
  border-color: #fbc02d !important;
}

.border-danger {
  border-color: #e53935 !important;
}

.border-light {
  border-color: #f7f9f9 !important;
}

.border-dark {
  border-color: #1c2833 !important;
}

.border-indigo {
  border-color: #1565c0 !important;
}

.border-purple {
  border-color: #9c27b0 !important;
}

.border-pink {
  border-color: #f06292 !important;
}

.border-orange {
  border-color: #fb8c00 !important;
}

.border-sky {
  border-color: #64b5f6 !important;
}

.border-navy {
  border-color: #0d47a1 !important;
}

.border-berry {
  border-color: #b71c1c !important;
}

.border-rose {
  border-color: #e91e63 !important;
}

.border-orchid {
  border-color: #ba68c8 !important;
}

.border-blush {
  border-color: #ef5350 !important;
}

.border-scarlet {
  border-color: #d32f2f !important;
}

.border-carrot {
  border-color: #ef6c00 !important;
}

.border-dark-gray {
  border-color: #202020 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.1875rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3125rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1280px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1280px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1280px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.3rem rgba(23, 32, 42, 0.095) !important;
}

.shadow {
  box-shadow: 0 0.42rem 1.22rem rgba(23, 32, 42, 0.12) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3.2rem rgba(23, 32, 42, 0.165) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1280px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1280px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #2196f3 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0a6ebd !important;
}

.text-secondary {
  color: #4db6ac !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #35817a !important;
}

.text-success {
  color: #66bb6a !important;
}

a.text-success:hover, a.text-success:focus {
  color: #419345 !important;
}

.text-info {
  color: #81d4fa !important;
}

a.text-info:hover, a.text-info:focus {
  color: #37bbf7 !important;
}

.text-warning {
  color: #fbc02d !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d79b04 !important;
}

.text-danger {
  color: #e53935 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #b61b17 !important;
}

.text-light {
  color: #f7f9f9 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd8d8 !important;
}

.text-dark {
  color: #1c2833 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #010102 !important;
}

.text-indigo {
  color: #1565c0 !important;
}

a.text-indigo:hover, a.text-indigo:focus {
  color: #0d417b !important;
}

.text-purple {
  color: #9c27b0 !important;
}

a.text-purple:hover, a.text-purple:focus {
  color: #641971 !important;
}

.text-pink {
  color: #f06292 !important;
}

a.text-pink:hover, a.text-pink:focus {
  color: #e91c62 !important;
}

.text-orange {
  color: #fb8c00 !important;
}

a.text-orange:hover, a.text-orange:focus {
  color: #af6100 !important;
}

.text-sky {
  color: #64b5f6 !important;
}

a.text-sky:hover, a.text-sky:focus {
  color: #1c92f2 !important;
}

.text-navy {
  color: #0d47a1 !important;
}

a.text-navy:hover, a.text-navy:focus {
  color: #07285a !important;
}

.text-berry {
  color: #b71c1c !important;
}

a.text-berry:hover, a.text-berry:focus {
  color: #751212 !important;
}

.text-rose {
  color: #e91e63 !important;
}

a.text-rose:hover, a.text-rose:focus {
  color: #aa1145 !important;
}

.text-orchid {
  color: #ba68c8 !important;
}

a.text-orchid:hover, a.text-orchid:focus {
  color: #973da7 !important;
}

.text-blush {
  color: #ef5350 !important;
}

a.text-blush:hover, a.text-blush:focus {
  color: #de1814 !important;
}

.text-scarlet {
  color: #d32f2f !important;
}

a.text-scarlet:hover, a.text-scarlet:focus {
  color: #962020 !important;
}

.text-carrot {
  color: #ef6c00 !important;
}

a.text-carrot:hover, a.text-carrot:focus {
  color: #a34900 !important;
}

.text-dark-gray {
  color: #202020 !important;
}

a.text-dark-gray:hover, a.text-dark-gray:focus {
  color: black !important;
}

.text-body {
  color: #717171 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #e0e0e0;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #e0e0e0;
  }
}

/***************************************************
	Default menu
****************************************************/
.navik-header {
  position: relative;
  z-index: 1040;
  font-size: 16px;
  line-height: 1.428572;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

.navik-header ul {
  margin-bottom: inherit;
}

.navik-header img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

.navik-header a:hover, .navik-header a:focus, .navik-header a:active {
  text-decoration: none;
  outline: none;
}

.navik-header .logo {
  padding: 19px 40px;
  text-align: center;
}

.navik-header .logo img {
  max-width: 100%;
  height: auto;
}

.navik-header-container {
  position: relative;
}

.navik-menu > ul {
  display: none;
  padding: 0 0 20px;
}

.navik-menu ul li {
  position: relative;
}

.navik-menu ul li > a {
  display: block;
  padding: 14px 15px;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: #202020;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
}

.navik-menu ul li.current-menu > a,
.navik-menu ul li:hover > a {
  color: #2196f3;
  background-color: #f6f6f6;
}

.navik-menu ul ul li > a {
  padding: 14px 15px 14px 30px;
  font-size: 13px;
  font-style: italic;
  text-transform: inherit;
  background-color: #efefef;
}

.navik-menu ul ul li:hover > a {
  background-color: #efefef;
}

.navik-menu ul ul ul li > a {
  padding: 14px 15px 14px 45px;
}

.navik-menu li > ul {
  display: none;
}

.dropdown-plus {
  position: absolute;
  top: 0;
  right: 0;
  width: 49px;
  height: 49px;
  line-height: 49px;
  cursor: pointer;
}

.dropdown-plus::before, .dropdown-plus::after {
  position: absolute;
  top: 24px;
  right: 18px;
  width: 13px;
  height: 1px;
  content: "";
  background-color: #111;
}

.dropdown-plus::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.dropdown-plus.dropdown-open::after {
  display: none;
}

.header-shadow-wrapper {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 30px;
  overflow: hidden;
}

.header-shadow-wrapper::after {
  position: relative;
  top: -60px;
  display: block;
  width: 100%;
  height: 60px;
  content: "";
  border-radius: 50%;
  box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.1);
}

/***************************************************
  Burger menu
****************************************************/
.burger-menu {
  position: absolute;
  top: 32px;
  right: 14px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 20px;
  height: 17px;
  cursor: pointer;
  transition: -webkit-transform 330ms ease-out;
  transition: transform 330ms ease-out;
  transition: transform 330ms ease-out, -webkit-transform 330ms ease-out;
}

.burger-menu.menu-open {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.line-menu {
  width: 100%;
  height: 3px;
  background-color: #111;
  border-radius: 2px;
}

.line-menu.line-half {
  width: 50%;
}

.line-menu.first-line {
  -webkit-transform-origin: right;
  transform-origin: right;
  transition: -webkit-transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57), -webkit-transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
}

.line-menu.last-line {
  -ms-flex-item-align: end;
  align-self: flex-end;
  -webkit-transform-origin: left;
  transform-origin: left;
  transition: -webkit-transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57), -webkit-transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
}

.menu-open .line-menu.first-line {
  -webkit-transform: rotate(-90deg) translateX(3px);
  transform: rotate(-90deg) translateX(3px);
}

.menu-open .line-menu.last-line {
  -webkit-transform: rotate(-90deg) translateX(-3px);
  transform: rotate(-90deg) translateX(-3px);
}

/***************************************************
  Center menu
****************************************************/
.logoCenter {
  display: none;
}

.center-menu-1 .navik-menu > ul:first-child {
  padding: 0;
}

/***************************************************
  Dark version menu
****************************************************/
.navik-header.header-dark {
  background-color: #111;
}

.navik-header.header-dark .line-menu,
.navik-header.header-dark .dropdown-plus::before,
.navik-header.header-dark .dropdown-plus::after {
  background-color: #fff;
}

.header-dark .navik-menu ul li > a {
  color: #fff;
}

.header-dark .navik-menu ul li.current-menu > a,
.header-dark .navik-menu ul li:hover > a {
  color: #2196f3;
  background-color: #313131;
}

.header-dark .navik-menu ul ul li > a {
  color: #c1c1c1;
  background-color: #212121;
}

/***************************************************
  Overlay dropdown plus
****************************************************/
.overlay-dropdown-plus {
  position: absolute;
  top: 6px;
  right: 30px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
  opacity: 0;
}

.overlay-dropdown-plus::before, .overlay-dropdown-plus::after {
  position: absolute;
  top: 9px;
  right: 5px;
  width: 10px;
  height: 2px;
  content: "";
  background-color: #616161;
}

.overlay-dropdown-plus::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.overlay-dropdown-plus.overlay-dropdown-open::after {
  display: none;
}

/***************************************************
  Overlay menu
****************************************************/
.navik-header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

.navik-header-overlay .navik-header-container {
  position: inherit;
}

.navik-header-overlay img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

.navik-header-overlay a:hover, .navik-header-overlay a:focus, .navik-header-overlay a:active {
  text-decoration: none;
  outline: none;
}

.navik-header-overlay .logo {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 2;
}

.navik-header-overlay .burger-menu {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 100001;
  display: block;
  -ms-flex-direction: inherit;
  flex-direction: inherit;
  -ms-flex-pack: inherit;
  justify-content: inherit;
  width: 50px;
  height: 47px;
  padding: 15px;
  background: #111;
  transition: all 0.2s ease-in-out;
}

.navik-header-overlay .burger-menu:hover {
  box-shadow: 0 0 25px -1px rgba(0, 0, 0, 0.4);
}

.navik-header-overlay .burger-menu > span {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  height: 17px;
  transition: -webkit-transform 330ms ease-out;
  transition: transform 330ms ease-out;
  transition: transform 330ms ease-out, -webkit-transform 330ms ease-out;
}

.navik-header-overlay .burger-menu.menu-open {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.navik-header-overlay .burger-menu.menu-open > span {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.navik-header-overlay .line-menu {
  background-color: #fff;
}

.nav-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  visibility: hidden;
  background-color: #fff;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.nav-menu-wrapper.overlay-menu-open {
  visibility: visible;
  opacity: 1;
}

.navik-menu-overlay {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
}

.navik-menu-overlay > ul {
  display: table-cell;
  padding: 80px 0 100px;
  text-align: right;
  vertical-align: middle;
}

.navik-menu-overlay > ul > li {
  position: relative;
  padding: 0 65px 0 15px;
  margin-bottom: 12px;
}

.navik-menu-overlay > ul > li:last-child {
  margin-bottom: 0;
}

.navik-menu-overlay > ul > li > .menu-overlay-link a {
  display: inline-block;
  font-size: 28px;
  font-weight: 800;
  line-height: 1.2em;
  color: #202020;
  text-transform: uppercase;
  transition: color 0.2s ease-in-out;
}

.navik-menu-overlay > ul > li > ul {
  display: none;
  padding: 10px 0 20px;
}

.navik-menu-overlay > ul > li > ul > li {
  position: relative;
}

.navik-menu-overlay > ul > li > ul > li::after {
  position: absolute;
  top: 3px;
  left: -18px;
  width: 1px;
  height: 13px;
  content: "";
  background-color: #d1d1d1;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.navik-menu-overlay > ul > li > ul > li:first-child {
  margin-left: 0;
}

.navik-menu-overlay > ul > li > ul > li:first-child::after {
  display: none;
}

.navik-menu-overlay > ul > li.menuFade > .menu-overlay-link a,
.navik-menu-overlay > ul > li.menuFade > .menu-overlay-link .overlay-dropdown-plus {
  opacity: 0;
}

.navik-menu-overlay > ul > li.menuFade > ul {
  opacity: 0;
}

.navik-menu-overlay > ul > li li {
  display: inline;
}

.navik-menu-overlay > ul > li.menuSlideIn .menu-overlay-link .overlay-dropdown-plus {
  -webkit-animation-name: menuFadeIn;
  animation-name: menuFadeIn;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.navik-menu-overlay > ul > li.menuSlideOut .menu-overlay-link .overlay-dropdown-plus {
  -webkit-animation-name: subMenuFadeOut;
  animation-name: subMenuFadeOut;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.navik-menu-overlay > ul ul {
  display: inline;
}

.navik-menu-overlay > ul ul li {
  margin-left: 32px;
}

.navik-menu-overlay > ul ul li a {
  font-size: 14px;
  font-style: italic;
  color: #818181;
  white-space: nowrap;
  transition: color 0.2s ease-in-out;
}

.navik-menu-overlay > ul ul li a:hover {
  color: #2196f3 !important;
}

.navik-menu-overlay ul li a {
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
}

.navik-menu-overlay.submenu-no-separate > ul > li > ul li::after {
  display: none !important;
}

.navik-menu-overlay.submenu-no-separate > ul ul li {
  margin-left: 20px;
}

.navik-menu-overlay > ul > li > .menu-overlay-link a:hover, .navik-menu-overlay > ul > li.current-menu > .menu-overlay-link a, .overlay-menu-hover > .menu-overlay-link a, .overlay-dropdown-plus:hover + a {
  color: #2196f3 !important;
}

@-webkit-keyframes menuFadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-80px, 0, 0);
    transform: translate3d(-80px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes menuFadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-80px, 0, 0);
    transform: translate3d(-80px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes menuFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes menuFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes subMenuFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes subMenuFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.navik-menu-overlay > ul > li.menuSlideIn > .menu-overlay-link a, .navik-menu-overlay > ul > li.menuSlideIn > ul {
  -webkit-animation-name: menuFadeInLeft;
  animation-name: menuFadeInLeft;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .navik-menu-overlay > ul > li.menuSlideIn > ul {
    opacity: 1;
    -webkit-animation-name: menuFadeIn;
    animation-name: menuFadeIn;
  }
}

@supports (-ms-accelerator: true) {
  .navik-menu-overlay > ul > li.menuSlideIn > ul {
    opacity: 1;
    -webkit-animation-name: menuFadeIn;
    animation-name: menuFadeIn;
  }
}

.overlay-center-menu .navik-menu-overlay > ul {
  text-align: center;
}

.overlay-center-menu .navik-menu-overlay > ul > li {
  padding: 0 15px;
}

.overlay-center-menu .navik-menu-overlay > ul > li.menuSlideIn .menu-overlay-link .overlay-dropdown-plus {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.overlay-center-menu .navik-menu-overlay > ul > li.menuSlideIn > .menu-overlay-link a {
  -webkit-animation-name: menuFadeIn;
  animation-name: menuFadeIn;
}

.overlay-center-menu .navik-menu-overlay > ul > li.menuSlideIn > ul {
  -webkit-animation-name: menuFadeIn;
  animation-name: menuFadeIn;
}

.overlay-center-menu .navik-menu-overlay > ul > li > ul {
  padding: 15px 0 21px;
}

.overlay-center-menu .menu-overlay-link {
  position: relative;
  display: inline-block;
}

.overlay-center-menu .overlay-dropdown-plus {
  right: -30px;
}

.overlay-submenu-close > li {
  -webkit-animation-name: subMenuFadeOut;
  animation-name: subMenuFadeOut;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/***************************************************
  Overlay menu submenu vertical
****************************************************/
.submenu-vertical > ul ul li {
  margin-left: 0 !important;
}

.submenu-vertical > ul > li > ul li {
  position: relative;
  display: block;
  margin-bottom: 5px;
}

.submenu-vertical > ul > li > ul li:last-child {
  margin-bottom: 0 !important;
}

.submenu-vertical > ul > li > ul li .overlay-dropdown-plus {
  top: 4px;
  right: -35px;
}

.submenu-vertical > ul > li > ul ul {
  display: none;
  padding-top: 5px;
}

.submenu-vertical > ul > li > ul > li::after {
  display: none;
}

.submenu-vertical > ul > li.menuFade > ul {
  opacity: 1;
}

.submenu-vertical > ul > li.menuSlideIn > ul {
  -webkit-animation-name: none;
  animation-name: none;
}

.submenu-vertical > ul > li.menuSlideIn ul:not(.overlay-submenu-close) a {
  display: inline-block;
  -webkit-animation-name: menuFadeInLeft;
  animation-name: menuFadeInLeft;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.submenu-vertical > ul > li ul {
  position: relative;
  right: -30px;
  padding-right: 30px !important;
}

.submenu-vertical > ul > li ul:not(.overlay-submenu-close) a {
  opacity: 0;
}

.submenu-vertical > ul > li.menuSlideOut li a {
  -webkit-animation-name: subMenuFadeOut;
  animation-name: subMenuFadeOut;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.overlay-center-menu .submenu-vertical > ul > li > ul li .overlay-dropdown-plus {
  right: -30px;
}

.overlay-center-menu .submenu-vertical > ul > li ul {
  right: 0 !important;
  padding-right: 0 !important;
}

.overlay-center-menu .submenu-vertical > ul > li.menuSlideIn ul:not(.overlay-submenu-close) a {
  -webkit-animation-name: menuFadeIn;
  animation-name: menuFadeIn;
}

/***************************************************
  Overlay menu social media
****************************************************/
.menu-social-media {
  position: absolute;
  right: 0;
  bottom: 24px;
  width: 100%;
  padding: 0 65px;
  font-size: 0;
  text-align: right;
}

.menu-social-media a {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 0 0 6px 6px;
  border: 3px solid #202020;
  border-radius: 50%;
  transition: opacity 0.2s ease-in-out;
}

.menu-social-media a:hover {
  opacity: 0.8;
}

.menu-social-media a img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: 14px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.overlay-center-menu .menu-social-media {
  padding: 0 15px;
  text-align: center;
}

.overlay-center-menu .menu-social-media a {
  margin: 0 3px 6px;
}

/***************************************************
  Overlay slide menu
****************************************************/
.menu-slide .navik-menu-overlay > ul {
  display: block;
  padding: 120px 0 100px;
  text-align: left;
}

.menu-slide .navik-menu-overlay > ul > li {
  padding: 0 35px 0 30px;
}

.menu-slide .navik-menu-overlay > ul > li > ul {
  padding-top: 25px;
}

.menu-slide .navik-menu-overlay > ul > li > ul ul {
  display: none;
  padding-top: 5px;
  padding-left: 20px;
}

.menu-slide .navik-menu-overlay > ul > li > ul li {
  position: relative;
  margin-bottom: 5px;
  margin-left: 0 !important;
}

.menu-slide .navik-menu-overlay > ul > li > ul > li::after {
  display: none;
}

.menu-slide .navik-menu-overlay > ul > li ul .overlay-dropdown-plus {
  top: -1px;
  right: -5px;
}

.menu-slide .navik-menu-overlay > ul > li.menuSlideIn > ul {
  opacity: 1;
  -webkit-animation-name: none;
  animation-name: none;
}

.menu-slide .navik-menu-overlay > ul > li.menuSlideIn .menu-overlay-link .overlay-dropdown-plus {
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
}

.menu-slide .navik-menu-overlay > ul > li li {
  display: block;
}

.menu-slide .navik-menu-overlay > ul ul li a {
  position: relative;
  display: block;
  padding-left: 20px;
  transition: all 0.2s ease-in-out;
}

.menu-slide .navik-menu-overlay > ul ul li a::before {
  position: absolute;
  top: 2px;
  left: 5px;
  width: 1px;
  height: 13px;
  content: "";
  background-color: #d1d1d1;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.menu-slide .navik-menu-overlay > ul ul li a:hover {
  padding-left: 30px;
}

.menu-slide .navik-menu-overlay > ul ul:not(.overlay-submenu-close) a {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.menu-slide .navik-menu-overlay.submenu-no-separate > ul ul li a {
  padding-left: 15px;
}

.menu-slide .navik-menu-overlay.submenu-no-separate > ul ul li a::before {
  display: none !important;
}

.menu-slide .navik-menu-overlay.submenu-no-separate > ul ul li a:hover {
  padding-left: 25px;
}

.menu-slide .navik-menu-overlay .menu-overlay-link {
  padding-right: 30px;
}

.menu-slide .nav-menu-wrapper {
  right: 0;
  left: inherit;
  width: 320px;
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  transition-duration: 0.4s;
}

.menu-slide .nav-menu-wrapper.overlay-menu-open {
  box-shadow: -15px 0 60px -5px rgba(0, 0, 0, 0.3);
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.menu-slide .menu-social-media {
  right: inherit;
  left: 0;
  padding: 0 30px;
  text-align: left;
}

.menu-slide .menu-social-media a {
  margin: 0 6px 6px 0;
}

.slidemenu-bg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.1s;
}

.overlay-menu-open + .slidemenu-bg-overlay {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

@-webkit-keyframes menuFadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(40px, 0, 0);
    transform: translate3d(40px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes menuFadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(40px, 0, 0);
    transform: translate3d(40px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.menu-slide .navik-menu-overlay > ul > li.menuSlideIn > .menu-overlay-link a, .menu-slide .navik-menu-overlay > ul > li.menuSlideIn ul:not(.overlay-submenu-close) a {
  -webkit-animation-name: menuFadeInRight;
  animation-name: menuFadeInRight;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/***************************************************
  Overlay menu dark
****************************************************/
.header-dark .nav-menu-wrapper {
  background-color: #111;
}

.header-dark .navik-menu-overlay > ul > li > .menu-overlay-link a {
  color: #fff;
}

.header-dark .navik-menu-overlay > ul ul li a {
  color: #a1a1a1;
}

.header-dark .overlay-dropdown-plus::before, .header-dark .overlay-dropdown-plus::after {
  background-color: #717171;
}

.header-dark .menu-social-media a {
  border-color: #fff;
  opacity: 0.2;
}

.header-dark .menu-social-media a:hover {
  opacity: 1;
}

.navik-header-overlay.header-dark .burger-menu.menu-open {
  background-color: #000;
}

.header-dark .navik-menu-overlay > ul > li > ul > li::after, .menu-slide.header-dark .navik-menu-overlay > ul ul li a::before {
  background-color: #515151 !important;
}

/***************************************************
  Overlay menu background color
****************************************************/
.header-color-bg .nav-menu-wrapper {
  background-color: #006aa1;
}

.header-color-bg .navik-menu-overlay > ul > li > .menu-overlay-link a {
  color: #fff;
}

.header-color-bg .navik-menu-overlay > ul ul li a {
  color: #fff;
}

.header-color-bg .menu-social-media a {
  border-color: #fff;
}

.header-color-bg .navik-menu-overlay > ul > li > ul li::after, .header-color-bg .overlay-dropdown-plus::before, .header-color-bg .overlay-dropdown-plus::after, .menu-slide.header-color-bg .navik-menu-overlay > ul ul li a::before {
  background-color: #fff !important;
}

.header-color-bg .navik-menu-overlay > ul > li.current-menu > .menu-overlay-link a, .header-color-bg .overlay-dropdown-plus:hover + a, .header-color-bg .overlay-menu-hover > .menu-overlay-link a {
  color: #fff !important;
}

.header-color-bg .navik-menu-overlay > ul > li > .menu-overlay-link a:hover, .header-color-bg .navik-menu-overlay > ul ul li a:hover {
  color: rgba(255, 255, 255, 0.8) !important;
}

/***************************************************
  Overlay menu background image
****************************************************/
.header-bg-image .nav-menu-wrapper {
  background-color: inherit;
  background-image: url("../images/upload/menu-overlay-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.header-bg-image .navik-menu-overlay > ul > li > .menu-overlay-link a {
  color: #fff;
}

.header-bg-image .navik-menu-overlay > ul > li > ul > li::after {
  background-color: rgba(255, 255, 255, 0.25) !important;
}

.header-bg-image .navik-menu-overlay > ul ul li a {
  color: rgba(255, 255, 255, 0.75);
}

.header-bg-image .overlay-dropdown-plus::before, .header-bg-image .overlay-dropdown-plus::after {
  background-color: #fff;
}

.header-bg-image .menu-social-media a {
  border-color: #fff;
}

.header-bg-image .menu-social-media a:hover {
  opacity: 0.6;
}

/***************************************************
  Fixed sidebar menu
****************************************************/
.navik-fixed-sidebar {
  position: fixed;
  top: 0;
  z-index: 100001;
  width: 250px;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
  transition: all 0.4s ease-in-out;
}

.navik-fixed-sidebar + .burger-menu {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 100001;
  display: block;
  -ms-flex-direction: inherit;
  flex-direction: inherit;
  -ms-flex-pack: inherit;
  justify-content: inherit;
  width: 50px;
  height: 47px;
  padding: 15px;
  background: #111;
  transition: all 0.2s ease-in-out;
}

.navik-fixed-sidebar + .burger-menu > span {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  height: 17px;
  transition: -webkit-transform 330ms ease-out;
  transition: transform 330ms ease-out;
  transition: transform 330ms ease-out, -webkit-transform 330ms ease-out;
}

.navik-fixed-sidebar + .burger-menu .line-menu {
  background-color: #fff;
}

.navik-fixed-sidebar + .burger-menu.menu-open {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.navik-fixed-sidebar + .burger-menu.menu-open > span {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.navik-fixed-sidebar .navik-header-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 100%;
  padding: 0 30px;
}

.navik-fixed-sidebar .menu-social-media {
  position: inherit;
  bottom: inherit;
  -ms-flex-item-align: end;
  align-self: flex-end;
  padding: 0 0 30px !important;
  text-align: left;
}

.navik-fixed-sidebar .menu-social-media a {
  margin: 0 6px 6px 0;
}

.navik-fixed-sidebar .menu-social-media a:last-child {
  margin-right: 0;
}

.navik-fixed-sidebar .logo {
  display: none;
  padding: 80px 0;
}

.body-fixed-sidebar {
  overflow-x: hidden;
}

.navik-sidebar-left {
  left: 0;
  box-shadow: 2px 0 12px -2px rgba(0, 0, 0, 0.15);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.navik-sidebar-left.fixed-sidebar-open {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

.navik-sidebar-left.fixed-sidebar-open ~ .navik-side-content {
  -webkit-transform: translateX(125px);
  transform: translateX(125px);
}

.navik-sidebar-left + .burger-menu {
  top: 0;
  right: inherit;
  left: 0;
}

.navik-sidebar-right {
  right: 0;
  box-shadow: -2px 0 12px -2px rgba(0, 0, 0, 0.15);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.navik-sidebar-right.fixed-sidebar-open {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

.navik-sidebar-right.fixed-sidebar-open ~ .navik-side-content {
  -webkit-transform: translateX(-125px);
  transform: translateX(-125px);
}

.navik-sidebar-right + .burger-menu {
  top: 0;
  right: 0;
}

.fixedsidebar-bg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.navik-side-content {
  transition: all 0.4s ease-in-out;
}

.navik-side-content > .logo {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 47px;
  padding: 0 50px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 2px 12px -2px rgba(0, 0, 0, 0.15);
}

.navik-side-content > .logo img {
  width: auto;
  max-width: inherit;
  max-height: 47px;
}

.fixed-sidebar-open ~ .fixedsidebar-bg-overlay {
  visibility: visible;
  opacity: 1;
}

.fixed-menu-wrap {
  width: 100%;
}

.navik-menu-fixed {
  padding-top: 90px;
}

.navik-menu-fixed ul li {
  position: relative;
}

.navik-menu-fixed ul li a {
  display: block;
  padding-right: 17px;
  font-family: "Montserrat", sans-serif;
  transition: all 0.2s ease-in-out;
}

.navik-menu-fixed > ul {
  margin-bottom: 40px;
}

.navik-menu-fixed > ul > li {
  margin-bottom: 14px;
}

.navik-menu-fixed > ul > li:last-child {
  margin-bottom: 0;
}

.navik-menu-fixed > ul > li > a {
  font-size: 18px;
  font-weight: 700;
  color: #202020;
  text-transform: uppercase;
}

.navik-menu-fixed > ul > li > ul {
  padding: 27px 0 15px;
}

.navik-menu-fixed > ul > li > ul ul {
  padding-top: 6px;
}

.navik-menu-fixed > ul > li > ul > li ul {
  padding-left: 18px;
}

.navik-menu-fixed > ul ul {
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.navik-menu-fixed > ul ul.submenu-collapse {
  opacity: 1;
}

.navik-menu-fixed > ul ul li {
  margin-bottom: 6px;
}

.navik-menu-fixed > ul ul li:last-child {
  margin-bottom: 0;
}

.navik-menu-fixed > ul ul a {
  position: relative;
  padding-left: 18px;
  font-size: 14px;
  font-style: italic;
  color: #818181;
}

.navik-menu-fixed > ul ul a::before {
  position: absolute;
  top: 5px;
  left: 4px;
  width: 1px;
  height: 12px;
  content: "";
  background-color: #d1d1d1;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.navik-menu-fixed > ul ul a:hover {
  padding-left: 24px;
}

.navik-menu-fixed > ul ul .overlay-dropdown-plus {
  top: 1px;
}

.navik-menu-fixed .overlay-dropdown-plus {
  top: 4px;
  right: -7px;
  opacity: 1;
}

.navik-menu-fixed.submenu-no-separate > ul ul a {
  padding-left: 12px;
}

.navik-menu-fixed.submenu-no-separate > ul ul a::before {
  display: none !important;
}

.navik-menu-fixed.submenu-no-separate > ul ul a:hover {
  padding-left: 18px;
}

.navik-menu-fixed.submenu-no-separate > ul > li > ul > li ul {
  padding-left: 12px;
}

.navik-menu-fixed ul li:hover > a, .navik-menu-fixed > ul > li.current-menu > a {
  color: #2196f3 !important;
}

/***************************************************
  Fixed sidebar menu dark
****************************************************/
.navik-fixed-sidebar.sidebar-dark {
  background-color: #111;
}

.navik-fixed-sidebar.sidebar-dark .menu-social-media a {
  border-color: #fff;
  opacity: 0.2;
}

.navik-fixed-sidebar.sidebar-dark .menu-social-media a:hover {
  opacity: 1;
}

.sidebar-dark ~ .navik-side-content > .logo {
  background-color: #111;
}

.sidebar-dark .navik-menu-fixed > ul > li > a {
  color: #fff;
}

.sidebar-dark .navik-menu-fixed > ul ul a {
  color: #a1a1a1;
}

.sidebar-dark .navik-menu-fixed > ul ul a::before {
  background-color: #515151;
}

.sidebar-dark .overlay-dropdown-plus::before, .sidebar-dark .overlay-dropdown-plus::after {
  background-color: #717171;
}

/***************************************************
  Fixed sidebar menu background color
****************************************************/
.navik-fixed-sidebar.sidebar-color-bg {
  background-color: #006aa1;
}

.navik-fixed-sidebar.sidebar-color-bg .menu-social-media a {
  border-color: #fff;
}

.sidebar-color-bg ~ .navik-side-content > .logo {
  background-color: #006aa1;
}

.sidebar-color-bg .navik-menu-fixed ul li a:hover {
  color: rgba(255, 255, 255, 0.8) !important;
}

.sidebar-color-bg .navik-menu-fixed > ul > li > a, .sidebar-color-bg .navik-menu-fixed > ul ul a {
  color: #fff !important;
}

.sidebar-color-bg .navik-menu-fixed > ul ul a::before, .sidebar-color-bg .overlay-dropdown-plus::before, .sidebar-color-bg .overlay-dropdown-plus::after {
  background-color: #fff;
}

/***************************************************
  Mega menu
****************************************************/
.navik-menu .mega-menu-container {
  padding: 30px 30px 0;
}

.navik-menu .mega-menu-container ul li {
  position: relative;
}

.navik-menu .mega-menu-container ul li a {
  padding: inherit;
  font-style: inherit;
  color: inherit;
  background-color: inherit;
}

.navik-menu .mega-menu-container ul li:hover a {
  background-color: inherit;
}

.navik-menu .mega-menu-container li > ul {
  display: block;
}

.navik-menu .mega-menu > ul .dropdown-plus {
  display: none;
}

.navik-menu .mega-menu-box {
  margin-bottom: 40px;
}

.navik-menu .mega-menu-heading {
  margin-bottom: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #202020;
  text-transform: uppercase;
}

.navik-menu .mega-menu-heading a {
  color: #202020;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

.navik-menu .mega-menu-heading a:hover {
  color: #2196f3;
}

.navik-menu .mega-menu-desc {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-style: italic;
  line-height: 1.6;
  color: #818181;
}

.navik-menu ul.mega-menu-list li a {
  padding: 6px 0;
  font-size: 13px;
  font-style: italic;
  color: #818181;
}

.navik-menu ul.mega-menu-list li a:hover {
  color: #2196f3;
}

.navik-menu ul.mega-menu-list ul {
  padding-left: 15px !important;
}

.navik-menu .mega-menu-thumbnail {
  margin-bottom: 24px;
}

.navik-menu .mega-menu-thumbnail img {
  width: 100%;
  height: auto;
}

.navik-menu .mega-menu-thumbnail > a {
  position: relative;
  display: block;
}

.navik-menu .mega-menu-thumbnail > a::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s ease-in-out;
}

.navik-menu .mega-menu-thumbnail:hover > a::before {
  background-color: rgba(0, 0, 0, 0.2);
}

/***************************************************
  Mega menu dark
****************************************************/
.header-dark .navik-menu .mega-menu-container ul li a {
  background-color: inherit;
}

.header-dark .navik-menu .mega-menu-heading a {
  color: #fff;
}

.header-dark .navik-menu .mega-menu-heading a:hover {
  color: #2196f3;
}

/***************************************************
  Menu icons
****************************************************/
.navik-menu .navik-menu-icon {
  margin-right: 5px;
  color: #a1a1a1;
}

.navik-menu ul li > a .navik-menu-icon, .navik-menu .mega-menu-heading a .navik-menu-icon {
  transition: color 0.2s ease-in-out;
}

.navik-menu ul li.current-menu > a .navik-menu-icon, .navik-menu ul li:hover > a .navik-menu-icon, .navik-menu .mega-menu-heading a:hover .navik-menu-icon {
  color: #2196f3 !important;
}

.header-dark .navik-menu .navik-menu-icon {
  color: #717171;
}

.navik-menu-overlay .navik-menu-icon {
  position: relative;
  top: -2px;
  margin-right: 10px;
}

.navik-menu-overlay > ul ul li a .navik-menu-icon {
  top: -1px;
  margin-right: 7px;
}

.menu-slide .navik-menu-overlay > ul ul li a .navik-menu-icon {
  margin-right: 5px;
}

.navik-menu-fixed .navik-menu-icon {
  margin-right: 5px;
}

/***************************************************
  Media queries for responsive design
****************************************************/
@media (max-width: 767.98px) {
  .navik-header,
  .navik-header-overlay,
  .body-fixed-sidebar {
    width: 100%;
  }
}

@media (max-width: 1279.98px) {
  .navik-header,
  .navik-header-overlay,
  .body-fixed-sidebar {
    width: 100%;
  }
}

@media (min-width: 768px) {
  /********** Default menu **********/
  .navik-header .logo {
    float: left;
    padding: 19px 40px 19px 0;
  }
  .navik-menu {
    clear: both;
  }
  .header-shadow-wrapper::after {
    box-shadow: 0 10px 40px -5px rgba(0, 0, 0, 0.1);
  }
  /********** Overlay dropdown plus **********/
  .overlay-dropdown-plus {
    top: 7px;
    right: 43px;
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
  .overlay-dropdown-plus::before, .overlay-dropdown-plus::after {
    top: 11px;
    right: 7px;
  }
  /********** Overlay menu **********/
  .navik-header-overlay .logo {
    left: 30px;
  }
  .navik-header-overlay .burger-menu {
    right: 30px;
  }
  .navik-menu-overlay > ul > li {
    padding: 0 110px 0 30px;
    margin-bottom: 16px;
  }
  .navik-menu-overlay > ul > li > .menu-overlay-link a {
    font-size: 34px;
  }
  .navik-menu-overlay > ul > li > ul {
    padding: 13px 0 16px;
  }
  .navik-menu-overlay > ul > li > ul > li::after {
    top: 1px;
    left: -23px;
    height: 16px;
  }
  .navik-menu-overlay > ul ul li {
    margin-left: 42px;
  }
  .navik-menu-overlay > ul ul li a {
    font-size: 16px;
  }
  .navik-menu-overlay.submenu-no-separate > ul ul li {
    margin-left: 25px;
  }
  .overlay-center-menu .navik-menu-overlay > ul > li {
    padding: 0 30px;
  }
  .overlay-center-menu .navik-menu-overlay > ul > li > ul {
    padding: 18px 0 21px;
  }
  .overlay-center-menu .menu-social-media {
    padding: 0 40px;
  }
  .overlay-center-menu .overlay-dropdown-plus, .overlay-center-menu .submenu-vertical > ul > li > ul li .overlay-dropdown-plus {
    right: -42px;
  }
  .submenu-vertical > ul > li ul {
    right: -60px;
    padding-right: 60px !important;
  }
  .submenu-vertical > ul > li > ul li {
    margin-bottom: 7px;
  }
  .submenu-vertical > ul > li > ul li .overlay-dropdown-plus {
    top: 1px;
    right: -67px;
  }
  .submenu-vertical > ul > li > ul ul {
    padding-top: 7px;
  }
  .menu-social-media {
    bottom: 34px;
    padding: 0 110px;
  }
  /********** Overlay slide menu **********/
  .menu-slide .nav-menu-wrapper {
    width: 400px;
  }
  .menu-slide .navik-menu-overlay > ul {
    padding: 140px 0 100px;
  }
  .menu-slide .navik-menu-overlay > ul > li {
    padding: 0 60px 0 45px;
    margin-bottom: 14px;
  }
  .menu-slide .navik-menu-overlay > ul > li > ul {
    padding: 28px 0 16px;
  }
  .menu-slide .navik-menu-overlay > ul > li > ul li {
    margin-bottom: 7px;
  }
  .menu-slide .navik-menu-overlay > ul > li > ul ul {
    padding-top: 7px;
    padding-left: 23px;
  }
  .menu-slide .navik-menu-overlay > ul > li ul .overlay-dropdown-plus {
    top: -2px;
    right: -17px;
  }
  .menu-slide .navik-menu-overlay > ul ul li a {
    padding-left: 23px;
  }
  .menu-slide .navik-menu-overlay > ul ul li a:hover {
    padding-left: 33px;
  }
  .menu-slide .navik-menu-overlay > ul ul li a::before {
    height: 15px;
  }
  .menu-slide .menu-social-media {
    padding: 0 45px;
  }
  /********** Mega menu **********/
  .navik-menu .mega-menu-media {
    display: table;
    width: 100%;
  }
  .navik-menu .mega-menu-media-img {
    display: table-cell;
    width: 120px;
    vertical-align: top;
  }
  .navik-menu .mega-menu-media-img .mega-menu-thumbnail {
    margin-bottom: 0;
  }
  .navik-menu .mega-menu-media-img img {
    width: 100%;
    height: auto;
  }
  .navik-menu .mega-menu-media-info {
    display: table-cell;
    padding-left: 20px;
    vertical-align: top;
  }
  /********** Menu icons **********/
  .navik-menu-overlay .navik-menu-icon {
    margin-right: 12px;
  }
  .navik-menu-overlay > ul ul li a .navik-menu-icon {
    margin-right: 8px;
  }
  .menu-slide .navik-menu-overlay .navik-menu-icon {
    margin-right: 10px;
  }
}

@media (min-width: 1280px) {
  /********** Default menu **********/
  .navik-header-container {
    height: 90px;
  }
  .navik-header .logo {
    position: relative;
    top: 50%;
    padding: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .navik-header .logo img {
    width: auto;
    max-width: inherit;
    max-height: 90px;
  }
  .burger-menu {
    display: none;
  }
  .navik-menu {
    float: right;
    clear: inherit;
  }
  .navik-menu > ul {
    display: block !important;
    padding: 0;
    font-size: 0;
  }
  .navik-menu > ul > li {
    display: inline-block;
  }
  .navik-menu > ul > li > a {
    position: relative;
    box-sizing: border-box;
    height: 90px;
    padding: 0 20px;
    line-height: 90px;
    white-space: nowrap;
  }
  .navik-menu > ul > li:last-child > a {
    padding: 0 0 0 20px;
  }
  .navik-menu ul li {
    position: relative;
  }
  .navik-menu ul li.dropdown_menu > a > span {
    display: none;
  }
  .navik-menu ul li.dropdown_menu > a > span::before {
    position: relative;
    top: -3px;
    box-sizing: content-box;
    display: inline-block;
    width: 4px;
    height: 4px;
    margin-left: 8px;
    content: "";
    border-color: #c1c1c1;
    border-style: solid;
    border-width: 2px 2px 0 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  .navik-menu ul ul li.dropdown_menu > a > span::before {
    top: 7px;
    right: -10px;
    float: right;
    margin: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .navik-menu ul ul li > a {
    position: relative;
    padding: 10px 35px !important;
    color: #818181;
    background-color: transparent;
  }
  .navik-menu ul ul li > a::before {
    position: absolute;
    top: 17px;
    left: 35px;
    width: 0;
    height: 1px;
    content: "";
    background: #a1a1a1;
    transition: all 0.2s ease-in-out;
  }
  .navik-menu ul ul li:hover > a {
    padding-left: 55px !important;
    color: #818181;
    background-color: transparent;
  }
  .navik-menu ul ul li:hover > a::before {
    width: 10px;
  }
  .navik-menu li > ul {
    position: absolute;
    top: 100%;
    left: 0;
    display: block !important;
    width: 250px;
    padding: 27px 0;
    visibility: hidden;
    background-color: #fff;
    box-shadow: 0 12px 30px -2px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
  .navik-menu li > ul ul {
    top: -27px;
    left: 100%;
  }
  .navik-menu li:hover > ul {
    visibility: visible;
    opacity: 1;
  }
  .navik-menu li.submenu-right > ul {
    right: 0;
    left: inherit;
  }
  .navik-menu li.submenu-right > ul ul {
    left: -100% !important;
  }
  .navik-menu.menu-caret ul li.dropdown_menu > a > span {
    display: inline-block;
  }
  .navik-menu.menu-caret ul ul li.dropdown_menu > a > span {
    display: inline;
  }
  .navik-menu.separate-line > ul > li > a {
    position: relative;
    padding: 0 28px;
  }
  .navik-menu.separate-line > ul > li > a::before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 20px;
    content: "";
    background-color: #eee;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .navik-menu.separate-line > ul > li:last-child > a {
    padding: 0 0 0 28px;
  }
  .navik-menu.separate-line > ul > li:last-child > a::before {
    display: none;
  }
  .header-dark .navik-menu ul li.dropdown_menu > a > span::before {
    border-color: #a1a1a1;
  }
  .header-dark .navik-menu ul ul li > a {
    color: #a1a1a1;
    background-color: transparent;
  }
  .header-dark .navik-menu ul ul li:hover > a {
    color: #fff;
    background-color: transparent;
  }
  .header-dark .navik-menu ul ul li:hover > a .navik-menu-icon {
    color: #fff !important;
  }
  .header-dark .navik-menu li > ul {
    background-color: #111;
  }
  .header-dark .navik-menu.separate-line > ul > li > a::before {
    background-color: #333;
  }
  .navik-menu.menu-hover-2 > ul > li:last-child > a, .navik-menu.menu-hover-3 > ul > li:last-child > a, .navik-menu.menu-hover-4 > ul > li:last-child > a, .center-menu-1 .navik-menu > ul > li:last-child > a,
  .center-menu-2 .navik-menu > ul > li:last-child > a {
    padding: 0 20px;
  }
  .navik-menu.menu-hover-2.separate-line > ul > li:last-child > a, .navik-menu.menu-hover-3.separate-line > ul > li:last-child > a, .navik-menu.menu-hover-4.separate-line > ul > li:last-child > a, .center-menu-1 .navik-menu.separate-line > ul > li:last-child > a,
  .center-menu-2 .navik-menu.separate-line > ul > li:last-child > a {
    padding: 0 28px;
  }
  .navik-menu ul li.current-menu > a, .navik-menu ul li:hover > a, .header-dark .navik-menu ul li.current-menu > a, .header-dark .navik-menu ul li:hover > a {
    background-color: inherit;
  }
  .dropdown-plus {
    display: none;
  }
  /********** Header sticky **********/
  .navik-header.sticky {
    position: fixed;
    width: 100%;
    -webkit-animation-name: stickyTransition;
    animation-name: stickyTransition;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  .navik-header.sticky .navik-header-container {
    height: 70px;
  }
  .navik-header.sticky .navik-menu > ul > li > a {
    height: 70px;
    line-height: 70px;
  }
  .navik-header.sticky .logo {
    padding: 0 40px 0 0;
  }
  .navik-header.sticky .logo img {
    max-height: 70px;
  }
  @-webkit-keyframes stickyTransition {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @keyframes stickyTransition {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  /********** Center menu **********/
  .navik-header.center-menu-1 {
    position: relative;
  }
  .navik-header.center-menu-1.sticky {
    position: fixed;
  }
  .navik-header.center-menu-1.sticky .logo {
    padding: 0;
  }
  .navik-header.center-menu-1 .navik-header-container > .logo {
    display: none;
  }
  .navik-header.center-menu-1 .logoCenter .logo {
    top: 0;
    float: none;
    width: inherit;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .navik-header.center-menu-2 .navik-header-container {
    height: auto;
  }
  .navik-header.center-menu-2 .logo {
    top: 0;
    float: none;
    padding: 36px 30px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .navik-header.center-menu-2 .logo img {
    max-width: 100%;
    height: auto;
    max-height: inherit;
  }
  .navik-header.center-menu-2.sticky .logo {
    display: none;
  }
  .navik-header.center-menu-2.sticky .navik-menu {
    border: none;
  }
  .logoCenter {
    display: table-cell !important;
    text-align: center;
    vertical-align: middle;
  }
  .center-menu-1 .navik-menu {
    display: table !important;
    float: none;
    width: 100%;
  }
  .center-menu-1 .navik-menu > ul {
    display: table-cell !important;
    vertical-align: top;
  }
  .center-menu-1 .navik-menu > ul:first-child {
    padding: 0 40px;
    text-align: right;
  }
  .center-menu-1 .navik-menu > ul:first-child ul {
    text-align: left;
  }
  .center-menu-1 .navik-menu > ul:last-child {
    padding: 0 40px;
    text-align: left;
  }
  .center-menu-2 .navik-menu {
    float: none;
    text-align: center;
    border-top: 1px solid #e9e9e9;
  }
  .center-menu-2 .navik-menu li > ul {
    text-align: left;
  }
  .center-menu-2 .navik-menu > ul > li > a {
    height: 62px;
    line-height: 62px;
  }
  .center-menu-2.header-dark .navik-menu {
    border-color: #333;
  }
  /********** Header transparent **********/
  .navik-header.header-transparent-on, .navik-header.header-opacity-on {
    position: absolute;
    width: 100%;
    background-color: transparent;
  }
  .navik-header.header-opacity-on {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .header-transparent-on .header-shadow-wrapper,
  .header-opacity-on .header-shadow-wrapper {
    display: none;
  }
  .header-transparent-on .navik-menu > ul > li > a,
  .header-opacity-on .navik-menu > ul > li > a {
    color: #fff;
  }
  .header-transparent-on .navik-menu > ul > li > a .navik-menu-icon,
  .header-opacity-on .navik-menu > ul > li > a .navik-menu-icon {
    color: rgba(255, 255, 255, 0.85);
  }
  .header-transparent-on .navik-menu > ul > li:hover > a, .header-transparent-on .navik-menu > ul > li.current-menu > a,
  .header-opacity-on .navik-menu > ul > li:hover > a,
  .header-opacity-on .navik-menu > ul > li.current-menu > a {
    color: #2196f3;
  }
  .header-transparent-on .navik-menu > ul > li.dropdown_menu > a > span::before,
  .header-opacity-on .navik-menu > ul > li.dropdown_menu > a > span::before {
    border-color: #fff;
  }
  .header-transparent-on.center-menu-2 .navik-menu {
    border-color: rgba(255, 255, 255, 0.25);
  }
  .header-transparent-on .navik-menu.separate-line > ul > li > a::before {
    background-color: rgba(255, 255, 255, 0.25);
  }
  .header-opacity-on.center-menu-2 .navik-menu {
    border-color: rgba(255, 255, 255, 0.2);
  }
  .header-opacity-on .navik-menu.separate-line > ul > li > a::before {
    background-color: rgba(255, 255, 255, 0.2);
  }
  /********** Hover transitions **********/
  .navik-menu.menu-hover-2 > ul > li.current-menu > a, .navik-menu.menu-hover-2 > ul > li:hover > a {
    color: #202020;
    background-color: #f8f8f8;
  }
  .navik-menu.menu-hover-2 > ul > li.current-menu > a::after, .navik-menu.menu-hover-2 > ul > li:hover > a::after {
    opacity: 1;
  }
  .navik-menu.menu-hover-2 > ul > li > a::after, .navik-menu.menu-hover-3 > ul > li > a::after {
    position: absolute;
    bottom: -3px;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 3px;
    content: "";
    background-color: #2196f3;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
  .navik-menu.menu-hover-3 > ul > li.current-menu > a, .navik-menu.menu-hover-3 > ul > li:hover > a {
    color: #202020;
  }
  .navik-menu.menu-hover-3 > ul > li.current-menu > a::after, .navik-menu.menu-hover-3 > ul > li:hover > a::after {
    width: 100%;
    opacity: 1;
  }
  .navik-menu.menu-hover-3 > ul > li > a::after {
    left: 50%;
    width: 0;
    transition: all 0.3s ease-in-out;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .navik-menu.menu-hover-4 > ul > li > a > .hover-transition {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .navik-menu.menu-hover-4 > ul > li > a > .hover-transition::after {
    position: relative;
    top: 50%;
    left: 50%;
    display: block;
    width: calc(100% + 2px);
    height: 0;
    content: "";
    background-color: #2196f3;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .navik-menu.menu-hover-4 > ul > li.current-menu > a, .navik-menu.menu-hover-4 > ul > li:hover > a {
    color: #fff;
  }
  .navik-menu.menu-hover-4 > ul > li.current-menu > a > .hover-transition::after, .navik-menu.menu-hover-4 > ul > li:hover > a > .hover-transition::after {
    height: 100%;
    opacity: 1;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .navik-menu.menu-hover-4 > ul > li.current-menu > a .navik-menu-icon, .navik-menu.menu-hover-4 > ul > li:hover > a .navik-menu-icon {
    color: #fff !important;
  }
  .navik-menu.menu-hover-4 > ul > li > ul {
    transition-delay: 0s;
  }
  .navik-menu.menu-hover-4 > ul > li:hover > ul {
    transition-delay: 0.13s;
  }
  .navik-menu.menu-hover-4 > ul > li.dropdown_menu.current-menu > a > span::before, .navik-menu.menu-hover-4 > ul > li.dropdown_menu:hover > a > span::before {
    border-color: #fff;
  }
  .header-dark .navik-menu.menu-hover-2 > ul > li.current-menu > a, .header-dark .navik-menu.menu-hover-2 > ul > li:hover > a {
    color: #fff;
    background-color: #222;
  }
  .header-dark .navik-menu.menu-hover-3 > ul > li.current-menu > a, .header-dark .navik-menu.menu-hover-3 > ul > li:hover > a {
    color: #fff;
  }
  .header-transparent-on .navik-menu.menu-hover-2 > ul > li.current-menu > a, .header-transparent-on .navik-menu.menu-hover-2 > ul > li:hover > a {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.25);
  }
  .header-opacity-on .navik-menu.menu-hover-2 > ul > li.current-menu > a, .header-opacity-on .navik-menu.menu-hover-2 > ul > li:hover > a {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.2);
  }
  .header-transparent-on .navik-menu.menu-hover-3 > ul > li.current-menu > a, .header-transparent-on .navik-menu.menu-hover-3 > ul > li:hover > a, .header-opacity-on .navik-menu.menu-hover-3 > ul > li.current-menu > a, .header-opacity-on .navik-menu.menu-hover-3 > ul > li:hover > a {
    color: #fff;
  }
  .header-transparent-on .navik-menu.menu-hover-2 > ul > li.current-menu > a .navik-menu-icon, .header-transparent-on .navik-menu.menu-hover-2 > ul > li:hover > a .navik-menu-icon, .header-transparent-on .navik-menu.menu-hover-3 > ul > li.current-menu > a .navik-menu-icon, .header-transparent-on .navik-menu.menu-hover-3 > ul > li:hover > a .navik-menu-icon, .header-opacity-on .navik-menu.menu-hover-2 > ul > li.current-menu > a .navik-menu-icon, .header-opacity-on .navik-menu.menu-hover-2 > ul > li:hover > a .navik-menu-icon, .header-opacity-on .navik-menu.menu-hover-3 > ul > li.current-menu > a .navik-menu-icon, .header-opacity-on .navik-menu.menu-hover-3 > ul > li:hover > a .navik-menu-icon {
    color: rgba(255, 255, 255, 0.85) !important;
  }
  /********** Submenu **********/
  .navik-menu.submenu-top-border li > ul {
    border-top: 3px solid #2196f3;
  }
  .navik-menu.submenu-top-border li > ul ul {
    top: -30px;
  }
  .navik-menu.submenu-top-border.submenu-list-border li > ul ul {
    top: -25px;
  }
  .navik-menu.submenu-list-border li > ul ul {
    top: -20px;
  }
  .navik-menu.submenu-list-border ul ul li > a {
    padding: 15px 35px 16px !important;
  }
  .navik-menu.submenu-list-border ul ul li:first-child > a {
    padding: 10px 35px 16px !important;
  }
  .navik-menu.submenu-list-border ul ul li:last-child > a {
    padding: 15px 35px 10px !important;
  }
  .navik-menu.submenu-list-border ul ul li:last-child::after {
    display: none;
  }
  .navik-menu.submenu-list-border ul ul li::after {
    position: absolute;
    right: 35px;
    bottom: 0;
    left: 35px;
    height: 1px;
    content: "";
    background-color: #eee;
  }
  .navik-menu.submenu-list-border ul ul li:hover > a {
    padding-left: 55px !important;
  }
  .navik-menu.submenu-list-border ul ul li:nth-child(n+2) > a::before {
    top: 24px;
  }
  .header-dark .navik-menu.submenu-list-border ul ul li::after {
    background-color: #333;
  }
  /********** Submenu transitions **********/
  .navik-menu.submenu-flip li > ul {
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: all 0.35s ease-in-out;
    -webkit-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
  }
  .navik-menu.submenu-flip li:hover > ul {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  .navik-menu.submenu-flip ul {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: 200px;
    perspective: 200px;
  }
  .navik-menu.submenu-flip ul li {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: 200px;
    perspective: 200px;
  }
  .navik-menu.submenu-scale li > ul {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    transition: all 0.3s ease;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  .navik-menu.submenu-scale li.submenu-right ul {
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
  }
  .navik-menu.submenu-scale li:hover > ul {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  /********** Overlay dropdown plus **********/
  .overlay-dropdown-plus {
    right: 53px;
  }
  /********** Overlay menu **********/
  .navik-header-overlay .navik-header-container {
    height: auto;
  }
  .navik-header-overlay .logo {
    top: 40px;
    left: 40px;
  }
  .navik-header-overlay .burger-menu {
    top: 40px;
    right: 40px;
  }
  .navik-menu-overlay > ul > li {
    padding: 0 130px 0 40px;
  }
  .overlay-center-menu .navik-menu-overlay > ul > li {
    padding: 0 40px;
  }
  .submenu-vertical > ul > li ul {
    right: -70px;
    padding-right: 70px !important;
  }
  .submenu-vertical > ul > li > ul li .overlay-dropdown-plus {
    right: -77px;
  }
  .menu-slide .navik-menu-overlay > ul > li ul .overlay-dropdown-plus {
    right: -7px;
  }
  .menu-social-media {
    padding: 0 130px;
  }
  /********** Mega menu **********/
  .navik-mega-menu.mega-menu-fullwidth .navik-header-container {
    position: inherit;
  }
  .navik-mega-menu.mega-menu-fullwidth, .navik-mega-menu .navik-header-container {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: 200px;
    perspective: 200px;
  }
  .navik-menu .mega-menu-container {
    padding: 0 15px;
  }
  .navik-menu .mega-menu-container ul.mega-menu-list li > a::before {
    top: 13px !important;
    left: 0;
    display: block;
  }
  .navik-menu.submenu-flip .mega-menu > ul {
    -webkit-perspective: inherit;
    perspective: inherit;
  }
  .navik-menu.submenu-flip .mega-menu-container li > ul {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  .navik-menu.submenu-scale .mega-menu-container li > ul {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .navik-menu ul li.mega-menu {
    position: inherit;
    font-size: initial;
  }
  .navik-menu li.mega-menu > ul {
    width: 100%;
    padding: 40px 20px;
  }
  .navik-menu li.mega-menu:hover .mega-menu-container li > ul {
    visibility: visible;
    opacity: 1;
  }
  .navik-menu li.mega-menu.submenu-right > ul ul {
    left: inherit !important;
  }
  .navik-mega-menu.mega-menu-fullwidth .navik-header-container, .navik-mega-menu .navik-menu.submenu-flip > ul, .navik-mega-menu .navik-menu.submenu-flip ul li.mega-menu {
    -webkit-transform-style: unset;
    transform-style: unset;
    -webkit-perspective: none;
    perspective: none;
  }
  .navik-menu.submenu-scale li.mega-menu ul, .navik-menu.submenu-scale li.mega-menu.submenu-right ul {
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
  }
  .navik-menu .mega-menu-container li ul, .navik-menu.submenu-top-border .mega-menu-container li ul, .navik-menu.submenu-list-border .mega-menu-container li ul {
    position: relative;
    top: 0 !important;
    left: inherit;
    width: 100%;
    padding: 0;
    border-top: none;
    box-shadow: inherit;
  }
  .navik-menu .mega-menu-container ul li > a::before, .navik-menu.submenu-list-border .mega-menu-container ul li::after, .navik-menu.menu-caret .mega-menu-container li.dropdown_menu > a > span {
    display: none;
  }
  .navik-menu .mega-menu-container ul li a, .navik-menu .mega-menu-container ul li:hover a, .navik-menu.submenu-list-border .mega-menu-container ul li a, .navik-menu.submenu-list-border .mega-menu-container ul li:hover a, .navik-menu.submenu-list-border .mega-menu-container ul li:first-child a, .navik-menu.submenu-list-border .mega-menu-container ul li:first-child:hover a, .navik-menu.submenu-list-border .mega-menu-container ul li:last-child a, .navik-menu.submenu-list-border .mega-menu-container ul li:last-child:hover a {
    padding: inherit !important;
  }
  .navik-menu .mega-menu-container ul.mega-menu-list li a, .navik-menu .mega-menu-container ul.mega-menu-list li:hover a, .navik-menu.submenu-list-border .mega-menu-container ul.mega-menu-list li a, .navik-menu.submenu-list-border .mega-menu-container ul.mega-menu-list li:hover a {
    padding: 6px 0 !important;
    color: #818181;
  }
  .navik-menu .mega-menu-container ul.mega-menu-list li a:hover, .navik-menu.submenu-list-border .mega-menu-container ul.mega-menu-list li a:hover {
    padding-left: 20px !important;
  }
  .header-dark .navik-menu .mega-menu-container ul.mega-menu-list li a {
    color: #a1a1a1;
  }
  .header-dark .navik-menu .mega-menu-container ul.mega-menu-list li a:hover {
    color: #fff;
  }
  /********** Fixed sidebar menu **********/
  .navik-fixed-sidebar {
    transition-duration: 0s;
  }
  .navik-fixed-sidebar .logo {
    display: block;
  }
  .navik-side-content {
    width: calc(100% - 250px);
  }
  .navik-fixed-sidebar + .burger-menu, .navik-side-content > .logo, .fixedsidebar-bg-overlay {
    display: none;
  }
  .navik-sidebar-left, .navik-sidebar-left.fixed-sidebar-open ~ .navik-side-content, .navik-sidebar-right, .navik-sidebar-right.fixed-sidebar-open ~ .navik-side-content {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    transition-duration: 0s;
  }
  .navik-sidebar-left ~ .fixedsidebar-bg-overlay + .navik-side-content {
    margin-left: auto;
  }
  .navik-sidebar-right ~ .fixedsidebar-bg-overlay + .navik-side-content {
    margin-right: auto;
  }
  .navik-menu-fixed {
    padding-top: 0;
  }
}

body {
  overflow-x: hidden;
  font-size: 0.875rem;
}

dt {
  color: #202020;
}

a {
  transition: color 0.2s ease-in-out;
}

a:hover {
  text-decoration: none;
}

label {
  font-size: 0.75rem;
}

h1, .h1 {
  font-size: 2rem;
}

h2, .h2 {
  font-size: 1.75rem;
}

h3, .h3 {
  font-size: 1.5rem;
}

h4, .h4 {
  font-size: 1.25rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

@media (min-width: 768px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
  h2, .h2 {
    font-size: 2rem;
  }
  h3, .h3 {
    font-size: 1.75rem;
  }
  h4, .h4 {
    font-size: 1.5rem;
  }
  h5, .h5 {
    font-size: 1.25rem;
  }
  h6, .h6 {
    font-size: 1rem;
  }
}

.lead-sm {
  font-size: 1rem;
}

.display-1 {
  font-size: 3rem;
}

.display-2 {
  font-size: 2.75rem;
}

.display-3 {
  font-size: 2.25rem;
}

.display-4 {
  font-size: 1.75rem;
}

.display-ultra-1 {
  font-size: 4.875rem;
}

.display-ultra-2 {
  font-size: 4.375rem;
}

.display-ultra-3 {
  font-size: 3.875rem;
}

.display-ultra-4 {
  font-size: 3.375rem;
}

@media (min-width: 768px) {
  .display-1 {
    font-size: 4.5rem;
  }
  .display-2 {
    font-size: 4.125rem;
  }
  .display-3 {
    font-size: 3.375rem;
  }
  .display-4 {
    font-size: 2.625rem;
  }
  .display-ultra-1 {
    font-size: 7.3125rem;
  }
  .display-ultra-2 {
    font-size: 6.5625rem;
  }
  .display-ultra-3 {
    font-size: 5.8125rem;
  }
  .display-ultra-4 {
    font-size: 5.0625rem;
  }
}

@media (min-width: 1280px) {
  .display-1 {
    font-size: 6rem;
  }
  .display-2 {
    font-size: 5.5rem;
  }
  .display-3 {
    font-size: 4.5rem;
  }
  .display-4 {
    font-size: 3.5rem;
  }
  .display-ultra-1 {
    font-size: 9.75rem;
  }
  .display-ultra-2 {
    font-size: 8.75rem;
  }
  .display-ultra-3 {
    font-size: 7.75rem;
  }
  .display-ultra-4 {
    font-size: 6.75rem;
  }
}

.blockquote, .blockquote-2, .blockquote-3 {
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 500;
  line-height: 1.6;
  color: #202020;
}

.blockquote-footer {
  margin-top: 1rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
}

.blockquote-footer::before {
  position: relative;
  top: -4px;
  display: inline-block;
  width: 12px;
  height: 1px;
  margin-right: 0.625rem;
  content: "";
  background-color: #b9b9b9;
}

.blockquote-light-1 {
  color: #fff;
}

.blockquote-light-1 .blockquote-footer {
  color: rgba(255, 255, 255, 0.5);
}

.blockquote-light-1 .blockquote-footer::before {
  background-color: rgba(255, 255, 255, 0.5);
}

.blockquote-light-2 {
  color: #fff;
}

.blockquote-light-2 .blockquote-footer {
  color: rgba(255, 255, 255, 0.75);
}

.blockquote-light-2 .blockquote-footer::before {
  background-color: rgba(255, 255, 255, 0.75);
}

.blockquote-light-2.blockquote-3 {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #fff !important;
}

.blockquote-2 {
  padding: 0.1875rem 1.875rem 0.375rem;
  /* stylelint-disable declaration-no-important */
  border-color: rgba(0, 0, 0, 0.07) !important;
  border-width: 5px !important;
  /* stylelint-enable */
}

.blockquote-2.blockquote-light-1 {
  border-color: rgba(255, 255, 255, 0.16) !important;
}

.blockquote-2.blockquote-light-2 {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.blockquote-3 {
  padding: 2.5rem;
  background-color: #fff;
  /* stylelint-disable */
  border-color: #2196f3 !important;
  border-width: 5px !important;
  /* stylelint-enable */
}

.blockquote-3.blockquote-light-1, .blockquote-3.blockquote-light-2 {
  background-color: #111111;
}

.blockquote-3.blockquote-light-1 .blockquote-footer, .blockquote-3.blockquote-light-2 .blockquote-footer {
  color: rgba(255, 255, 255, 0.5);
}

.blockquote-3.blockquote-light-1 .blockquote-footer::before, .blockquote-3.blockquote-light-2 .blockquote-footer::before {
  background-color: rgba(255, 255, 255, 0.5);
}

.table {
  margin-bottom: 2rem;
  border-radius: 0.1875rem;
}

.table .thead-dark th {
  background-color: #1c2833;
}

.table .thead-light th {
  color: #1c2833;
  background-color: rgba(0, 0, 0, 0.08);
}

thead th {
  color: #fff;
  background-color: #2196f3;
  /* stylelint-disable-next-line declaration-no-important */
  border: none !important;
}

thead th:first-child {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

thead th:last-child {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}

.table-dark thead th:first-child, .table-striped thead th:first-child {
  border-bottom-left-radius: 0;
}

.table-dark thead th:last-child, .table-striped thead th:last-child {
  border-bottom-right-radius: 0;
}

/* stylelint-disable */
tbody tr:first-child th,
tbody tr:first-child td {
  border: none;
}

tbody tr th, tbody tr th.table-light {
  color: #202020;
}

tbody tr td.table-light {
  color: #717171;
}

tbody tr.table-light > th {
  color: #202020;
}

tbody tr.table-light > td {
  color: #717171;
}

.table-bordered {
  border-color: #2196f3;
  border-radius: 0;
}

.table-bordered tbody tr:first-child th,
.table-bordered tbody tr:first-child td {
  border: 1px solid #e0e0e0;
}

.table-bordered thead th {
  border-radius: 0;
}

/* stylelint-enable */
.table-striped th,
.table-striped td {
  border: none;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  color: #fff;
  background-color: #2196f3;
}

.table-hover .table-primary:hover,
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #0e8df2;
}

.table .bg-primary > th {
  color: #fff;
}

.table .bg-primary > td {
  color: #fff;
}

.table.table-border-primary th,
.table.table-border-primary td {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #6ab8f7 !important;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  color: #fff;
  background-color: #4db6ac;
}

.table-hover .table-secondary:hover,
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #45a9a0;
}

.table .bg-secondary > th {
  color: #fff;
}

.table .bg-secondary > td {
  color: #fff;
}

.table.table-border-secondary th,
.table.table-border-secondary td {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #83ccc5 !important;
}

.table-success,
.table-success > th,
.table-success > td {
  color: #fff;
  background-color: #66bb6a;
}

.table-hover .table-success:hover,
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #58b55c;
}

.table .bg-success > th {
  color: #fff;
}

.table .bg-success > td {
  color: #fff;
}

.table.table-border-success th,
.table.table-border-success td {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #9bd39e !important;
}

.table-info,
.table-info > th,
.table-info > td {
  color: #fff;
  background-color: #81d4fa;
}

.table-hover .table-info:hover,
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #6dcdf9;
}

.table .bg-info > th {
  color: #fff;
}

.table .bg-info > td {
  color: #fff;
}

.table.table-border-info th,
.table.table-border-info td {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #cbedfd !important;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  color: #fff;
  background-color: #fbc02d;
}

.table-hover .table-warning:hover,
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fbba19;
}

.table .bg-warning > th {
  color: #fff;
}

.table .bg-warning > td {
  color: #fff;
}

.table.table-border-warning th,
.table.table-border-warning td {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #fcd778 !important;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  color: #fff;
  background-color: #e53935;
}

.table-hover .table-danger:hover,
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #e32723;
}

.table .bg-danger > th {
  color: #fff;
}

.table .bg-danger > td {
  color: #fff;
}

.table.table-border-danger th,
.table.table-border-danger td {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #ee7b79 !important;
}

.table-light,
.table-light > th,
.table-light > td {
  color: #fff;
  background-color: #f7f9f9;
}

.table-hover .table-light:hover,
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ebf0f0;
}

.table .bg-light > th {
  color: #202020;
}

.table .bg-light > td {
  color: #717171;
}

.table.table-border-light th,
.table.table-border-light td {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: white !important;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  color: #fff;
  background-color: #1c2833;
}

.table-hover .table-dark:hover,
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #151e26;
}

.table .bg-dark > th {
  color: #fff;
}

.table .bg-dark > td {
  color: #fff;
}

.table.table-border-dark th,
.table.table-border-dark td {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #374f64 !important;
}

.table-indigo,
.table-indigo > th,
.table-indigo > td {
  color: #fff;
  background-color: #1565c0;
}

.table-hover .table-indigo:hover,
.table-hover .table-indigo:hover > td,
.table-hover .table-indigo:hover > th {
  background-color: #135bae;
}

.table .bg-indigo > th {
  color: #fff;
}

.table .bg-indigo > td {
  color: #fff;
}

.table.table-border-indigo th,
.table.table-border-indigo td {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #388be9 !important;
}

.table-purple,
.table-purple > th,
.table-purple > td {
  color: #fff;
  background-color: #9c27b0;
}

.table-hover .table-purple:hover,
.table-hover .table-purple:hover > td,
.table-hover .table-purple:hover > th {
  background-color: #8d239f;
}

.table .bg-purple > th {
  color: #fff;
}

.table .bg-purple > td {
  color: #fff;
}

.table.table-border-purple th,
.table.table-border-purple td {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #c34cd7 !important;
}

.table-pink,
.table-pink > th,
.table-pink > td {
  color: #fff;
  background-color: #f06292;
}

.table-hover .table-pink:hover,
.table-hover .table-pink:hover > td,
.table-hover .table-pink:hover > th {
  background-color: #ee4f85;
}

.table .bg-pink > th {
  color: #fff;
}

.table .bg-pink > td {
  color: #fff;
}

.table.table-border-pink th,
.table.table-border-pink td {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #f7a8c2 !important;
}

.table-orange,
.table-orange > th,
.table-orange > td {
  color: #fff;
  background-color: #fb8c00;
}

.table-hover .table-orange:hover,
.table-hover .table-orange:hover > td,
.table-hover .table-orange:hover > th {
  background-color: #e78100;
}

.table .bg-orange > th {
  color: #fff;
}

.table .bg-orange > td {
  color: #fff;
}

.table.table-border-orange th,
.table.table-border-orange td {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #ffae49 !important;
}

.table-sky,
.table-sky > th,
.table-sky > td {
  color: #fff;
  background-color: #64b5f6;
}

.table-hover .table-sky:hover,
.table-hover .table-sky:hover > td,
.table-hover .table-sky:hover > th {
  background-color: #51acf5;
}

.table .bg-sky > th {
  color: #fff;
}

.table .bg-sky > td {
  color: #fff;
}

.table.table-border-sky th,
.table.table-border-sky td {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #acd8fa !important;
}

.table-navy,
.table-navy > th,
.table-navy > td {
  color: #fff;
  background-color: #0d47a1;
}

.table-hover .table-navy:hover,
.table-hover .table-navy:hover > td,
.table-hover .table-navy:hover > th {
  background-color: #0b3f8e;
}

.table .bg-navy > th {
  color: #fff;
}

.table .bg-navy > td {
  color: #fff;
}

.table.table-border-navy th,
.table.table-border-navy td {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #1366e8 !important;
}

.table-berry,
.table-berry > th,
.table-berry > td {
  color: #fff;
  background-color: #b71c1c;
}

.table-hover .table-berry:hover,
.table-hover .table-berry:hover > td,
.table-hover .table-berry:hover > th {
  background-color: #a51919;
}

.table .bg-berry > th {
  color: #fff;
}

.table .bg-berry > td {
  color: #fff;
}

.table.table-border-berry th,
.table.table-border-berry td {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #e13e3e !important;
}

.table-rose,
.table-rose > th,
.table-rose > td {
  color: #fff;
  background-color: #e91e63;
}

.table-hover .table-rose:hover,
.table-hover .table-rose:hover > td,
.table-hover .table-rose:hover > th {
  background-color: #dd1659;
}

.table .bg-rose > th {
  color: #fff;
}

.table .bg-rose > td {
  color: #fff;
}

.table.table-border-rose th,
.table.table-border-rose td {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #f06493 !important;
}

.table-orchid,
.table-orchid > th,
.table-orchid > td {
  color: #fff;
  background-color: #ba68c8;
}

.table-hover .table-orchid:hover,
.table-hover .table-orchid:hover > td,
.table-hover .table-orchid:hover > th {
  background-color: #b359c3;
}

.table .bg-orchid > th {
  color: #fff;
}

.table .bg-orchid > td {
  color: #fff;
}

.table.table-border-orchid th,
.table.table-border-orchid td {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #d4a0dc !important;
}

.table-blush,
.table-blush > th,
.table-blush > td {
  color: #fff;
  background-color: #ef5350;
}

.table-hover .table-blush:hover,
.table-hover .table-blush:hover > td,
.table-hover .table-blush:hover > th {
  background-color: #ed413d;
}

.table .bg-blush > th {
  color: #fff;
}

.table .bg-blush > td {
  color: #fff;
}

.table.table-border-blush th,
.table.table-border-blush td {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #f59896 !important;
}

.table-scarlet,
.table-scarlet > th,
.table-scarlet > td {
  color: #fff;
  background-color: #d32f2f;
}

.table-hover .table-scarlet:hover,
.table-hover .table-scarlet:hover > td,
.table-hover .table-scarlet:hover > th {
  background-color: #c42929;
}

.table .bg-scarlet > th {
  color: #fff;
}

.table .bg-scarlet > td {
  color: #fff;
}

.table.table-border-scarlet th,
.table.table-border-scarlet td {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #e06e6e !important;
}

.table-carrot,
.table-carrot > th,
.table-carrot > td {
  color: #fff;
  background-color: #ef6c00;
}

.table-hover .table-carrot:hover,
.table-hover .table-carrot:hover > td,
.table-hover .table-carrot:hover > th {
  background-color: #db6300;
}

.table .bg-carrot > th {
  color: #fff;
}

.table .bg-carrot > td {
  color: #fff;
}

.table.table-border-carrot th,
.table.table-border-carrot td {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #ff943d !important;
}

.table-dark-gray,
.table-dark-gray > th,
.table-dark-gray > td {
  color: #fff;
  background-color: #202020;
}

.table-hover .table-dark-gray:hover,
.table-hover .table-dark-gray:hover > td,
.table-hover .table-dark-gray:hover > th {
  background-color: #161616;
}

.table .bg-dark-gray > th {
  color: #fff;
}

.table .bg-dark-gray > td {
  color: #fff;
}

.table.table-border-dark-gray th,
.table.table-border-dark-gray td {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #464646 !important;
}

.table-dark, .table-light-1 {
  color: rgba(255, 255, 255, 0.5);
}

.table-dark tbody th, .table-light-1 tbody th {
  color: #fff;
}

.table-bordered.table-dark, .table-bordered.table-light-1 {
  border: 1px solid #2196f3;
}

.table-dark th,
.table-dark td {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #2e4254 !important;
}

.table-light-1 th,
.table-light-1 td {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #343a40 !important;
}

.table-light-1 .thead-light th {
  background-color: #fff;
}

.table-light-1.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-light-1.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

.table-light-1 .table-active,
.table-light-1 .table-active > th,
.table-light-1 .table-active > td {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.05);
}

.table-light-1 .table-hover .table-active:hover,
.table-light-1 .table-hover .table-active:hover > td,
.table-light-1 .table-hover .table-active:hover > th {
  background-color: rgba(245, 245, 245, 0.05);
}

.table-light-2,
.table-light-2 tbody th {
  color: #fff;
}

.table-light-2 th,
.table-light-2 td {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: rgba(255, 255, 255, 0.15) !important;
}

.table-light-2 .thead-light th {
  background-color: #fff;
}

.table-light-2.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.1);
}

.table-light-2.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.table-light-2 .table-active,
.table-light-2 .table-active > th,
.table-light-2 .table-active > td {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.table-light-2 .table-hover .table-active:hover,
.table-light-2 .table-hover .table-active:hover > td,
.table-light-2 .table-hover .table-active:hover > th {
  background-color: rgba(245, 245, 245, 0.1);
}

/* stylelint-disable */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .table {
    border-radius: 0;
  }
  thead th:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  thead th:last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@supports (-ms-accelerator: true) {
  .table {
    border-radius: 0;
  }
  thead th:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  thead th:last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

/* stylelint-enable */
.form-control {
  min-height: 2.6875rem;
  font-size: 0.75rem;
  border-radius: 0.1875rem;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
}

.col-form-label {
  padding-top: calc(0.375rem + 2px + 5px);
  padding-bottom: calc(0.375rem + 2px + 5px);
  font-size: 0.75rem;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 2px + 7px);
  padding-bottom: calc(0.5rem + 2px + 7px);
  font-size: 0.875rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 2px + 1px);
  padding-bottom: calc(0.25rem + 2px + 1px);
  font-size: 0.6875rem;
}

.form-control-plaintext {
  min-height: 2.6875rem;
  font-size: 0.75rem;
}

.form-control-sm {
  min-height: 1.875rem;
  font-size: 0.6875rem;
}

.form-control-lg {
  min-height: 3.375rem;
  font-size: 0.875rem;
}

/* stylelint-disable */
textarea.form-control {
  min-height: 6.25rem;
}

/* stylelint-enable */
.valid-tooltip {
  left: 0;
  font-size: 80%;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #66bb6a;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #66bb6a;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.was-validated .form-control:valid:focus ~ .input-focus-bg, .form-control.is-valid:focus ~ .input-focus-bg, .was-validated
.custom-select:valid:focus ~ .input-focus-bg,
.custom-select.is-valid:focus ~ .input-focus-bg {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #66bb6a !important;
}

.was-validated .form-control:valid ~ .input-focus-bg, .form-control.is-valid ~ .input-focus-bg, .was-validated
.custom-select:valid ~ .input-focus-bg,
.custom-select.is-valid ~ .input-focus-bg {
  border-color: #66bb6a;
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  background: url("../svg/caret-down.svg") no-repeat right 0.9375rem center/11px 14px, url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#66bb6a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") no-repeat center right 2.125rem/calc(0.85em + 0.375rem) calc(0.85em + 0.375rem);
}

.was-validated.form-light-1 .custom-select:valid, .custom-select.is-valid {
  background: url("../svg/caret-down-gray.svg") no-repeat right 0.9375rem center/11px 14px, url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#66bb6a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") no-repeat center right 2.125rem/calc(0.85em + 0.375rem) calc(0.85em + 0.375rem);
}

.was-validated.form-light-2 .custom-select:valid, .custom-select.is-valid {
  background: url("../svg/caret-down-white.svg") no-repeat right 0.9375rem center/11px 14px, url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#66bb6a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") no-repeat center right 2.125rem/calc(0.85em + 0.375rem) calc(0.85em + 0.375rem);
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.form-light-2.was-validated .form-control:focus,
.form-light-2.was-validated .custom-select:focus {
  box-shadow: 0 0 8px 0 rgba(255, 255, 255, 0.2);
}

.form-light-2.was-validated .custom-file-input:focus ~ .custom-file-label {
  box-shadow: 0 0 8px 0 rgba(255, 255, 255, 0.2);
}

.invalid-tooltip {
  left: 0;
  font-size: 80%;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #e53935;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #e53935;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.was-validated .form-control:invalid:focus ~ .input-focus-bg, .form-control.is-invalid:focus ~ .input-focus-bg, .was-validated
.custom-select:invalid:focus ~ .input-focus-bg,
.custom-select.is-invalid:focus ~ .input-focus-bg {
  /* stylelint-disable-next-line declaration-no-important */
  border-color: #e53935 !important;
}

.was-validated .form-control:invalid ~ .input-focus-bg, .form-control.is-invalid ~ .input-focus-bg, .was-validated
.custom-select:invalid ~ .input-focus-bg,
.custom-select.is-invalid ~ .input-focus-bg {
  border-color: #e53935;
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  background: url("../svg/caret-down.svg") no-repeat right 0.9375rem center/11px 14px, url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#e53935' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#e53935' stroke='none'/></svg>") no-repeat center right 2.125rem/calc(0.85em + 0.375rem) calc(0.85em + 0.375rem);
}

.was-validated.form-light-1 .custom-select:invalid, .custom-select.is-invalid {
  background: url("../svg/caret-down-gray.svg") no-repeat right 0.9375rem center/11px 14px, url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#e53935' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#e53935' stroke='none'/></svg>") no-repeat center right 2.125rem/calc(0.85em + 0.375rem) calc(0.85em + 0.375rem);
}

.was-validated.form-light-2 .custom-select:invalid, .custom-select.is-invalid {
  background: url("../svg/caret-down-white.svg") no-repeat right 0.9375rem center/11px 14px, url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#e53935' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#e53935' stroke='none'/></svg>") no-repeat center right 2.125rem/calc(0.85em + 0.375rem) calc(0.85em + 0.375rem);
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.form-light-2.was-validated .form-control:focus,
.form-light-2.was-validated .custom-select:focus {
  box-shadow: 0 0 8px 0 rgba(255, 255, 255, 0.2);
}

.form-light-2.was-validated .custom-file-input:focus ~ .custom-file-label {
  box-shadow: 0 0 8px 0 rgba(255, 255, 255, 0.2);
}

.form-round, .form-round.form-control, .form-round.custom-select {
  border-radius: 1.3125rem;
}

.form-round.form-control-lg, .form-round.custom-select-lg {
  border-radius: 1.6875rem;
}

.form-round.form-control-sm, .form-round.custom-select-sm {
  border-radius: 0.9375rem;
}

.form-round.custom-file-label::after {
  border-radius: 1rem;
}

.form-round.custom-select[multiple] option {
  border-radius: 1rem;
}

.form-round.custom-select[multiple].custom-select-lg option {
  border-radius: 1.125rem;
}

.form-round.custom-select[multiple].custom-select-sm option {
  border-radius: 0.875rem;
}

.custom-file .form-round {
  border-radius: 1.3125rem;
}

.custom-file-sm .form-round {
  border-radius: 0.9375rem;
}

.custom-file-sm .form-round.custom-file-label::after {
  border-radius: 0.625rem;
}

.custom-file-lg .form-round {
  border-radius: 1.6875rem;
}

.custom-file-lg .form-round.custom-file-label::after {
  border-radius: 1.375rem;
}

.form-box {
  padding: 2.5rem;
}

.rounded-form-box {
  border-radius: 3.875rem;
}

.rounded-form-box-sm {
  border-radius: 3.4375rem;
}

.rounded-form-box-lg {
  border-radius: 4.1875rem;
}

.form-light-1 .form-control,
.form-light-1 .custom-select,
.form-light-1 .custom-file-label {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.19);
}

.form-light-1 .form-control:focus,
.form-light-1 .custom-select:focus,
.form-light-1 .custom-file-label:focus {
  border-color: #2196f3;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.form-light-1 .form-control::-webkit-input-placeholder, .form-light-1 .custom-select::-webkit-input-placeholder, .form-light-1 .custom-file-label::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.form-light-1 .form-control::-moz-placeholder, .form-light-1 .custom-select::-moz-placeholder, .form-light-1 .custom-file-label::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.form-light-1 .form-control:-ms-input-placeholder, .form-light-1 .custom-select:-ms-input-placeholder, .form-light-1 .custom-file-label:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.form-light-1 .form-control::-ms-input-placeholder, .form-light-1 .custom-select::-ms-input-placeholder, .form-light-1 .custom-file-label::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.form-light-1 .form-control::placeholder,
.form-light-1 .custom-select::placeholder,
.form-light-1 .custom-file-label::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.form-light-1 .form-control:disabled, .form-light-1 .form-control[readonly],
.form-light-1 .custom-select:disabled,
.form-light-1 .custom-select[readonly],
.form-light-1 .custom-file-label:disabled,
.form-light-1 .custom-file-label[readonly] {
  background-color: rgba(255, 255, 255, 0.1);
}

.form-light-1 .form-control-plaintext {
  color: rgba(255, 255, 255, 0.5);
}

.form-light-1 .form-check-input[disabled] ~ .form-check-label,
.form-light-1 .form-check-input:disabled ~ .form-check-label {
  color: rgba(255, 255, 255, 0.5);
}

.form-light-1 .custom-select:focus {
  color: rgba(255, 255, 255, 0.5);
}

.form-light-1 .custom-select:not([multiple]) option {
  color: #717171;
}

.form-light-1 .custom-control-input:active ~ .custom-control-label::before {
  background-color: rgba(255, 255, 255, 0.5);
}

.form-light-1 .custom-control-input:disabled ~ .custom-control-label {
  color: rgba(255, 255, 255, 0.5);
}

.form-light-1 .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: rgba(255, 255, 255, 0.25);
}

.form-light-1 .custom-control-label::before {
  background-color: rgba(255, 255, 255, 0.25);
}

.form-light-1 .custom-range::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.15);
}

.form-light-1 .custom-range::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.15);
}

.form-light-1 .custom-range::-ms-fill-lower {
  background-color: rgba(255, 255, 255, 0.15);
}

.form-light-1 .custom-range::-ms-fill-upper {
  background-color: rgba(255, 255, 255, 0.15);
}

.form-light-2 .form-control,
.form-light-2 .custom-select,
.form-light-2 .custom-file-label {
  color: rgba(255, 255, 255, 0.75);
  border-color: rgba(255, 255, 255, 0.5);
}

.form-light-2 .form-control:focus,
.form-light-2 .custom-select:focus,
.form-light-2 .custom-file-label:focus {
  border-color: #fff;
  box-shadow: 0 0 8px 0 rgba(255, 255, 255, 0.2);
}

.form-light-2 .form-control::-webkit-input-placeholder, .form-light-2 .custom-select::-webkit-input-placeholder, .form-light-2 .custom-file-label::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.75);
}

.form-light-2 .form-control::-moz-placeholder, .form-light-2 .custom-select::-moz-placeholder, .form-light-2 .custom-file-label::-moz-placeholder {
  color: rgba(255, 255, 255, 0.75);
}

.form-light-2 .form-control:-ms-input-placeholder, .form-light-2 .custom-select:-ms-input-placeholder, .form-light-2 .custom-file-label:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.75);
}

.form-light-2 .form-control::-ms-input-placeholder, .form-light-2 .custom-select::-ms-input-placeholder, .form-light-2 .custom-file-label::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.75);
}

.form-light-2 .form-control::placeholder,
.form-light-2 .custom-select::placeholder,
.form-light-2 .custom-file-label::placeholder {
  color: rgba(255, 255, 255, 0.75);
}

.form-light-2 .form-control:disabled, .form-light-2 .form-control[readonly],
.form-light-2 .custom-select:disabled,
.form-light-2 .custom-select[readonly],
.form-light-2 .custom-file-label:disabled,
.form-light-2 .custom-file-label[readonly] {
  background-color: rgba(255, 255, 255, 0.25);
}

.form-light-2 .form-control-plaintext {
  color: rgba(255, 255, 255, 0.75);
}

.form-light-2 .form-check-input[disabled] ~ .form-check-label,
.form-light-2 .form-check-input:disabled ~ .form-check-label {
  color: rgba(255, 255, 255, 0.75);
}

.form-light-2 .custom-select:focus {
  color: rgba(255, 255, 255, 0.75);
}

.form-light-2 .custom-select:not([multiple]) option {
  color: #717171;
}

.form-light-2 .custom-control-input:active ~ .custom-control-label::before {
  background-color: rgba(255, 255, 255, 0.75);
}

.form-light-2 .custom-control-input:disabled ~ .custom-control-label {
  color: rgba(255, 255, 255, 0.75);
}

.form-light-2 .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: rgba(255, 255, 255, 0.5);
}

.form-light-2 .custom-control-label::before {
  background-color: rgba(255, 255, 255, 0.5);
}

.form-light-2 .custom-range::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.5);
}

.form-light-2 .custom-range::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.5);
}

.form-light-2 .custom-range::-ms-fill-lower {
  background-color: rgba(255, 255, 255, 0.5);
}

.form-light-2 .custom-range::-ms-fill-upper {
  background-color: rgba(255, 255, 255, 0.5);
}

.form-light-2:not(.was-validated) .custom-file-input:focus ~ .custom-file-label {
  border-color: #fff;
  box-shadow: 0 0 8px 0 rgba(255, 255, 255, 0.2);
}

.btn {
  margin-bottom: 1rem;
  text-transform: uppercase;
  padding: 0.625rem 1.625rem;
  font-size: 0.75rem;
  line-height: 1.6;
  border-radius: 0.1875rem;
  transition: all 0.2s ease-in-out;
  color: #fff;
  background-color: #bdc3c7;
  box-shadow: 0 12px 35px -10px rgba(189, 195, 199, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn:hover, .btn:focus, .btn.focus {
  color: #fff;
  background-color: #d2d6d9;
  border-color: #d2d6d9;
  box-shadow: 0 1px 9px 0 rgba(189, 195, 199, 0.5);
}

.btn.disabled, .btn:disabled {
  color: #fff;
  background-color: #bdc3c7;
  border-color: #bdc3c7;
  box-shadow: 0 1px 9px 0 rgba(189, 195, 199, 0.5);
}

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled).active:focus,
.show > .btn.dropdown-toggle,
.show > .btn.dropdown-toggle:focus {
  color: #fff;
  background-color: #bdc3c7;
  border-color: transparent;
  box-shadow: 0 1px 9px 0 rgba(189, 195, 199, 0.5);
}

.btn > i {
  margin-right: 0.5rem;
  font-size: 0.875rem;
}

.btn-primary {
  color: #fff;
  background-color: #2196f3;
  box-shadow: 0 12px 35px -10px rgba(33, 150, 243, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-primary:hover, .btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #45a7f5;
  border-color: #45a7f5;
  box-shadow: 0 1px 9px 0 rgba(33, 150, 243, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
  box-shadow: 0 1px 9px 0 rgba(33, 150, 243, 0.5);
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle,
.show > .btn-primary.dropdown-toggle:focus {
  color: #fff;
  background-color: #2196f3;
  border-color: transparent;
  box-shadow: 0 1px 9px 0 rgba(33, 150, 243, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #4db6ac;
  box-shadow: 0 12px 35px -10px rgba(77, 182, 172, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #68c1b9;
  border-color: #68c1b9;
  box-shadow: 0 1px 9px 0 rgba(77, 182, 172, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #4db6ac;
  border-color: #4db6ac;
  box-shadow: 0 1px 9px 0 rgba(77, 182, 172, 0.5);
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle,
.show > .btn-secondary.dropdown-toggle:focus {
  color: #fff;
  background-color: #4db6ac;
  border-color: transparent;
  box-shadow: 0 1px 9px 0 rgba(77, 182, 172, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #66bb6a;
  box-shadow: 0 12px 35px -10px rgba(102, 187, 106, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-success:hover, .btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #80c784;
  border-color: #80c784;
  box-shadow: 0 1px 9px 0 rgba(102, 187, 106, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #66bb6a;
  border-color: #66bb6a;
  box-shadow: 0 1px 9px 0 rgba(102, 187, 106, 0.5);
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle,
.show > .btn-success.dropdown-toggle:focus {
  color: #fff;
  background-color: #66bb6a;
  border-color: transparent;
  box-shadow: 0 1px 9px 0 rgba(102, 187, 106, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #81d4fa;
  box-shadow: 0 12px 35px -10px rgba(129, 212, 250, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-info:hover, .btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #a6e1fb;
  border-color: #a6e1fb;
  box-shadow: 0 1px 9px 0 rgba(129, 212, 250, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #81d4fa;
  border-color: #81d4fa;
  box-shadow: 0 1px 9px 0 rgba(129, 212, 250, 0.5);
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle,
.show > .btn-info.dropdown-toggle:focus {
  color: #fff;
  background-color: #81d4fa;
  border-color: transparent;
  box-shadow: 0 1px 9px 0 rgba(129, 212, 250, 0.5);
}

.btn-warning {
  color: #fff;
  background-color: #fbc02d;
  box-shadow: 0 12px 35px -10px rgba(251, 192, 45, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-warning:hover, .btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #fccb53;
  border-color: #fccb53;
  box-shadow: 0 1px 9px 0 rgba(251, 192, 45, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #fff;
  background-color: #fbc02d;
  border-color: #fbc02d;
  box-shadow: 0 1px 9px 0 rgba(251, 192, 45, 0.5);
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle,
.show > .btn-warning.dropdown-toggle:focus {
  color: #fff;
  background-color: #fbc02d;
  border-color: transparent;
  box-shadow: 0 1px 9px 0 rgba(251, 192, 45, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #e53935;
  box-shadow: 0 12px 35px -10px rgba(229, 57, 53, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-danger:hover, .btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #e95a57;
  border-color: #e95a57;
  box-shadow: 0 1px 9px 0 rgba(229, 57, 53, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #e53935;
  border-color: #e53935;
  box-shadow: 0 1px 9px 0 rgba(229, 57, 53, 0.5);
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle,
.show > .btn-danger.dropdown-toggle:focus {
  color: #fff;
  background-color: #e53935;
  border-color: transparent;
  box-shadow: 0 1px 9px 0 rgba(229, 57, 53, 0.5);
}

.btn-light {
  color: #1c2833;
  background-color: #f7f9f9;
  box-shadow: 0 12px 35px -10px rgba(247, 249, 249, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 8px 25px -2px rgba(0, 0, 0, 0.12);
}

.btn-light:hover, .btn-light:focus, .btn-light.focus {
  color: #1c2833;
  background-color: white;
  border-color: white;
  box-shadow: 0 1px 9px 0 rgba(189, 205, 205, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #1c2833;
  background-color: #f7f9f9;
  border-color: #f7f9f9;
  box-shadow: 0 1px 9px 0 rgba(189, 205, 205, 0.5);
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle,
.show > .btn-light.dropdown-toggle:focus {
  color: #1c2833;
  background-color: #f7f9f9;
  border-color: transparent;
  box-shadow: 0 1px 9px 0 rgba(189, 205, 205, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #1c2833;
  box-shadow: 0 12px 35px -10px rgba(28, 40, 51, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-dark:hover, .btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #2a3b4c;
  border-color: #2a3b4c;
  box-shadow: 0 1px 9px 0 rgba(28, 40, 51, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #1c2833;
  border-color: #1c2833;
  box-shadow: 0 1px 9px 0 rgba(28, 40, 51, 0.5);
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle,
.show > .btn-dark.dropdown-toggle:focus {
  color: #fff;
  background-color: #1c2833;
  border-color: transparent;
  box-shadow: 0 1px 9px 0 rgba(28, 40, 51, 0.5);
}

.btn-indigo {
  color: #fff;
  background-color: #1565c0;
  box-shadow: 0 12px 35px -10px rgba(21, 101, 192, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-indigo:hover, .btn-indigo:focus, .btn-indigo.focus {
  color: #fff;
  background-color: #1977e2;
  border-color: #1977e2;
  box-shadow: 0 1px 9px 0 rgba(21, 101, 192, 0.5);
}

.btn-indigo.disabled, .btn-indigo:disabled {
  color: #fff;
  background-color: #1565c0;
  border-color: #1565c0;
  box-shadow: 0 1px 9px 0 rgba(21, 101, 192, 0.5);
}

.btn-indigo:not(:disabled):not(.disabled):active, .btn-indigo:not(:disabled):not(.disabled):active:focus, .btn-indigo:not(:disabled):not(.disabled).active, .btn-indigo:not(:disabled):not(.disabled).active:focus,
.show > .btn-indigo.dropdown-toggle,
.show > .btn-indigo.dropdown-toggle:focus {
  color: #fff;
  background-color: #1565c0;
  border-color: transparent;
  box-shadow: 0 1px 9px 0 rgba(21, 101, 192, 0.5);
}

.btn-purple {
  color: #fff;
  background-color: #9c27b0;
  box-shadow: 0 12px 35px -10px rgba(156, 39, 176, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-purple:hover, .btn-purple:focus, .btn-purple.focus {
  color: #fff;
  background-color: #b82ecf;
  border-color: #b82ecf;
  box-shadow: 0 1px 9px 0 rgba(156, 39, 176, 0.5);
}

.btn-purple.disabled, .btn-purple:disabled {
  color: #fff;
  background-color: #9c27b0;
  border-color: #9c27b0;
  box-shadow: 0 1px 9px 0 rgba(156, 39, 176, 0.5);
}

.btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active, .btn-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-purple.dropdown-toggle,
.show > .btn-purple.dropdown-toggle:focus {
  color: #fff;
  background-color: #9c27b0;
  border-color: transparent;
  box-shadow: 0 1px 9px 0 rgba(156, 39, 176, 0.5);
}

.btn-pink {
  color: #fff;
  background-color: #f06292;
  box-shadow: 0 12px 35px -10px rgba(240, 98, 146, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-pink:hover, .btn-pink:focus, .btn-pink.focus {
  color: #fff;
  background-color: #f385aa;
  border-color: #f385aa;
  box-shadow: 0 1px 9px 0 rgba(240, 98, 146, 0.5);
}

.btn-pink.disabled, .btn-pink:disabled {
  color: #fff;
  background-color: #f06292;
  border-color: #f06292;
  box-shadow: 0 1px 9px 0 rgba(240, 98, 146, 0.5);
}

.btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled):active:focus, .btn-pink:not(:disabled):not(.disabled).active, .btn-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-pink.dropdown-toggle,
.show > .btn-pink.dropdown-toggle:focus {
  color: #fff;
  background-color: #f06292;
  border-color: transparent;
  box-shadow: 0 1px 9px 0 rgba(240, 98, 146, 0.5);
}

.btn-orange {
  color: #fff;
  background-color: #fb8c00;
  box-shadow: 0 12px 35px -10px rgba(251, 140, 0, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-orange:hover, .btn-orange:focus, .btn-orange.focus {
  color: #fff;
  background-color: #ff9d22;
  border-color: #ff9d22;
  box-shadow: 0 1px 9px 0 rgba(251, 140, 0, 0.5);
}

.btn-orange.disabled, .btn-orange:disabled {
  color: #fff;
  background-color: #fb8c00;
  border-color: #fb8c00;
  box-shadow: 0 1px 9px 0 rgba(251, 140, 0, 0.5);
}

.btn-orange:not(:disabled):not(.disabled):active, .btn-orange:not(:disabled):not(.disabled):active:focus, .btn-orange:not(:disabled):not(.disabled).active, .btn-orange:not(:disabled):not(.disabled).active:focus,
.show > .btn-orange.dropdown-toggle,
.show > .btn-orange.dropdown-toggle:focus {
  color: #fff;
  background-color: #fb8c00;
  border-color: transparent;
  box-shadow: 0 1px 9px 0 rgba(251, 140, 0, 0.5);
}

.btn-sky {
  color: #fff;
  background-color: #64b5f6;
  box-shadow: 0 12px 35px -10px rgba(100, 181, 246, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-sky:hover, .btn-sky:focus, .btn-sky.focus {
  color: #fff;
  background-color: #88c6f8;
  border-color: #88c6f8;
  box-shadow: 0 1px 9px 0 rgba(100, 181, 246, 0.5);
}

.btn-sky.disabled, .btn-sky:disabled {
  color: #fff;
  background-color: #64b5f6;
  border-color: #64b5f6;
  box-shadow: 0 1px 9px 0 rgba(100, 181, 246, 0.5);
}

.btn-sky:not(:disabled):not(.disabled):active, .btn-sky:not(:disabled):not(.disabled):active:focus, .btn-sky:not(:disabled):not(.disabled).active, .btn-sky:not(:disabled):not(.disabled).active:focus,
.show > .btn-sky.dropdown-toggle,
.show > .btn-sky.dropdown-toggle:focus {
  color: #fff;
  background-color: #64b5f6;
  border-color: transparent;
  box-shadow: 0 1px 9px 0 rgba(100, 181, 246, 0.5);
}

.btn-navy {
  color: #fff;
  background-color: #0d47a1;
  box-shadow: 0 12px 35px -10px rgba(13, 71, 161, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-navy:hover, .btn-navy:focus, .btn-navy.focus {
  color: #fff;
  background-color: #1057c4;
  border-color: #1057c4;
  box-shadow: 0 1px 9px 0 rgba(13, 71, 161, 0.5);
}

.btn-navy.disabled, .btn-navy:disabled {
  color: #fff;
  background-color: #0d47a1;
  border-color: #0d47a1;
  box-shadow: 0 1px 9px 0 rgba(13, 71, 161, 0.5);
}

.btn-navy:not(:disabled):not(.disabled):active, .btn-navy:not(:disabled):not(.disabled):active:focus, .btn-navy:not(:disabled):not(.disabled).active, .btn-navy:not(:disabled):not(.disabled).active:focus,
.show > .btn-navy.dropdown-toggle,
.show > .btn-navy.dropdown-toggle:focus {
  color: #fff;
  background-color: #0d47a1;
  border-color: transparent;
  box-shadow: 0 1px 9px 0 rgba(13, 71, 161, 0.5);
}

.btn-berry {
  color: #fff;
  background-color: #b71c1c;
  box-shadow: 0 12px 35px -10px rgba(183, 28, 28, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-berry:hover, .btn-berry:focus, .btn-berry.focus {
  color: #fff;
  background-color: #d82121;
  border-color: #d82121;
  box-shadow: 0 1px 9px 0 rgba(183, 28, 28, 0.5);
}

.btn-berry.disabled, .btn-berry:disabled {
  color: #fff;
  background-color: #b71c1c;
  border-color: #b71c1c;
  box-shadow: 0 1px 9px 0 rgba(183, 28, 28, 0.5);
}

.btn-berry:not(:disabled):not(.disabled):active, .btn-berry:not(:disabled):not(.disabled):active:focus, .btn-berry:not(:disabled):not(.disabled).active, .btn-berry:not(:disabled):not(.disabled).active:focus,
.show > .btn-berry.dropdown-toggle,
.show > .btn-berry.dropdown-toggle:focus {
  color: #fff;
  background-color: #b71c1c;
  border-color: transparent;
  box-shadow: 0 1px 9px 0 rgba(183, 28, 28, 0.5);
}

.btn-rose {
  color: #fff;
  background-color: #e91e63;
  box-shadow: 0 12px 35px -10px rgba(233, 30, 99, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-rose:hover, .btn-rose:focus, .btn-rose.focus {
  color: #fff;
  background-color: #ec417b;
  border-color: #ec417b;
  box-shadow: 0 1px 9px 0 rgba(233, 30, 99, 0.5);
}

.btn-rose.disabled, .btn-rose:disabled {
  color: #fff;
  background-color: #e91e63;
  border-color: #e91e63;
  box-shadow: 0 1px 9px 0 rgba(233, 30, 99, 0.5);
}

.btn-rose:not(:disabled):not(.disabled):active, .btn-rose:not(:disabled):not(.disabled):active:focus, .btn-rose:not(:disabled):not(.disabled).active, .btn-rose:not(:disabled):not(.disabled).active:focus,
.show > .btn-rose.dropdown-toggle,
.show > .btn-rose.dropdown-toggle:focus {
  color: #fff;
  background-color: #e91e63;
  border-color: transparent;
  box-shadow: 0 1px 9px 0 rgba(233, 30, 99, 0.5);
}

.btn-orchid {
  color: #fff;
  background-color: #ba68c8;
  box-shadow: 0 12px 35px -10px rgba(186, 104, 200, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-orchid:hover, .btn-orchid:focus, .btn-orchid.focus {
  color: #fff;
  background-color: #c784d2;
  border-color: #c784d2;
  box-shadow: 0 1px 9px 0 rgba(186, 104, 200, 0.5);
}

.btn-orchid.disabled, .btn-orchid:disabled {
  color: #fff;
  background-color: #ba68c8;
  border-color: #ba68c8;
  box-shadow: 0 1px 9px 0 rgba(186, 104, 200, 0.5);
}

.btn-orchid:not(:disabled):not(.disabled):active, .btn-orchid:not(:disabled):not(.disabled):active:focus, .btn-orchid:not(:disabled):not(.disabled).active, .btn-orchid:not(:disabled):not(.disabled).active:focus,
.show > .btn-orchid.dropdown-toggle,
.show > .btn-orchid.dropdown-toggle:focus {
  color: #fff;
  background-color: #ba68c8;
  border-color: transparent;
  box-shadow: 0 1px 9px 0 rgba(186, 104, 200, 0.5);
}

.btn-blush {
  color: #fff;
  background-color: #ef5350;
  box-shadow: 0 12px 35px -10px rgba(239, 83, 80, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-blush:hover, .btn-blush:focus, .btn-blush.focus {
  color: #fff;
  background-color: #f27573;
  border-color: #f27573;
  box-shadow: 0 1px 9px 0 rgba(239, 83, 80, 0.5);
}

.btn-blush.disabled, .btn-blush:disabled {
  color: #fff;
  background-color: #ef5350;
  border-color: #ef5350;
  box-shadow: 0 1px 9px 0 rgba(239, 83, 80, 0.5);
}

.btn-blush:not(:disabled):not(.disabled):active, .btn-blush:not(:disabled):not(.disabled):active:focus, .btn-blush:not(:disabled):not(.disabled).active, .btn-blush:not(:disabled):not(.disabled).active:focus,
.show > .btn-blush.dropdown-toggle,
.show > .btn-blush.dropdown-toggle:focus {
  color: #fff;
  background-color: #ef5350;
  border-color: transparent;
  box-shadow: 0 1px 9px 0 rgba(239, 83, 80, 0.5);
}

.btn-scarlet {
  color: #fff;
  background-color: #d32f2f;
  box-shadow: 0 12px 35px -10px rgba(211, 47, 47, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-scarlet:hover, .btn-scarlet:focus, .btn-scarlet.focus {
  color: #fff;
  background-color: #da4f4f;
  border-color: #da4f4f;
  box-shadow: 0 1px 9px 0 rgba(211, 47, 47, 0.5);
}

.btn-scarlet.disabled, .btn-scarlet:disabled {
  color: #fff;
  background-color: #d32f2f;
  border-color: #d32f2f;
  box-shadow: 0 1px 9px 0 rgba(211, 47, 47, 0.5);
}

.btn-scarlet:not(:disabled):not(.disabled):active, .btn-scarlet:not(:disabled):not(.disabled):active:focus, .btn-scarlet:not(:disabled):not(.disabled).active, .btn-scarlet:not(:disabled):not(.disabled).active:focus,
.show > .btn-scarlet.dropdown-toggle,
.show > .btn-scarlet.dropdown-toggle:focus {
  color: #fff;
  background-color: #d32f2f;
  border-color: transparent;
  box-shadow: 0 1px 9px 0 rgba(211, 47, 47, 0.5);
}

.btn-carrot {
  color: #fff;
  background-color: #ef6c00;
  box-shadow: 0 12px 35px -10px rgba(239, 108, 0, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-carrot:hover, .btn-carrot:focus, .btn-carrot.focus {
  color: #fff;
  background-color: #ff7f16;
  border-color: #ff7f16;
  box-shadow: 0 1px 9px 0 rgba(239, 108, 0, 0.5);
}

.btn-carrot.disabled, .btn-carrot:disabled {
  color: #fff;
  background-color: #ef6c00;
  border-color: #ef6c00;
  box-shadow: 0 1px 9px 0 rgba(239, 108, 0, 0.5);
}

.btn-carrot:not(:disabled):not(.disabled):active, .btn-carrot:not(:disabled):not(.disabled):active:focus, .btn-carrot:not(:disabled):not(.disabled).active, .btn-carrot:not(:disabled):not(.disabled).active:focus,
.show > .btn-carrot.dropdown-toggle,
.show > .btn-carrot.dropdown-toggle:focus {
  color: #fff;
  background-color: #ef6c00;
  border-color: transparent;
  box-shadow: 0 1px 9px 0 rgba(239, 108, 0, 0.5);
}

.btn-dark-gray {
  color: #fff;
  background-color: #202020;
  box-shadow: 0 12px 35px -10px rgba(32, 32, 32, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-dark-gray:hover, .btn-dark-gray:focus, .btn-dark-gray.focus {
  color: #fff;
  background-color: #333333;
  border-color: #333333;
  box-shadow: 0 1px 9px 0 rgba(32, 32, 32, 0.5);
}

.btn-dark-gray.disabled, .btn-dark-gray:disabled {
  color: #fff;
  background-color: #202020;
  border-color: #202020;
  box-shadow: 0 1px 9px 0 rgba(32, 32, 32, 0.5);
}

.btn-dark-gray:not(:disabled):not(.disabled):active, .btn-dark-gray:not(:disabled):not(.disabled):active:focus, .btn-dark-gray:not(:disabled):not(.disabled).active, .btn-dark-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark-gray.dropdown-toggle,
.show > .btn-dark-gray.dropdown-toggle:focus {
  color: #fff;
  background-color: #202020;
  border-color: transparent;
  box-shadow: 0 1px 9px 0 rgba(32, 32, 32, 0.5);
}

.btn-outline-primary {
  color: #2196f3;
  background-color: transparent;
  background-image: none;
  border-color: #2196f3;
  box-shadow: none;
}

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary.focus {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
  box-shadow: 0 1px 9px 0 rgba(33, 150, 243, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #2196f3;
  background-color: transparent;
  border-color: #2196f3;
  box-shadow: none;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle,
.show > .btn-outline-primary.dropdown-toggle:focus {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
  box-shadow: 0 1px 9px 0 rgba(33, 150, 243, 0.5);
}

.btn-outline-secondary {
  color: #4db6ac;
  background-color: transparent;
  background-image: none;
  border-color: #4db6ac;
  box-shadow: none;
}

.btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary.focus {
  color: #fff;
  background-color: #4db6ac;
  border-color: #4db6ac;
  box-shadow: 0 1px 9px 0 rgba(77, 182, 172, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #4db6ac;
  background-color: transparent;
  border-color: #4db6ac;
  box-shadow: none;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  color: #fff;
  background-color: #4db6ac;
  border-color: #4db6ac;
  box-shadow: 0 1px 9px 0 rgba(77, 182, 172, 0.5);
}

.btn-outline-success {
  color: #66bb6a;
  background-color: transparent;
  background-image: none;
  border-color: #66bb6a;
  box-shadow: none;
}

.btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success.focus {
  color: #fff;
  background-color: #66bb6a;
  border-color: #66bb6a;
  box-shadow: 0 1px 9px 0 rgba(102, 187, 106, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #66bb6a;
  background-color: transparent;
  border-color: #66bb6a;
  box-shadow: none;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle,
.show > .btn-outline-success.dropdown-toggle:focus {
  color: #fff;
  background-color: #66bb6a;
  border-color: #66bb6a;
  box-shadow: 0 1px 9px 0 rgba(102, 187, 106, 0.5);
}

.btn-outline-info {
  color: #81d4fa;
  background-color: transparent;
  background-image: none;
  border-color: #81d4fa;
  box-shadow: none;
}

.btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info.focus {
  color: #fff;
  background-color: #81d4fa;
  border-color: #81d4fa;
  box-shadow: 0 1px 9px 0 rgba(129, 212, 250, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #81d4fa;
  background-color: transparent;
  border-color: #81d4fa;
  box-shadow: none;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle,
.show > .btn-outline-info.dropdown-toggle:focus {
  color: #fff;
  background-color: #81d4fa;
  border-color: #81d4fa;
  box-shadow: 0 1px 9px 0 rgba(129, 212, 250, 0.5);
}

.btn-outline-warning {
  color: #fbc02d;
  background-color: transparent;
  background-image: none;
  border-color: #fbc02d;
  box-shadow: none;
}

.btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning.focus {
  color: #fff;
  background-color: #fbc02d;
  border-color: #fbc02d;
  box-shadow: 0 1px 9px 0 rgba(251, 192, 45, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #fbc02d;
  background-color: transparent;
  border-color: #fbc02d;
  box-shadow: none;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle,
.show > .btn-outline-warning.dropdown-toggle:focus {
  color: #fff;
  background-color: #fbc02d;
  border-color: #fbc02d;
  box-shadow: 0 1px 9px 0 rgba(251, 192, 45, 0.5);
}

.btn-outline-danger {
  color: #e53935;
  background-color: transparent;
  background-image: none;
  border-color: #e53935;
  box-shadow: none;
}

.btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger.focus {
  color: #fff;
  background-color: #e53935;
  border-color: #e53935;
  box-shadow: 0 1px 9px 0 rgba(229, 57, 53, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #e53935;
  background-color: transparent;
  border-color: #e53935;
  box-shadow: none;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle,
.show > .btn-outline-danger.dropdown-toggle:focus {
  color: #fff;
  background-color: #e53935;
  border-color: #e53935;
  box-shadow: 0 1px 9px 0 rgba(229, 57, 53, 0.5);
}

.btn-outline-light {
  color: #f7f9f9;
  background-color: transparent;
  background-image: none;
  border-color: #f7f9f9;
  box-shadow: none;
}

.btn-outline-light:hover, .btn-outline-light:focus, .btn-outline-light.focus {
  color: #2c3e50;
  background-color: #f7f9f9;
  border-color: #f7f9f9;
  box-shadow: 0 1px 9px 0 rgba(189, 205, 205, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f7f9f9;
  background-color: transparent;
  border-color: #f7f9f9;
  box-shadow: none;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle,
.show > .btn-outline-light.dropdown-toggle:focus {
  color: #2c3e50;
  background-color: #f7f9f9;
  border-color: #f7f9f9;
  box-shadow: 0 1px 9px 0 rgba(189, 205, 205, 0.5);
}

.btn-outline-dark {
  color: #1c2833;
  background-color: transparent;
  background-image: none;
  border-color: #1c2833;
  box-shadow: none;
}

.btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark.focus {
  color: #fff;
  background-color: #1c2833;
  border-color: #1c2833;
  box-shadow: 0 1px 9px 0 rgba(28, 40, 51, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #1c2833;
  background-color: transparent;
  border-color: #1c2833;
  box-shadow: none;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle,
.show > .btn-outline-dark.dropdown-toggle:focus {
  color: #fff;
  background-color: #1c2833;
  border-color: #1c2833;
  box-shadow: 0 1px 9px 0 rgba(28, 40, 51, 0.5);
}

.btn-outline-indigo {
  color: #1565c0;
  background-color: transparent;
  background-image: none;
  border-color: #1565c0;
  box-shadow: none;
}

.btn-outline-indigo:hover, .btn-outline-indigo:focus, .btn-outline-indigo.focus {
  color: #fff;
  background-color: #1565c0;
  border-color: #1565c0;
  box-shadow: 0 1px 9px 0 rgba(21, 101, 192, 0.5);
}

.btn-outline-indigo.disabled, .btn-outline-indigo:disabled {
  color: #1565c0;
  background-color: transparent;
  border-color: #1565c0;
  box-shadow: none;
}

.btn-outline-indigo:not(:disabled):not(.disabled):active, .btn-outline-indigo:not(:disabled):not(.disabled):active:focus, .btn-outline-indigo:not(:disabled):not(.disabled).active, .btn-outline-indigo:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-indigo.dropdown-toggle,
.show > .btn-outline-indigo.dropdown-toggle:focus {
  color: #fff;
  background-color: #1565c0;
  border-color: #1565c0;
  box-shadow: 0 1px 9px 0 rgba(21, 101, 192, 0.5);
}

.btn-outline-purple {
  color: #9c27b0;
  background-color: transparent;
  background-image: none;
  border-color: #9c27b0;
  box-shadow: none;
}

.btn-outline-purple:hover, .btn-outline-purple:focus, .btn-outline-purple.focus {
  color: #fff;
  background-color: #9c27b0;
  border-color: #9c27b0;
  box-shadow: 0 1px 9px 0 rgba(156, 39, 176, 0.5);
}

.btn-outline-purple.disabled, .btn-outline-purple:disabled {
  color: #9c27b0;
  background-color: transparent;
  border-color: #9c27b0;
  box-shadow: none;
}

.btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active, .btn-outline-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-purple.dropdown-toggle,
.show > .btn-outline-purple.dropdown-toggle:focus {
  color: #fff;
  background-color: #9c27b0;
  border-color: #9c27b0;
  box-shadow: 0 1px 9px 0 rgba(156, 39, 176, 0.5);
}

.btn-outline-pink {
  color: #f06292;
  background-color: transparent;
  background-image: none;
  border-color: #f06292;
  box-shadow: none;
}

.btn-outline-pink:hover, .btn-outline-pink:focus, .btn-outline-pink.focus {
  color: #fff;
  background-color: #f06292;
  border-color: #f06292;
  box-shadow: 0 1px 9px 0 rgba(240, 98, 146, 0.5);
}

.btn-outline-pink.disabled, .btn-outline-pink:disabled {
  color: #f06292;
  background-color: transparent;
  border-color: #f06292;
  box-shadow: none;
}

.btn-outline-pink:not(:disabled):not(.disabled):active, .btn-outline-pink:not(:disabled):not(.disabled):active:focus, .btn-outline-pink:not(:disabled):not(.disabled).active, .btn-outline-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-pink.dropdown-toggle,
.show > .btn-outline-pink.dropdown-toggle:focus {
  color: #fff;
  background-color: #f06292;
  border-color: #f06292;
  box-shadow: 0 1px 9px 0 rgba(240, 98, 146, 0.5);
}

.btn-outline-orange {
  color: #fb8c00;
  background-color: transparent;
  background-image: none;
  border-color: #fb8c00;
  box-shadow: none;
}

.btn-outline-orange:hover, .btn-outline-orange:focus, .btn-outline-orange.focus {
  color: #fff;
  background-color: #fb8c00;
  border-color: #fb8c00;
  box-shadow: 0 1px 9px 0 rgba(251, 140, 0, 0.5);
}

.btn-outline-orange.disabled, .btn-outline-orange:disabled {
  color: #fb8c00;
  background-color: transparent;
  border-color: #fb8c00;
  box-shadow: none;
}

.btn-outline-orange:not(:disabled):not(.disabled):active, .btn-outline-orange:not(:disabled):not(.disabled):active:focus, .btn-outline-orange:not(:disabled):not(.disabled).active, .btn-outline-orange:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-orange.dropdown-toggle,
.show > .btn-outline-orange.dropdown-toggle:focus {
  color: #fff;
  background-color: #fb8c00;
  border-color: #fb8c00;
  box-shadow: 0 1px 9px 0 rgba(251, 140, 0, 0.5);
}

.btn-outline-sky {
  color: #64b5f6;
  background-color: transparent;
  background-image: none;
  border-color: #64b5f6;
  box-shadow: none;
}

.btn-outline-sky:hover, .btn-outline-sky:focus, .btn-outline-sky.focus {
  color: #fff;
  background-color: #64b5f6;
  border-color: #64b5f6;
  box-shadow: 0 1px 9px 0 rgba(100, 181, 246, 0.5);
}

.btn-outline-sky.disabled, .btn-outline-sky:disabled {
  color: #64b5f6;
  background-color: transparent;
  border-color: #64b5f6;
  box-shadow: none;
}

.btn-outline-sky:not(:disabled):not(.disabled):active, .btn-outline-sky:not(:disabled):not(.disabled):active:focus, .btn-outline-sky:not(:disabled):not(.disabled).active, .btn-outline-sky:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-sky.dropdown-toggle,
.show > .btn-outline-sky.dropdown-toggle:focus {
  color: #fff;
  background-color: #64b5f6;
  border-color: #64b5f6;
  box-shadow: 0 1px 9px 0 rgba(100, 181, 246, 0.5);
}

.btn-outline-navy {
  color: #0d47a1;
  background-color: transparent;
  background-image: none;
  border-color: #0d47a1;
  box-shadow: none;
}

.btn-outline-navy:hover, .btn-outline-navy:focus, .btn-outline-navy.focus {
  color: #fff;
  background-color: #0d47a1;
  border-color: #0d47a1;
  box-shadow: 0 1px 9px 0 rgba(13, 71, 161, 0.5);
}

.btn-outline-navy.disabled, .btn-outline-navy:disabled {
  color: #0d47a1;
  background-color: transparent;
  border-color: #0d47a1;
  box-shadow: none;
}

.btn-outline-navy:not(:disabled):not(.disabled):active, .btn-outline-navy:not(:disabled):not(.disabled):active:focus, .btn-outline-navy:not(:disabled):not(.disabled).active, .btn-outline-navy:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-navy.dropdown-toggle,
.show > .btn-outline-navy.dropdown-toggle:focus {
  color: #fff;
  background-color: #0d47a1;
  border-color: #0d47a1;
  box-shadow: 0 1px 9px 0 rgba(13, 71, 161, 0.5);
}

.btn-outline-berry {
  color: #b71c1c;
  background-color: transparent;
  background-image: none;
  border-color: #b71c1c;
  box-shadow: none;
}

.btn-outline-berry:hover, .btn-outline-berry:focus, .btn-outline-berry.focus {
  color: #fff;
  background-color: #b71c1c;
  border-color: #b71c1c;
  box-shadow: 0 1px 9px 0 rgba(183, 28, 28, 0.5);
}

.btn-outline-berry.disabled, .btn-outline-berry:disabled {
  color: #b71c1c;
  background-color: transparent;
  border-color: #b71c1c;
  box-shadow: none;
}

.btn-outline-berry:not(:disabled):not(.disabled):active, .btn-outline-berry:not(:disabled):not(.disabled):active:focus, .btn-outline-berry:not(:disabled):not(.disabled).active, .btn-outline-berry:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-berry.dropdown-toggle,
.show > .btn-outline-berry.dropdown-toggle:focus {
  color: #fff;
  background-color: #b71c1c;
  border-color: #b71c1c;
  box-shadow: 0 1px 9px 0 rgba(183, 28, 28, 0.5);
}

.btn-outline-rose {
  color: #e91e63;
  background-color: transparent;
  background-image: none;
  border-color: #e91e63;
  box-shadow: none;
}

.btn-outline-rose:hover, .btn-outline-rose:focus, .btn-outline-rose.focus {
  color: #fff;
  background-color: #e91e63;
  border-color: #e91e63;
  box-shadow: 0 1px 9px 0 rgba(233, 30, 99, 0.5);
}

.btn-outline-rose.disabled, .btn-outline-rose:disabled {
  color: #e91e63;
  background-color: transparent;
  border-color: #e91e63;
  box-shadow: none;
}

.btn-outline-rose:not(:disabled):not(.disabled):active, .btn-outline-rose:not(:disabled):not(.disabled):active:focus, .btn-outline-rose:not(:disabled):not(.disabled).active, .btn-outline-rose:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-rose.dropdown-toggle,
.show > .btn-outline-rose.dropdown-toggle:focus {
  color: #fff;
  background-color: #e91e63;
  border-color: #e91e63;
  box-shadow: 0 1px 9px 0 rgba(233, 30, 99, 0.5);
}

.btn-outline-orchid {
  color: #ba68c8;
  background-color: transparent;
  background-image: none;
  border-color: #ba68c8;
  box-shadow: none;
}

.btn-outline-orchid:hover, .btn-outline-orchid:focus, .btn-outline-orchid.focus {
  color: #fff;
  background-color: #ba68c8;
  border-color: #ba68c8;
  box-shadow: 0 1px 9px 0 rgba(186, 104, 200, 0.5);
}

.btn-outline-orchid.disabled, .btn-outline-orchid:disabled {
  color: #ba68c8;
  background-color: transparent;
  border-color: #ba68c8;
  box-shadow: none;
}

.btn-outline-orchid:not(:disabled):not(.disabled):active, .btn-outline-orchid:not(:disabled):not(.disabled):active:focus, .btn-outline-orchid:not(:disabled):not(.disabled).active, .btn-outline-orchid:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-orchid.dropdown-toggle,
.show > .btn-outline-orchid.dropdown-toggle:focus {
  color: #fff;
  background-color: #ba68c8;
  border-color: #ba68c8;
  box-shadow: 0 1px 9px 0 rgba(186, 104, 200, 0.5);
}

.btn-outline-blush {
  color: #ef5350;
  background-color: transparent;
  background-image: none;
  border-color: #ef5350;
  box-shadow: none;
}

.btn-outline-blush:hover, .btn-outline-blush:focus, .btn-outline-blush.focus {
  color: #fff;
  background-color: #ef5350;
  border-color: #ef5350;
  box-shadow: 0 1px 9px 0 rgba(239, 83, 80, 0.5);
}

.btn-outline-blush.disabled, .btn-outline-blush:disabled {
  color: #ef5350;
  background-color: transparent;
  border-color: #ef5350;
  box-shadow: none;
}

.btn-outline-blush:not(:disabled):not(.disabled):active, .btn-outline-blush:not(:disabled):not(.disabled):active:focus, .btn-outline-blush:not(:disabled):not(.disabled).active, .btn-outline-blush:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-blush.dropdown-toggle,
.show > .btn-outline-blush.dropdown-toggle:focus {
  color: #fff;
  background-color: #ef5350;
  border-color: #ef5350;
  box-shadow: 0 1px 9px 0 rgba(239, 83, 80, 0.5);
}

.btn-outline-scarlet {
  color: #d32f2f;
  background-color: transparent;
  background-image: none;
  border-color: #d32f2f;
  box-shadow: none;
}

.btn-outline-scarlet:hover, .btn-outline-scarlet:focus, .btn-outline-scarlet.focus {
  color: #fff;
  background-color: #d32f2f;
  border-color: #d32f2f;
  box-shadow: 0 1px 9px 0 rgba(211, 47, 47, 0.5);
}

.btn-outline-scarlet.disabled, .btn-outline-scarlet:disabled {
  color: #d32f2f;
  background-color: transparent;
  border-color: #d32f2f;
  box-shadow: none;
}

.btn-outline-scarlet:not(:disabled):not(.disabled):active, .btn-outline-scarlet:not(:disabled):not(.disabled):active:focus, .btn-outline-scarlet:not(:disabled):not(.disabled).active, .btn-outline-scarlet:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-scarlet.dropdown-toggle,
.show > .btn-outline-scarlet.dropdown-toggle:focus {
  color: #fff;
  background-color: #d32f2f;
  border-color: #d32f2f;
  box-shadow: 0 1px 9px 0 rgba(211, 47, 47, 0.5);
}

.btn-outline-carrot {
  color: #ef6c00;
  background-color: transparent;
  background-image: none;
  border-color: #ef6c00;
  box-shadow: none;
}

.btn-outline-carrot:hover, .btn-outline-carrot:focus, .btn-outline-carrot.focus {
  color: #fff;
  background-color: #ef6c00;
  border-color: #ef6c00;
  box-shadow: 0 1px 9px 0 rgba(239, 108, 0, 0.5);
}

.btn-outline-carrot.disabled, .btn-outline-carrot:disabled {
  color: #ef6c00;
  background-color: transparent;
  border-color: #ef6c00;
  box-shadow: none;
}

.btn-outline-carrot:not(:disabled):not(.disabled):active, .btn-outline-carrot:not(:disabled):not(.disabled):active:focus, .btn-outline-carrot:not(:disabled):not(.disabled).active, .btn-outline-carrot:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-carrot.dropdown-toggle,
.show > .btn-outline-carrot.dropdown-toggle:focus {
  color: #fff;
  background-color: #ef6c00;
  border-color: #ef6c00;
  box-shadow: 0 1px 9px 0 rgba(239, 108, 0, 0.5);
}

.btn-outline-dark-gray {
  color: #202020;
  background-color: transparent;
  background-image: none;
  border-color: #202020;
  box-shadow: none;
}

.btn-outline-dark-gray:hover, .btn-outline-dark-gray:focus, .btn-outline-dark-gray.focus {
  color: #fff;
  background-color: #202020;
  border-color: #202020;
  box-shadow: 0 1px 9px 0 rgba(32, 32, 32, 0.5);
}

.btn-outline-dark-gray.disabled, .btn-outline-dark-gray:disabled {
  color: #202020;
  background-color: transparent;
  border-color: #202020;
  box-shadow: none;
}

.btn-outline-dark-gray:not(:disabled):not(.disabled):active, .btn-outline-dark-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-gray:not(:disabled):not(.disabled).active, .btn-outline-dark-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark-gray.dropdown-toggle,
.show > .btn-outline-dark-gray.dropdown-toggle:focus {
  color: #fff;
  background-color: #202020;
  border-color: #202020;
  box-shadow: 0 1px 9px 0 rgba(32, 32, 32, 0.5);
}

.btn-primary.btn-gray-shadow, .btn-secondary.btn-gray-shadow, .btn-success.btn-gray-shadow, .btn-info.btn-gray-shadow, .btn-warning.btn-gray-shadow, .btn-danger.btn-gray-shadow, .btn-dark.btn-gray-shadow, .btn-indigo.btn-gray-shadow, .btn-purple.btn-gray-shadow, .btn-pink.btn-gray-shadow, .btn-orange.btn-gray-shadow, .btn-sky.btn-gray-shadow, .btn-navy.btn-gray-shadow, .btn-berry.btn-gray-shadow, .btn-rose.btn-gray-shadow, .btn-orchid.btn-gray-shadow, .btn-blush.btn-gray-shadow, .btn-scarlet.btn-gray-shadow, .btn-carrot.btn-gray-shadow, .btn-dark-gray.btn-gray-shadow, .btn-group-primary.btn-gray-shadow, .btn-group-secondary.btn-gray-shadow, .btn-group-success.btn-gray-shadow, .btn-group-info.btn-gray-shadow, .btn-group-warning.btn-gray-shadow, .btn-group-danger.btn-gray-shadow, .btn-group-dark.btn-gray-shadow, .btn-group-indigo.btn-gray-shadow, .btn-group-purple.btn-gray-shadow, .btn-group-pink.btn-gray-shadow, .btn-group-orange.btn-gray-shadow, .btn-group-sky.btn-gray-shadow, .btn-group-navy.btn-gray-shadow, .btn-group-berry.btn-gray-shadow, .btn-group-rose.btn-gray-shadow, .btn-group-orchid.btn-gray-shadow, .btn-group-blush.btn-gray-shadow, .btn-group-scarlet.btn-gray-shadow, .btn-group-carrot.btn-gray-shadow, .btn-group-dark-gray.btn-gray-shadow {
  box-shadow: 0 12px 35px -10px rgba(0, 0, 0, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-light.btn-gray-shadow, .btn-group-light.btn-gray-shadow {
  box-shadow: 0 12px 35px -10px rgba(0, 0, 0, 0.45), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.07);
}

.btn-primary.btn-gray-shadow:hover, .btn-primary.btn-gray-shadow:focus, .btn-primary.btn-gray-shadow.focus, .btn-primary.btn-gray-shadow.disabled, .btn-primary.btn-gray-shadow:disabled, .btn-primary.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-primary.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-primary.btn-gray-shadow.dropdown-toggle:focus, .btn-secondary.btn-gray-shadow:hover, .btn-secondary.btn-gray-shadow:focus, .btn-secondary.btn-gray-shadow.focus, .btn-secondary.btn-gray-shadow.disabled, .btn-secondary.btn-gray-shadow:disabled, .btn-secondary.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-secondary.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-secondary.btn-gray-shadow.dropdown-toggle:focus, .btn-success.btn-gray-shadow:hover, .btn-success.btn-gray-shadow:focus, .btn-success.btn-gray-shadow.focus, .btn-success.btn-gray-shadow.disabled, .btn-success.btn-gray-shadow:disabled, .btn-success.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-success.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-success.btn-gray-shadow.dropdown-toggle:focus, .btn-info.btn-gray-shadow:hover, .btn-info.btn-gray-shadow:focus, .btn-info.btn-gray-shadow.focus, .btn-info.btn-gray-shadow.disabled, .btn-info.btn-gray-shadow:disabled, .btn-info.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-info.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-info.btn-gray-shadow.dropdown-toggle:focus, .btn-warning.btn-gray-shadow:hover, .btn-warning.btn-gray-shadow:focus, .btn-warning.btn-gray-shadow.focus, .btn-warning.btn-gray-shadow.disabled, .btn-warning.btn-gray-shadow:disabled, .btn-warning.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-warning.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-warning.btn-gray-shadow.dropdown-toggle:focus, .btn-danger.btn-gray-shadow:hover, .btn-danger.btn-gray-shadow:focus, .btn-danger.btn-gray-shadow.focus, .btn-danger.btn-gray-shadow.disabled, .btn-danger.btn-gray-shadow:disabled, .btn-danger.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-danger.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-danger.btn-gray-shadow.dropdown-toggle:focus, .btn-dark.btn-gray-shadow:hover, .btn-dark.btn-gray-shadow:focus, .btn-dark.btn-gray-shadow.focus, .btn-dark.btn-gray-shadow.disabled, .btn-dark.btn-gray-shadow:disabled, .btn-dark.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-dark.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-dark.btn-gray-shadow.dropdown-toggle:focus, .btn-indigo.btn-gray-shadow:hover, .btn-indigo.btn-gray-shadow:focus, .btn-indigo.btn-gray-shadow.focus, .btn-indigo.btn-gray-shadow.disabled, .btn-indigo.btn-gray-shadow:disabled, .btn-indigo.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-indigo.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-indigo.btn-gray-shadow.dropdown-toggle:focus, .btn-purple.btn-gray-shadow:hover, .btn-purple.btn-gray-shadow:focus, .btn-purple.btn-gray-shadow.focus, .btn-purple.btn-gray-shadow.disabled, .btn-purple.btn-gray-shadow:disabled, .btn-purple.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-purple.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-purple.btn-gray-shadow.dropdown-toggle:focus, .btn-pink.btn-gray-shadow:hover, .btn-pink.btn-gray-shadow:focus, .btn-pink.btn-gray-shadow.focus, .btn-pink.btn-gray-shadow.disabled, .btn-pink.btn-gray-shadow:disabled, .btn-pink.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-pink.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-pink.btn-gray-shadow.dropdown-toggle:focus, .btn-orange.btn-gray-shadow:hover, .btn-orange.btn-gray-shadow:focus, .btn-orange.btn-gray-shadow.focus, .btn-orange.btn-gray-shadow.disabled, .btn-orange.btn-gray-shadow:disabled, .btn-orange.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-orange.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-orange.btn-gray-shadow.dropdown-toggle:focus, .btn-sky.btn-gray-shadow:hover, .btn-sky.btn-gray-shadow:focus, .btn-sky.btn-gray-shadow.focus, .btn-sky.btn-gray-shadow.disabled, .btn-sky.btn-gray-shadow:disabled, .btn-sky.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-sky.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-sky.btn-gray-shadow.dropdown-toggle:focus, .btn-navy.btn-gray-shadow:hover, .btn-navy.btn-gray-shadow:focus, .btn-navy.btn-gray-shadow.focus, .btn-navy.btn-gray-shadow.disabled, .btn-navy.btn-gray-shadow:disabled, .btn-navy.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-navy.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-navy.btn-gray-shadow.dropdown-toggle:focus, .btn-berry.btn-gray-shadow:hover, .btn-berry.btn-gray-shadow:focus, .btn-berry.btn-gray-shadow.focus, .btn-berry.btn-gray-shadow.disabled, .btn-berry.btn-gray-shadow:disabled, .btn-berry.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-berry.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-berry.btn-gray-shadow.dropdown-toggle:focus, .btn-rose.btn-gray-shadow:hover, .btn-rose.btn-gray-shadow:focus, .btn-rose.btn-gray-shadow.focus, .btn-rose.btn-gray-shadow.disabled, .btn-rose.btn-gray-shadow:disabled, .btn-rose.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-rose.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-rose.btn-gray-shadow.dropdown-toggle:focus, .btn-orchid.btn-gray-shadow:hover, .btn-orchid.btn-gray-shadow:focus, .btn-orchid.btn-gray-shadow.focus, .btn-orchid.btn-gray-shadow.disabled, .btn-orchid.btn-gray-shadow:disabled, .btn-orchid.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-orchid.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-orchid.btn-gray-shadow.dropdown-toggle:focus, .btn-blush.btn-gray-shadow:hover, .btn-blush.btn-gray-shadow:focus, .btn-blush.btn-gray-shadow.focus, .btn-blush.btn-gray-shadow.disabled, .btn-blush.btn-gray-shadow:disabled, .btn-blush.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-blush.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-blush.btn-gray-shadow.dropdown-toggle:focus, .btn-scarlet.btn-gray-shadow:hover, .btn-scarlet.btn-gray-shadow:focus, .btn-scarlet.btn-gray-shadow.focus, .btn-scarlet.btn-gray-shadow.disabled, .btn-scarlet.btn-gray-shadow:disabled, .btn-scarlet.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-scarlet.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-scarlet.btn-gray-shadow.dropdown-toggle:focus, .btn-carrot.btn-gray-shadow:hover, .btn-carrot.btn-gray-shadow:focus, .btn-carrot.btn-gray-shadow.focus, .btn-carrot.btn-gray-shadow.disabled, .btn-carrot.btn-gray-shadow:disabled, .btn-carrot.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-carrot.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-carrot.btn-gray-shadow.dropdown-toggle:focus, .btn-dark-gray.btn-gray-shadow:hover, .btn-dark-gray.btn-gray-shadow:focus, .btn-dark-gray.btn-gray-shadow.focus, .btn-dark-gray.btn-gray-shadow.disabled, .btn-dark-gray.btn-gray-shadow:disabled, .btn-dark-gray.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-dark-gray.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-dark-gray.btn-gray-shadow.dropdown-toggle:focus, .btn-outline-primary.btn-gray-shadow:hover, .btn-outline-primary.btn-gray-shadow:focus, .btn-outline-primary.btn-gray-shadow.focus, .btn-outline-primary.btn-gray-shadow:not(:disabled):not(.disabled):active, .btn-outline-primary.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-outline-primary.btn-gray-shadow.active:not(:disabled):not(.disabled), .btn-outline-primary.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-outline-primary.btn-gray-shadow.dropdown-toggle,
.show > .btn-outline-primary.btn-gray-shadow.dropdown-toggle:focus, .btn-outline-secondary.btn-gray-shadow:hover, .btn-outline-secondary.btn-gray-shadow:focus, .btn-outline-secondary.btn-gray-shadow.focus, .btn-outline-secondary.btn-gray-shadow:not(:disabled):not(.disabled):active, .btn-outline-secondary.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary.btn-gray-shadow.active:not(:disabled):not(.disabled), .btn-outline-secondary.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-outline-secondary.btn-gray-shadow.dropdown-toggle,
.show > .btn-outline-secondary.btn-gray-shadow.dropdown-toggle:focus, .btn-outline-success.btn-gray-shadow:hover, .btn-outline-success.btn-gray-shadow:focus, .btn-outline-success.btn-gray-shadow.focus, .btn-outline-success.btn-gray-shadow:not(:disabled):not(.disabled):active, .btn-outline-success.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-outline-success.btn-gray-shadow.active:not(:disabled):not(.disabled), .btn-outline-success.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-outline-success.btn-gray-shadow.dropdown-toggle,
.show > .btn-outline-success.btn-gray-shadow.dropdown-toggle:focus, .btn-outline-info.btn-gray-shadow:hover, .btn-outline-info.btn-gray-shadow:focus, .btn-outline-info.btn-gray-shadow.focus, .btn-outline-info.btn-gray-shadow:not(:disabled):not(.disabled):active, .btn-outline-info.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-outline-info.btn-gray-shadow.active:not(:disabled):not(.disabled), .btn-outline-info.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-outline-info.btn-gray-shadow.dropdown-toggle,
.show > .btn-outline-info.btn-gray-shadow.dropdown-toggle:focus, .btn-outline-warning.btn-gray-shadow:hover, .btn-outline-warning.btn-gray-shadow:focus, .btn-outline-warning.btn-gray-shadow.focus, .btn-outline-warning.btn-gray-shadow:not(:disabled):not(.disabled):active, .btn-outline-warning.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-outline-warning.btn-gray-shadow.active:not(:disabled):not(.disabled), .btn-outline-warning.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-outline-warning.btn-gray-shadow.dropdown-toggle,
.show > .btn-outline-warning.btn-gray-shadow.dropdown-toggle:focus, .btn-outline-danger.btn-gray-shadow:hover, .btn-outline-danger.btn-gray-shadow:focus, .btn-outline-danger.btn-gray-shadow.focus, .btn-outline-danger.btn-gray-shadow:not(:disabled):not(.disabled):active, .btn-outline-danger.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-outline-danger.btn-gray-shadow.active:not(:disabled):not(.disabled), .btn-outline-danger.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-outline-danger.btn-gray-shadow.dropdown-toggle,
.show > .btn-outline-danger.btn-gray-shadow.dropdown-toggle:focus, .btn-outline-dark.btn-gray-shadow:hover, .btn-outline-dark.btn-gray-shadow:focus, .btn-outline-dark.btn-gray-shadow.focus, .btn-outline-dark.btn-gray-shadow:not(:disabled):not(.disabled):active, .btn-outline-dark.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-outline-dark.btn-gray-shadow.active:not(:disabled):not(.disabled), .btn-outline-dark.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-outline-dark.btn-gray-shadow.dropdown-toggle,
.show > .btn-outline-dark.btn-gray-shadow.dropdown-toggle:focus, .btn-outline-indigo.btn-gray-shadow:hover, .btn-outline-indigo.btn-gray-shadow:focus, .btn-outline-indigo.btn-gray-shadow.focus, .btn-outline-indigo.btn-gray-shadow:not(:disabled):not(.disabled):active, .btn-outline-indigo.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-outline-indigo.btn-gray-shadow.active:not(:disabled):not(.disabled), .btn-outline-indigo.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-outline-indigo.btn-gray-shadow.dropdown-toggle,
.show > .btn-outline-indigo.btn-gray-shadow.dropdown-toggle:focus, .btn-outline-purple.btn-gray-shadow:hover, .btn-outline-purple.btn-gray-shadow:focus, .btn-outline-purple.btn-gray-shadow.focus, .btn-outline-purple.btn-gray-shadow:not(:disabled):not(.disabled):active, .btn-outline-purple.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-outline-purple.btn-gray-shadow.active:not(:disabled):not(.disabled), .btn-outline-purple.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-outline-purple.btn-gray-shadow.dropdown-toggle,
.show > .btn-outline-purple.btn-gray-shadow.dropdown-toggle:focus, .btn-outline-pink.btn-gray-shadow:hover, .btn-outline-pink.btn-gray-shadow:focus, .btn-outline-pink.btn-gray-shadow.focus, .btn-outline-pink.btn-gray-shadow:not(:disabled):not(.disabled):active, .btn-outline-pink.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-outline-pink.btn-gray-shadow.active:not(:disabled):not(.disabled), .btn-outline-pink.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-outline-pink.btn-gray-shadow.dropdown-toggle,
.show > .btn-outline-pink.btn-gray-shadow.dropdown-toggle:focus, .btn-outline-orange.btn-gray-shadow:hover, .btn-outline-orange.btn-gray-shadow:focus, .btn-outline-orange.btn-gray-shadow.focus, .btn-outline-orange.btn-gray-shadow:not(:disabled):not(.disabled):active, .btn-outline-orange.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-outline-orange.btn-gray-shadow.active:not(:disabled):not(.disabled), .btn-outline-orange.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-outline-orange.btn-gray-shadow.dropdown-toggle,
.show > .btn-outline-orange.btn-gray-shadow.dropdown-toggle:focus, .btn-outline-sky.btn-gray-shadow:hover, .btn-outline-sky.btn-gray-shadow:focus, .btn-outline-sky.btn-gray-shadow.focus, .btn-outline-sky.btn-gray-shadow:not(:disabled):not(.disabled):active, .btn-outline-sky.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-outline-sky.btn-gray-shadow.active:not(:disabled):not(.disabled), .btn-outline-sky.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-outline-sky.btn-gray-shadow.dropdown-toggle,
.show > .btn-outline-sky.btn-gray-shadow.dropdown-toggle:focus, .btn-outline-navy.btn-gray-shadow:hover, .btn-outline-navy.btn-gray-shadow:focus, .btn-outline-navy.btn-gray-shadow.focus, .btn-outline-navy.btn-gray-shadow:not(:disabled):not(.disabled):active, .btn-outline-navy.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-outline-navy.btn-gray-shadow.active:not(:disabled):not(.disabled), .btn-outline-navy.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-outline-navy.btn-gray-shadow.dropdown-toggle,
.show > .btn-outline-navy.btn-gray-shadow.dropdown-toggle:focus, .btn-outline-berry.btn-gray-shadow:hover, .btn-outline-berry.btn-gray-shadow:focus, .btn-outline-berry.btn-gray-shadow.focus, .btn-outline-berry.btn-gray-shadow:not(:disabled):not(.disabled):active, .btn-outline-berry.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-outline-berry.btn-gray-shadow.active:not(:disabled):not(.disabled), .btn-outline-berry.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-outline-berry.btn-gray-shadow.dropdown-toggle,
.show > .btn-outline-berry.btn-gray-shadow.dropdown-toggle:focus, .btn-outline-rose.btn-gray-shadow:hover, .btn-outline-rose.btn-gray-shadow:focus, .btn-outline-rose.btn-gray-shadow.focus, .btn-outline-rose.btn-gray-shadow:not(:disabled):not(.disabled):active, .btn-outline-rose.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-outline-rose.btn-gray-shadow.active:not(:disabled):not(.disabled), .btn-outline-rose.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-outline-rose.btn-gray-shadow.dropdown-toggle,
.show > .btn-outline-rose.btn-gray-shadow.dropdown-toggle:focus, .btn-outline-orchid.btn-gray-shadow:hover, .btn-outline-orchid.btn-gray-shadow:focus, .btn-outline-orchid.btn-gray-shadow.focus, .btn-outline-orchid.btn-gray-shadow:not(:disabled):not(.disabled):active, .btn-outline-orchid.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-outline-orchid.btn-gray-shadow.active:not(:disabled):not(.disabled), .btn-outline-orchid.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-outline-orchid.btn-gray-shadow.dropdown-toggle,
.show > .btn-outline-orchid.btn-gray-shadow.dropdown-toggle:focus, .btn-outline-blush.btn-gray-shadow:hover, .btn-outline-blush.btn-gray-shadow:focus, .btn-outline-blush.btn-gray-shadow.focus, .btn-outline-blush.btn-gray-shadow:not(:disabled):not(.disabled):active, .btn-outline-blush.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-outline-blush.btn-gray-shadow.active:not(:disabled):not(.disabled), .btn-outline-blush.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-outline-blush.btn-gray-shadow.dropdown-toggle,
.show > .btn-outline-blush.btn-gray-shadow.dropdown-toggle:focus, .btn-outline-scarlet.btn-gray-shadow:hover, .btn-outline-scarlet.btn-gray-shadow:focus, .btn-outline-scarlet.btn-gray-shadow.focus, .btn-outline-scarlet.btn-gray-shadow:not(:disabled):not(.disabled):active, .btn-outline-scarlet.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-outline-scarlet.btn-gray-shadow.active:not(:disabled):not(.disabled), .btn-outline-scarlet.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-outline-scarlet.btn-gray-shadow.dropdown-toggle,
.show > .btn-outline-scarlet.btn-gray-shadow.dropdown-toggle:focus, .btn-outline-carrot.btn-gray-shadow:hover, .btn-outline-carrot.btn-gray-shadow:focus, .btn-outline-carrot.btn-gray-shadow.focus, .btn-outline-carrot.btn-gray-shadow:not(:disabled):not(.disabled):active, .btn-outline-carrot.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-outline-carrot.btn-gray-shadow.active:not(:disabled):not(.disabled), .btn-outline-carrot.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-outline-carrot.btn-gray-shadow.dropdown-toggle,
.show > .btn-outline-carrot.btn-gray-shadow.dropdown-toggle:focus, .btn-outline-dark-gray.btn-gray-shadow:hover, .btn-outline-dark-gray.btn-gray-shadow:focus, .btn-outline-dark-gray.btn-gray-shadow.focus, .btn-outline-dark-gray.btn-gray-shadow:not(:disabled):not(.disabled):active, .btn-outline-dark-gray.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-gray.btn-gray-shadow.active:not(:disabled):not(.disabled), .btn-outline-dark-gray.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-outline-dark-gray.btn-gray-shadow.dropdown-toggle,
.show > .btn-outline-dark-gray.btn-gray-shadow.dropdown-toggle:focus {
  box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.4);
}

.btn-light.btn-gray-shadow:hover, .btn-light.btn-gray-shadow:focus, .btn-light.btn-gray-shadow.focus, .btn-light.btn-gray-shadow.disabled, .btn-light.btn-gray-shadow:disabled, .btn-light.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-light.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-light.btn-gray-shadow.dropdown-toggle:focus, .btn-outline-light.btn-gray-shadow:hover, .btn-outline-light.btn-gray-shadow:focus, .btn-outline-light.btn-gray-shadow.focus, .btn-outline-light.btn-gray-shadow:not(:disabled):not(.disabled):active, .btn-outline-light.btn-gray-shadow:not(:disabled):not(.disabled):active:focus, .btn-outline-light.btn-gray-shadow.active:not(:disabled):not(.disabled), .btn-outline-light.btn-gray-shadow.active:not(:disabled):not(.disabled):focus,
.show > .btn-outline-light.btn-gray-shadow.dropdown-toggle,
.show > .btn-outline-light.btn-gray-shadow.dropdown-toggle:focus {
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.3);
}

.btn.btn-link {
  font-weight: 600;
  text-decoration: none;
}

.btn.btn-link, .btn.btn-link:hover, .btn.btn-link:focus, .btn.btn-link.focus, .btn.btn-link:not(:disabled):not(.disabled):active, .btn.btn-link:not(:disabled):not(.disabled).active,
.show > .btn.btn-link.dropdown-toggle {
  color: #2196f3;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

.btn.btn-link:hover, .btn.btn-link.focus, .btn.btn-link:focus {
  color: rgba(33, 150, 243, 0.8);
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.9375rem 1.9375rem;
  font-size: 0.875rem;
  line-height: 1.45;
  border-radius: 0.1875rem;
}

.btn-lg > i, .btn-group-lg > .btn > i {
  font-size: 0.9375rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.3125rem 1rem;
  font-size: 0.6875rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
}

.btn-sm > i, .btn-group-sm > .btn > i {
  font-size: 0.75rem;
}

.btn-round {
  border-radius: 1.3125rem;
}

.btn-round.btn-lg, .btn-group-lg > .btn-round.btn {
  border-radius: 1.6875rem;
}

.btn-round.btn-sm, .btn-group-sm > .btn-round.btn {
  border-radius: 0.9375rem;
}

.btn-icon-only {
  width: 43px;
  height: 43px;
  padding: 0;
  line-height: 39px;
}

.btn-icon-only > i {
  margin: 0;
}

.btn-icon-only.btn-lg, .btn-group-lg > .btn-icon-only.btn {
  width: 54px;
  height: 54px;
  line-height: 50px;
}

.btn-icon-only.btn-sm, .btn-group-sm > .btn-icon-only.btn {
  width: 30px;
  height: 30px;
  line-height: 26px;
}

.btn-icon-only.btn-round {
  border-radius: 50%;
}

.btn-letter-spacing {
  letter-spacing: 0.09375rem;
}

.dropdown-toggle {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.dropdown-toggle::after {
  width: auto;
  height: auto;
  /* stylelint-disable */
  font-family: "Font Awesome 5 Free";
  /* stylelint-enable */
  font-size: 1.2em;
  line-height: 1;
  vertical-align: initial;
  border: none;
  content: "\f0d7";
}

.dropdown-toggle::before, .dropdown-toggle::after {
  margin-left: .6em;
  /* stylelint-disable */
  font-size: 0.875rem !important;
  line-height: .5 !important;
  /* stylelint-enable */
}

.dropdown-toggle.btn-sm::before, .btn-group-sm > .dropdown-toggle.btn::before, .dropdown-toggle.btn-sm::after, .btn-group-sm > .dropdown-toggle.btn::after {
  /* stylelint-disable-next-line declaration-no-important */
  font-size: 0.75rem !important;
}

.dropdown-toggle.btn-lg::before, .btn-group-lg > .dropdown-toggle.btn::before, .dropdown-toggle.btn-lg::after, .btn-group-lg > .dropdown-toggle.btn::after {
  /* stylelint-disable-next-line declaration-no-important */
  font-size: 1rem !important;
}

.dropup .dropdown-toggle::after {
  width: auto;
  height: auto;
  /* stylelint-disable */
  font-family: "Font Awesome 5 Free";
  /* stylelint-enable */
  font-size: 1.2em;
  line-height: 1;
  vertical-align: initial;
  border: none;
  content: "\f0d8";
}

.dropup .dropdown-toggle::before, .dropup .dropdown-toggle::after {
  margin-left: .6em;
}

.dropright .dropdown-toggle::after {
  width: auto;
  height: auto;
  /* stylelint-disable */
  font-family: "Font Awesome 5 Free";
  /* stylelint-enable */
  font-size: 1.2em;
  line-height: 1;
  vertical-align: initial;
  border: none;
  content: "\f0da";
}

.dropright .dropdown-toggle::before, .dropright .dropdown-toggle::after {
  margin-left: .6em;
}

.dropleft .dropdown-toggle::after {
  width: auto;
  height: auto;
  /* stylelint-disable */
  font-family: "Font Awesome 5 Free";
  /* stylelint-enable */
  font-size: 1.2em;
  line-height: 1;
  vertical-align: initial;
  border: none;
}

.dropleft .dropdown-toggle::before {
  width: auto;
  height: auto;
  /* stylelint-disable */
  font-family: "Font Awesome 5 Free";
  /* stylelint-enable */
  font-size: 1.2em;
  line-height: 1;
  vertical-align: initial;
  border: none;
  content: "\f0d9";
}

.dropleft .dropdown-toggle::before, .dropleft .dropdown-toggle::after {
  margin-right: .6em;
  margin-left: 0;
}

.dropdown-caret-only {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}

.dropdown-caret-only::after, .dropdown-caret-only::before {
  margin: 0;
}

.dropdown-menu {
  font-size: 0.875rem;
  border: none;
  box-shadow: 0 0.375rem 1.375rem rgba(0, 0, 0, 0.12);
  border-radius: 0.1875rem;
}

.dropdown-item {
  transition: all 0.2s ease-in-out;
}

.dropdown-divider {
  height: 0;
  margin: 0.625rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.btn-group,
.btn-group-vertical {
  margin-bottom: 1rem;
  border-radius: 0.1875rem;
  transition: all 0.2s ease-in-out;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  margin-bottom: 0;
}

.btn-group:not(.btn-group-outline) > .btn,
.btn-group-vertical:not(.btn-group-outline) > .btn {
  box-shadow: none;
}

.btn-group-default {
  box-shadow: 0 12px 35px -10px rgba(189, 195, 199, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-group-primary {
  box-shadow: 0 12px 35px -10px rgba(33, 150, 243, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-group-secondary {
  box-shadow: 0 12px 35px -10px rgba(77, 182, 172, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-group-success {
  box-shadow: 0 12px 35px -10px rgba(102, 187, 106, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-group-info {
  box-shadow: 0 12px 35px -10px rgba(129, 212, 250, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-group-warning {
  box-shadow: 0 12px 35px -10px rgba(251, 192, 45, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-group-danger {
  box-shadow: 0 12px 35px -10px rgba(229, 57, 53, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-group-light {
  box-shadow: 0 12px 35px -10px rgba(247, 249, 249, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 8px 25px -2px rgba(0, 0, 0, 0.12);
}

.btn-group-dark {
  box-shadow: 0 12px 35px -10px rgba(28, 40, 51, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-group-indigo {
  box-shadow: 0 12px 35px -10px rgba(21, 101, 192, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-group-purple {
  box-shadow: 0 12px 35px -10px rgba(156, 39, 176, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-group-pink {
  box-shadow: 0 12px 35px -10px rgba(240, 98, 146, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-group-orange {
  box-shadow: 0 12px 35px -10px rgba(251, 140, 0, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-group-sky {
  box-shadow: 0 12px 35px -10px rgba(100, 181, 246, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-group-navy {
  box-shadow: 0 12px 35px -10px rgba(13, 71, 161, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-group-berry {
  box-shadow: 0 12px 35px -10px rgba(183, 28, 28, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-group-rose {
  box-shadow: 0 12px 35px -10px rgba(233, 30, 99, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-group-orchid {
  box-shadow: 0 12px 35px -10px rgba(186, 104, 200, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-group-blush {
  box-shadow: 0 12px 35px -10px rgba(239, 83, 80, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-group-scarlet {
  box-shadow: 0 12px 35px -10px rgba(211, 47, 47, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-group-carrot {
  box-shadow: 0 12px 35px -10px rgba(239, 108, 0, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-group-dark-gray {
  box-shadow: 0 12px 35px -10px rgba(32, 32, 32, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.btn-group-round {
  border-radius: 1.3125rem;
}

.btn-group-round-lg {
  border-radius: 1.6875rem;
}

.btn-group-round-sm {
  border-radius: 0.9375rem;
}

.input-group-inner {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group-inner > .form-control,
.input-group-inner > .custom-select,
.input-group-inner > .custom-file {
  position: relative;
  z-index: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
  /* stylelint-disable */
  border-color: transparent !important;
  box-shadow: none !important;
  /* stylelint-enable */
}

.input-group-inner > .form-control + .form-control,
.input-group-inner > .form-control + .custom-select,
.input-group-inner > .form-control + .custom-file,
.input-group-inner > .custom-select + .form-control,
.input-group-inner > .custom-select + .custom-select,
.input-group-inner > .custom-select + .custom-file,
.input-group-inner > .custom-file + .form-control,
.input-group-inner > .custom-file + .custom-select,
.input-group-inner > .custom-file + .custom-file {
  margin-left: -2px;
}

.input-group-inner > .form-control:focus ~ .input-focus-bg,
.input-group-inner > .custom-select:focus ~ .input-focus-bg,
.input-group-inner > .custom-file:focus ~ .input-focus-bg {
  border-color: #2196f3;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.input-group-inner > .form-control .custom-file-label,
.input-group-inner > .custom-select .custom-file-label,
.input-group-inner > .custom-file .custom-file-label {
  /* stylelint-disable */
  border-color: transparent !important;
  box-shadow: none !important;
  /* stylelint-enable */
}

.input-group-inner > .form-control .custom-file-label.input-group-first::after,
.input-group-inner > .custom-select .custom-file-label.input-group-first::after,
.input-group-inner > .custom-file .custom-file-label.input-group-first::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-inner > .form-control ~ .custom-file-focus-bg,
.input-group-inner > .custom-select ~ .custom-file-focus-bg,
.input-group-inner > .custom-file ~ .custom-file-focus-bg {
  border-color: #2196f3;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.input-group-inner > .form-control:focus,
.input-group-inner > .custom-select:focus,
.input-group-inner > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group-inner > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group-inner > .custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.input-group-inner > .custom-file + .input-group-append {
  margin-left: -0.3125rem;
  border-left: none;
}

.input-group-inner .form-control:disabled,
.input-group-inner .form-control[readonly] {
  background-color: transparent;
}

.input-group-inner .form-control:disabled ~ .input-focus-bg,
.input-group-inner .form-control[readonly] ~ .input-focus-bg {
  background-color: #e9ecef;
}

.input-group-inner .custom-select:disabled {
  background-color: transparent;
}

.input-group-inner .custom-select:disabled ~ .input-focus-bg {
  background-color: #e9ecef;
}

.input-group-inner .custom-file-input[disabled] ~ .custom-file-label,
.input-group-inner .custom-file-input:disabled ~ .custom-file-label {
  background-color: transparent;
}

.input-group-inner .input-group-file-disabled ~ .input-focus-bg {
  background-color: #e9ecef;
}

.input-group-inner .input-group-file-disabled ~ .input-focus-bg.custom-file-focus-bg {
  border-color: #e0e0e0;
  box-shadow: none;
}

.input-group-inner .invalid-feedback + .input-focus-bg {
  height: 2.6875rem;
}

.input-focus-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #e0e0e0;
  border-radius: 0.1875rem;
  transition: all 0.2s ease-in-out;
}

.input-group-prepend,
.input-group-append {
  z-index: 1;
  border: 0.3125rem solid transparent;
}

.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text {
  margin-left: 0;
}

.input-group-prepend .btn,
.input-group-append .btn {
  padding-top: 0;
  padding-bottom: 0;
}

.input-group-prepend .btn.input-group-first,
.input-group-append .btn.input-group-first {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend .btn.input-group-middle,
.input-group-append .btn.input-group-middle {
  border-radius: 0;
}

.input-group-prepend .btn.input-group-last,
.input-group-append .btn.input-group-last {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-text {
  font-size: 0.75rem;
  border: none;
  border-radius: 0.1875rem;
  /* stylelint-disable */
  /* stylelint-enable */
}

.input-group-text.input-group-first {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.input-group-text.input-group-middle {
  border-radius: 0 !important;
}

.input-group-text.input-group-last {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.input-group-text.input-group-icon {
  -ms-flex-pack: center;
  justify-content: center;
  width: 2.0625rem;
  padding-right: 0.375rem;
  padding-left: 0.375rem;
  /* stylelint-disable-next-line declaration-no-important */
  font-size: 0.875rem !important;
}

.input-group-lg .input-group-text.input-group-icon {
  width: 2.75rem;
  /* stylelint-disable */
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
  font-size: 1rem !important;
  /* stylelint-enable */
}

.input-group-sm .input-group-text.input-group-icon {
  width: 1.25rem;
  /* stylelint-disable */
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
  font-size: 0.6875rem !important;
  /* stylelint-enable */
}

.input-group-lg > .input-group-inner > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-inner > .input-group-append > .input-group-text {
  padding: 0.5rem 1.375rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.input-group-sm > .input-group-inner > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-inner > .input-group-append > .input-group-text {
  padding: 0.125rem 0.875rem;
  font-size: 0.6875rem;
  line-height: 1.5;
}

.input-group-lg > .input-group-inner .invalid-feedback + .input-focus-bg {
  height: 3.375rem;
}

.input-group-sm > .input-group-inner .invalid-feedback + .input-focus-bg {
  height: 1.875rem;
}

.input-group > .input-group-inner > .input-group-prepend > .btn-icon-only,
.input-group > .input-group-inner > .input-group-append > .btn-icon-only {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 2.0625rem;
  height: 2.0625rem;
}

.input-group-sm > .input-group-inner > .input-group-prepend > .btn-icon-only,
.input-group-sm > .input-group-inner > .input-group-append > .btn-icon-only {
  width: 1.25rem;
  height: 1.25rem;
}

.input-group-lg > .input-group-inner > .input-group-prepend > .btn-icon-only,
.input-group-lg > .input-group-inner > .input-group-append > .btn-icon-only {
  width: 2.75rem;
  height: 2.75rem;
}

.input-group-round .input-focus-bg {
  border-radius: 1.3125rem;
}

.input-group-round .input-group-text {
  border-radius: 1rem;
}

.input-group-round.input-group-lg .input-focus-bg {
  border-radius: 1.6875rem;
}

.input-group-round.input-group-lg .input-group-text {
  border-radius: 1.375rem;
}

.input-group-round.input-group-sm .input-focus-sm {
  border-radius: 0.9375rem;
}

.input-group-round.input-group-sm .input-group-text {
  border-radius: 0.625rem;
}

.form-light-1 .input-focus-bg {
  border-color: rgba(255, 255, 255, 0.19);
}

.form-light-1 .input-group-inner > .form-control:focus ~ .input-focus-bg,
.form-light-1 .input-group-inner > .custom-select:focus ~ .input-focus-bg,
.form-light-1 .input-group-inner > .custom-file:focus ~ .input-focus-bg {
  border-color: #2196f3;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.form-light-1 .input-group-inner > .form-control ~ .custom-file-focus-bg,
.form-light-1 .input-group-inner > .custom-select ~ .custom-file-focus-bg,
.form-light-1 .input-group-inner > .custom-file ~ .custom-file-focus-bg {
  border-color: #2196f3;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.form-light-1 .input-group-inner .form-control:disabled ~ .input-focus-bg,
.form-light-1 .input-group-inner .form-control[readonly] ~ .input-focus-bg {
  background-color: rgba(255, 255, 255, 0.1);
}

.form-light-1 .input-group-inner .custom-select:disabled ~ .input-focus-bg {
  background-color: rgba(255, 255, 255, 0.1);
}

.form-light-1 .input-group-file-disabled ~ .input-focus-bg {
  background-color: rgba(255, 255, 255, 0.1);
}

.form-light-1 .input-group-file-disabled ~ .input-focus-bg.custom-file-focus-bg {
  border-color: rgba(255, 255, 255, 0.19);
}

.form-light-1 .input-group-text {
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
}

.form-light-2 .input-focus-bg {
  border-color: rgba(255, 255, 255, 0.5);
}

.form-light-2 .input-group-inner > .form-control:focus ~ .input-focus-bg,
.form-light-2 .input-group-inner > .custom-select:focus ~ .input-focus-bg,
.form-light-2 .input-group-inner > .custom-file:focus ~ .input-focus-bg {
  border-color: #fff;
  box-shadow: 0 0 8px 0 rgba(255, 255, 255, 0.2);
}

.form-light-2 .input-group-inner > .form-control ~ .custom-file-focus-bg,
.form-light-2 .input-group-inner > .custom-select ~ .custom-file-focus-bg,
.form-light-2 .input-group-inner > .custom-file ~ .custom-file-focus-bg {
  border-color: #fff;
  box-shadow: 0 0 8px 0 rgba(255, 255, 255, 0.2);
}

.form-light-2 .input-group-inner .form-control:disabled ~ .input-focus-bg,
.form-light-2 .input-group-inner .form-control[readonly] ~ .input-focus-bg {
  background-color: rgba(255, 255, 255, 0.25);
}

.form-light-2 .input-group-inner .custom-select:disabled ~ .input-focus-bg {
  background-color: rgba(255, 255, 255, 0.25);
}

.form-light-2 .input-group-file-disabled ~ .input-focus-bg {
  background-color: rgba(255, 255, 255, 0.25);
}

.form-light-2 .input-group-file-disabled ~ .input-focus-bg.custom-file-focus-bg {
  border-color: rgba(255, 255, 255, 0.5);
}

.form-light-2 .input-group-text {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.25);
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  opacity: 0.65;
}

.custom-control-label::before, .custom-control-label::after {
  top: 0.0825rem;
  border: none;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #2196f3;
}

.custom-checkbox .custom-control-label::after {
  background-size: 100%;
}

.custom-radio .custom-control-label::after {
  background-size: 100%;
}

.custom-switch {
  padding-left: 2.125rem;
}

.custom-switch.custom-control {
  min-height: 1.25rem;
}

.custom-switch .custom-control-label {
  padding-top: 1px;
}

.custom-switch .custom-control-label::before {
  top: 0;
  left: -2.125rem;
  height: 1.25rem;
  border-radius: 0.625rem;
}

.custom-switch .custom-control-label::after {
  top: 2px;
  left: calc(-2.125rem + 2px);
  background-color: #fff;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.875rem);
  transform: translateX(0.875rem);
}

.custom-select {
  font-size: 0.75rem;
  background-position: right 0.9375rem center;
  border-radius: 0.1875rem;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
  /* stylelint-disable-next-line declaration-no-important */
  background-image: none !important;
}

.custom-select[multiple] option, .custom-select[size]:not([size="1"]) option {
  padding: 0.5rem 0.75rem;
  margin: 2px 0;
  border-radius: 0.1875rem;
}

.custom-select[multiple] option:first-child, .custom-select[size]:not([size="1"]) option:first-child {
  margin-top: 0;
}

.custom-select[multiple] option:last-child, .custom-select[size]:not([size="1"]) option:last-child {
  margin-bottom: 0;
}

.custom-select[multiple].form-control-sm, .custom-select[multiple].custom-select-sm, .custom-select[size]:not([size="1"]).form-control-sm, .custom-select[size]:not([size="1"]).custom-select-sm {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.custom-select[multiple].form-control-sm option, .custom-select[multiple].custom-select-sm option, .custom-select[size]:not([size="1"]).form-control-sm option, .custom-select[size]:not([size="1"]).custom-select-sm option {
  padding: 0.4375rem 0.5rem;
}

.custom-select[multiple].form-control-lg, .custom-select[multiple].custom-select-lg, .custom-select[size]:not([size="1"]).form-control-lg, .custom-select[size]:not([size="1"]).custom-select-lg {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.custom-select[multiple].form-control-lg option, .custom-select[multiple].custom-select-lg option, .custom-select[size]:not([size="1"]).form-control-lg option, .custom-select[size]:not([size="1"]).custom-select-lg option {
  padding: 0.5625rem 1rem;
}

.custom-select.form-control-sm:not([size]):not([multiple]), .custom-select.custom-select-sm:not([size]):not([multiple]) {
  height: 1.875rem;
}

.custom-select.form-control-lg:not([size]):not([multiple]), .custom-select.custom-select-lg:not([size]):not([multiple]) {
  height: 3.375rem;
}

.custom-select:focus {
  color: #717171;
  background-color: transparent;
  border-color: #2196f3;
  outline: 0;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.form-light-1 .custom-select {
  background-image: url("../svg/caret-down-gray.svg");
}

.form-light-1 .custom-switch .custom-control-label::after {
  background-color: rgba(255, 255, 255, 0.5);
}

.form-light-2 .custom-select {
  background-image: url("../svg/caret-down-white.svg");
}

.form-light-2 .custom-switch .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: rgba(255, 255, 255, 0.4);
}

.form-light-2 .custom-switch .custom-control-label::before {
  background-color: rgba(255, 255, 255, 0.4);
}

.form-light-2 .custom-switch .custom-control-label::after {
  background-color: rgba(255, 255, 255, 0.75);
}

.custom-select.form-control-sm, .custom-select.custom-select-sm {
  padding: 0.25rem 1.875rem 0.25rem 0.875rem;
  font-size: 0.6875rem;
  background-size: 9px 12px;
}

.custom-select.form-control-lg, .custom-select.custom-select-lg {
  padding: 0.5rem 2.375rem 0.5rem 1.375rem;
  font-size: 0.875rem;
  background-size: 13px 16px;
}

.custom-file-label, .custom-file-label::after {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
}

.custom-file-label {
  margin-bottom: 0;
  border-radius: 0.1875rem;
}

.custom-file-label::after {
  top: 3px;
  right: 3px;
  bottom: 3px;
  height: auto;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  border-left: none;
  border-radius: 0.1875rem;
}

.custom-file-sm, .custom-file-sm .custom-file-input, .custom-file-sm .custom-file-label {
  height: 1.875rem;
}

.custom-file-lg, .custom-file-lg .custom-file-input, .custom-file-lg .custom-file-label {
  height: 3.375rem;
}

.custom-file-lg .custom-file-label {
  padding: 0.5rem 1.375rem;
  font-size: 0.875rem;
}

.custom-file-sm .custom-file-label {
  padding: 0.5rem 0.875rem;
  font-size: 0.6875rem;
  line-height: .5;
}

.custom-file-sm .custom-file-label::after {
  line-height: .5;
}

.custom-range:hover::-webkit-slider-thumb, .custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.25);
}

.custom-range:hover::-moz-range-thumb, .custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.25);
}

.custom-range:hover::-ms-thumb, .custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.25);
}

.custom-range::-webkit-slider-thumb {
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.25);
}

.custom-range::-moz-range-thumb {
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.25);
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.25);
}

.custom-range::-ms-track {
  border-width: 0.7rem;
}

.nav-link {
  position: relative;
  font-size: 0.75rem;
  font-weight: 600;
  color: #717171;
  text-transform: uppercase;
}

.nav-link::before {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 3px;
  content: "";
  background-color: #2196f3;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.nav-link.active {
  color: #2196f3;
}

.nav-link.active::before {
  opacity: 1;
}

.nav-link:hover, .nav-link:focus {
  color: #2196f3;
}

.nav-link:hover::before, .nav-link:focus::before {
  opacity: 1;
}

.nav-link.disabled {
  color: #717171;
  opacity: 0.65;
}

.nav-link.disabled::before {
  display: none;
}

.nav-link.dropdown-toggle::before {
  left: -8px;
}

.nav-link.dropdown-toggle::after {
  position: relative;
  top: -1px;
}

.nav-link.dropdown-toggle.focus, .nav-link.dropdown-toggle:focus {
  outline: 0;
}

.nav.flex-column .nav-link {
  padding: 0.9375rem 1.75rem;
}

.nav.flex-column .nav-link::before {
  top: 0;
  bottom: inherit;
  width: 3px;
  height: 100%;
}

.nav-tabs {
  position: relative;
  border-bottom: none;
}

.nav-tabs > .nav-item {
  position: relative;
  z-index: 1;
}

.nav-tabs .nav-link {
  border: none;
}

.nav-tabs::after {
  position: absolute;
  bottom: -4px;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 3px;
  content: "";
  background-color: rgba(0, 0, 0, 0.07);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #2196f3;
  background-color: transparent;
}

.nav-tabs .dropdown-menu {
  margin-top: 0;
  border-top-left-radius: 0.1875rem;
  border-top-right-radius: 0.1875rem;
}

.nav-pills > .nav-item,
.nav-pills > .nav-link {
  margin-right: 0.625rem;
  margin-bottom: 0.625rem;
}

.nav-pills > .nav-item:last-child,
.nav-pills > .nav-link:last-child {
  margin-right: 0;
}

.nav-pills .nav-link {
  padding: 0.75rem 1.875rem;
  background-color: #f5f7f7;
  transition: all 0.2s ease-in-out;
}

.nav-pills .nav-link::before {
  display: none;
}

.nav-pills .nav-link.active, .nav-pills .nav-link:hover, .nav-pills .nav-link:focus {
  color: #fff;
  background-color: #2196f3;
  box-shadow: 0 12px 35px -10px rgba(33, 150, 243, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.nav-pills .nav-link.disabled {
  color: #717171;
  background-color: transparent;
  box-shadow: none;
}

.nav-pills.flex-column > .nav-item,
.nav-pills.flex-column > .nav-link {
  margin-right: 0;
}

.nav-pills.flex-column .nav-link {
  padding: 0.75rem 1.875rem;
  margin-bottom: 1.5625rem;
}

.nav-pills.flex-column.flex-sm-row .nav-link, .nav-pills.flex-column.flex-md-row .nav-link, .nav-pills.flex-column.flex-lg-row .nav-link, .nav-pills.flex-column.flex-xl-row .nav-link {
  margin-right: 0.625rem;
}

.tab-content {
  padding-top: 2.625rem;
}

.nav-pills + .tab-content {
  padding-top: 1.125rem;
}

.nav-light-1 .nav-link {
  color: rgba(255, 255, 255, 0.75);
}

.nav-light-1 .nav-link.active, .nav-light-1 .nav-link:hover, .nav-light-1 .nav-link:focus {
  color: #2196f3;
}

.nav-light-1 .nav-link.disabled {
  /* stylelint-disable-next-line declaration-no-important */
  color: rgba(255, 255, 255, 0.75) !important;
  opacity: 0.5;
}

.nav-light-1.nav-pills .nav-link {
  background-color: rgba(255, 255, 255, 0.06);
}

.nav-light-1.nav-pills .nav-link.active, .nav-light-1.nav-pills .nav-link:hover, .nav-light-1.nav-pills .nav-link:focus {
  color: #fff;
  background-color: #2196f3;
  box-shadow: 0 12px 35px -10px rgba(0, 0, 0, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.nav-light-1.nav-pills .nav-link.disabled {
  background-color: transparent;
  box-shadow: none;
}

.nav-light-1.nav-tabs::after {
  background-color: rgba(255, 255, 255, 0.1);
}

.nav-light-2 .nav-link, .nav-light-2 .nav-link.active, .nav-light-2 .nav-link:hover, .nav-light-2 .nav-link:focus, .nav-light-2 .nav-link.disabled {
  color: #fff;
}

.nav-light-2 .nav-link::before {
  background-color: #fff;
}

.nav-light-2.nav-pills .nav-link {
  background-color: rgba(255, 255, 255, 0.14);
}

.nav-light-2.nav-pills .nav-link.active, .nav-light-2.nav-pills .nav-link:hover, .nav-light-2.nav-pills .nav-link:focus {
  background-color: #2196f3;
  box-shadow: 0 12px 35px -10px rgba(0, 0, 0, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.nav-light-2.nav-pills .nav-link.disabled {
  background-color: transparent;
  box-shadow: none;
}

/* stylelint-disable */
.nav-light-2.nav-tabs::after {
  background-color: rgba(255, 255, 255, 0.2);
}

.nav-light-2.nav-tabs .nav-link.active,
.nav-light-2.nav-tabs .nav-item.show .nav-link {
  color: #fff;
}

/* stylelint-enable */
.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 1.875rem;
}

.navbar-nav .nav-link {
  padding-top: 0.6875rem;
  padding-bottom: 0.6875rem;
  font-size: 0.875rem;
}

.navbar-nav .nav-link::before {
  display: none;
}

.navbar-nav .nav-link i {
  margin-right: 0.5rem;
}

.navbar-nav .nav-link.disabled {
  /* stylelint-disable-next-line declaration-no-important */
  background-color: transparent !important;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  outline: 0;
}

/* stylelint-disable */
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm .nav-item {
    margin-right: 0.3125rem;
  }
  .navbar-expand-sm .nav-item:last-child {
    margin-right: 0;
  }
  .navbar-expand-sm .nav-item > .nav-link, .navbar-expand-sm .nav-item.nav-link {
    border-radius: 0.1875rem;
    transition: all 0.2s ease-in-out;
  }
  .navbar-expand-sm .nav-item.active > .nav-link, .navbar-expand-sm .nav-item.active.nav-link, .navbar-expand-sm .nav-item:hover > .nav-link, .navbar-expand-sm .nav-item:hover.nav-link, .navbar-expand-sm .nav-item:focus > .nav-link, .navbar-expand-sm .nav-item:focus.nav-link {
    background-color: rgba(255, 255, 255, 0.2);
  }
  .navbar-expand-sm.navbar-light .nav-item.active > .nav-link, .navbar-expand-sm.navbar-light .nav-item.active.nav-link, .navbar-expand-sm.navbar-light .nav-item:hover > .nav-link, .navbar-expand-sm.navbar-light .nav-item:hover.nav-link, .navbar-expand-sm.navbar-light .nav-item:focus > .nav-link, .navbar-expand-sm.navbar-light .nav-item:focus.nav-link {
    background-color: #ecf0f1;
  }
  .navbar-expand-sm.navbar-round .nav-item > .nav-link, .navbar-expand-sm.navbar-round .nav-item.nav-link {
    border-radius: 1.3125rem;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .nav-item {
    margin-right: 0.3125rem;
  }
  .navbar-expand-md .nav-item:last-child {
    margin-right: 0;
  }
  .navbar-expand-md .nav-item > .nav-link, .navbar-expand-md .nav-item.nav-link {
    border-radius: 0.1875rem;
    transition: all 0.2s ease-in-out;
  }
  .navbar-expand-md .nav-item.active > .nav-link, .navbar-expand-md .nav-item.active.nav-link, .navbar-expand-md .nav-item:hover > .nav-link, .navbar-expand-md .nav-item:hover.nav-link, .navbar-expand-md .nav-item:focus > .nav-link, .navbar-expand-md .nav-item:focus.nav-link {
    background-color: rgba(255, 255, 255, 0.2);
  }
  .navbar-expand-md.navbar-light .nav-item.active > .nav-link, .navbar-expand-md.navbar-light .nav-item.active.nav-link, .navbar-expand-md.navbar-light .nav-item:hover > .nav-link, .navbar-expand-md.navbar-light .nav-item:hover.nav-link, .navbar-expand-md.navbar-light .nav-item:focus > .nav-link, .navbar-expand-md.navbar-light .nav-item:focus.nav-link {
    background-color: #ecf0f1;
  }
  .navbar-expand-md.navbar-round .nav-item > .nav-link, .navbar-expand-md.navbar-round .nav-item.nav-link {
    border-radius: 1.3125rem;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .nav-item {
    margin-right: 0.3125rem;
  }
  .navbar-expand-lg .nav-item:last-child {
    margin-right: 0;
  }
  .navbar-expand-lg .nav-item > .nav-link, .navbar-expand-lg .nav-item.nav-link {
    border-radius: 0.1875rem;
    transition: all 0.2s ease-in-out;
  }
  .navbar-expand-lg .nav-item.active > .nav-link, .navbar-expand-lg .nav-item.active.nav-link, .navbar-expand-lg .nav-item:hover > .nav-link, .navbar-expand-lg .nav-item:hover.nav-link, .navbar-expand-lg .nav-item:focus > .nav-link, .navbar-expand-lg .nav-item:focus.nav-link {
    background-color: rgba(255, 255, 255, 0.2);
  }
  .navbar-expand-lg.navbar-light .nav-item.active > .nav-link, .navbar-expand-lg.navbar-light .nav-item.active.nav-link, .navbar-expand-lg.navbar-light .nav-item:hover > .nav-link, .navbar-expand-lg.navbar-light .nav-item:hover.nav-link, .navbar-expand-lg.navbar-light .nav-item:focus > .nav-link, .navbar-expand-lg.navbar-light .nav-item:focus.nav-link {
    background-color: #ecf0f1;
  }
  .navbar-expand-lg.navbar-round .nav-item > .nav-link, .navbar-expand-lg.navbar-round .nav-item.nav-link {
    border-radius: 1.3125rem;
  }
}

@media (max-width: 1279.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1280px) {
  .navbar-expand-xl .nav-item {
    margin-right: 0.3125rem;
  }
  .navbar-expand-xl .nav-item:last-child {
    margin-right: 0;
  }
  .navbar-expand-xl .nav-item > .nav-link, .navbar-expand-xl .nav-item.nav-link {
    border-radius: 0.1875rem;
    transition: all 0.2s ease-in-out;
  }
  .navbar-expand-xl .nav-item.active > .nav-link, .navbar-expand-xl .nav-item.active.nav-link, .navbar-expand-xl .nav-item:hover > .nav-link, .navbar-expand-xl .nav-item:hover.nav-link, .navbar-expand-xl .nav-item:focus > .nav-link, .navbar-expand-xl .nav-item:focus.nav-link {
    background-color: rgba(255, 255, 255, 0.2);
  }
  .navbar-expand-xl.navbar-light .nav-item.active > .nav-link, .navbar-expand-xl.navbar-light .nav-item.active.nav-link, .navbar-expand-xl.navbar-light .nav-item:hover > .nav-link, .navbar-expand-xl.navbar-light .nav-item:hover.nav-link, .navbar-expand-xl.navbar-light .nav-item:focus > .nav-link, .navbar-expand-xl.navbar-light .nav-item:focus.nav-link {
    background-color: #ecf0f1;
  }
  .navbar-expand-xl.navbar-round .nav-item > .nav-link, .navbar-expand-xl.navbar-round .nav-item.nav-link {
    border-radius: 1.3125rem;
  }
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .nav-item {
  margin-right: 0.3125rem;
}

.navbar-expand .nav-item:last-child {
  margin-right: 0;
}

.navbar-expand .nav-item > .nav-link, .navbar-expand .nav-item.nav-link {
  border-radius: 0.1875rem;
  transition: all 0.2s ease-in-out;
}

.navbar-expand .nav-item.active > .nav-link, .navbar-expand .nav-item.active.nav-link, .navbar-expand .nav-item:hover > .nav-link, .navbar-expand .nav-item:hover.nav-link, .navbar-expand .nav-item:focus > .nav-link, .navbar-expand .nav-item:focus.nav-link {
  background-color: rgba(255, 255, 255, 0.2);
}

.navbar-expand.navbar-light .nav-item.active > .nav-link, .navbar-expand.navbar-light .nav-item.active.nav-link, .navbar-expand.navbar-light .nav-item:hover > .nav-link, .navbar-expand.navbar-light .nav-item:hover.nav-link, .navbar-expand.navbar-light .nav-item:focus > .nav-link, .navbar-expand.navbar-light .nav-item:focus.nav-link {
  background-color: #ecf0f1;
}

.navbar-expand.navbar-round .nav-item > .nav-link, .navbar-expand.navbar-round .nav-item.nav-link {
  border-radius: 1.3125rem;
}

/* stylelint-enable */
.card {
  margin-bottom: 2rem;
  border-radius: 0.3125rem;
}

.card > .list-group:first-child {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.card > .list-group:last-child {
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.card-img-wrapper {
  overflow: hidden;
}

.card-title a {
  color: #202020;
  transition: all 0.2s ease-in-out;
}

.card-title a:hover {
  color: #2196f3;
  text-decoration: none;
}

.card-title-light-1 a {
  color: #fff;
}

.card-title-light-2 a {
  /* stylelint-disable-next-line declaration-no-important */
  color: #fff !important;
}

.card-title-light-2 a:hover {
  opacity: 0.8;
}

.card-link {
  transition: color 0.2s ease-in-out;
}

.card-header:first-child {
  border-radius: 0.3125rem 0.3125rem 0 0;
}

.card-footer:last-child {
  border-radius: 0 0 0.3125rem 0.3125rem;
}

.card-header-tabs,
.card-header-pills {
  margin-top: 0.3125rem;
  margin-right: 0;
  margin-bottom: -0.75rem;
  margin-left: 0;
}

.card-img {
  border-radius: 0.3125rem;
}

.card-img-top {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.card-img-bottom {
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

@media (min-width: 576px) {
  .card-group > .card:only-child {
    border-radius: 0.3125rem;
  }
  .card-group > .card:only-child .card-img-top,
  .card-group > .card:only-child .card-header {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
  }
  .card-group > .card:only-child .card-img-bottom,
  .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
}

.card-gallery {
  overflow: hidden;
}

.card-gallery .card-img-overlay {
  z-index: 1;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.65);
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.card-gallery .card-gallery-meta {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  padding: 2.1875rem 1.875rem 1.1875rem;
}

.card-gallery .card-gallery-meta::after {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  transition: all 0.2s ease-in-out;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  background-repeat: repeat-x;
}

.card-gallery .card-gallery-caption .btn, .card-gallery .card-gallery-caption .card-gallery-text {
  position: relative;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.card-gallery .card-gallery-caption .btn {
  top: -30px;
}

.card-gallery .card-gallery-caption .card-gallery-text {
  bottom: -30px;
}

.card-gallery:hover .card-img-overlay {
  visibility: visible;
  opacity: 1;
}

.card-gallery:hover .card-gallery-meta::after {
  visibility: hidden;
  opacity: 0;
}

.card-gallery:hover .card-gallery-caption .btn {
  top: 0;
  opacity: 1;
}

.card-gallery:hover .card-gallery-caption .card-gallery-text {
  bottom: 0;
  opacity: 1;
}

.card-gallery-caption {
  width: 100%;
  margin-bottom: 0.625rem;
}

.card-gallery-caption .card-title {
  margin-bottom: 0.1875rem;
  font-weight: 700;
  color: #fff;
}

.card-gallery-caption .btn {
  margin-bottom: 1.875rem;
}

.card-gallery-caption .btn i {
  margin-left: 1px;
}

.card-blog-entry .card-title {
  margin: 0.75rem 0 0.5rem;
  font-size: 1.125rem;
  font-weight: 800;
}

.card-blog-entry .meta-entry {
  margin-bottom: 1.875rem;
}

.card-blog-img, .card-feed-img {
  position: relative;
  overflow: hidden;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.card-blog-img .btn, .card-feed-img .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  margin-top: -30px;
  margin-bottom: 0;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: all 0.2s ease-in-out;
}

.card-blog-img::after, .card-feed-img::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(0, 0, 0, 0.65);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.card-blog-img:hover .btn, .card-feed-img:hover .btn {
  margin-top: 0;
  opacity: 1;
}

.card-blog-img:hover::after, .card-feed-img:hover::after {
  opacity: 1;
}

.card-price-table .card-body {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
}

.card-price {
  margin-bottom: 2.25rem;
}

.card-price .currency {
  font-size: 1.5rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.25);
}

.card-price .price {
  margin: 0 0.1875rem 0 0.3125rem;
  font-size: 3.75rem;
  font-weight: 700;
  line-height: 1;
  color: #2196f3;
}

.card-price .term {
  position: relative;
  top: -3px;
}

.card-price-title {
  margin-bottom: 2.5rem;
  font-size: 1.25rem;
  font-weight: 800;
}

.card-price-title i {
  margin-right: 0.5rem;
  font-size: 1.125rem;
  color: #2196f3;
}

.card-price-list {
  padding: 0;
  margin-bottom: 2.75rem;
  list-style: none;
}

.card-price-list li {
  margin-bottom: 0.5625rem;
}

.price-table-bg-01, .price-table-bg-02, .price-table-bg-03, .price-table-bg-04 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.price-table-bg-01 {
  background-image: url("../images/card-price-table-bg-01.jpg");
}

.price-table-bg-02 {
  background-image: url("../images/card-price-table-bg-02.jpg");
}

.price-table-bg-03 {
  background-image: url("../images/card-price-table-bg-03.jpg");
}

.price-table-bg-04 {
  background-image: url("../images/card-price-table-bg-04.jpg");
}

.card-feed-item .card-body {
  padding-top: 0;
}

.card-feed-item .card-title {
  margin-bottom: 0.625rem;
  font-size: 1.125rem;
  font-weight: 700;
}

.card-feed-item .card-text a {
  transition: all 0.2s ease-in-out;
}

.card-feed-item .card-text a:hover {
  text-decoration: none;
}

.card-feed-img {
  display: block;
}

.card-feed-img::after {
  background-color: rgba(0, 0, 0, 0.35);
}

.card-feed-img .play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  font-size: 2.25rem;
  color: rgba(255, 255, 255, 0.6);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: color 0.2s ease-in-out;
}

.card-feed-img:hover .play-icon {
  color: rgba(255, 255, 255, 0.85);
}

.card-feed-author {
  margin-bottom: 1.6875rem;
}

.card-feed-link {
  position: relative;
  z-index: 1;
  margin-bottom: 0.625rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.card-feed-name {
  margin-bottom: 1px;
  font-size: 0.6875rem;
  font-weight: 600;
  text-transform: uppercase;
}

.card-feed-name a {
  color: #414141;
  transition: color 0.2s ease-in-out;
}

.card-feed-name a:hover {
  color: #2196f3;
  text-decoration: none;
}

.card-feed-date {
  font-size: 0.6875rem;
  font-style: italic;
  color: #a1a1a1;
}

.accordion {
  margin-bottom: 2rem;
}

.accordion .card {
  margin-bottom: 1.5rem;
  overflow: inherit;
  background-color: transparent;
  border: none;
  border-radius: 0;
}

.accordion .card .card-header {
  padding-right: 1.5625rem;
  padding-left: 1.5625rem;
  margin-bottom: 0;
  background-color: #2196f3;
  border: none;
  /* stylelint-disable-next-line declaration-no-important */
  border-radius: 0.1875rem !important;
}

.accordion .card .card-header .btn {
  margin-bottom: 0;
  color: #fff;
  border: none;
}

.accordion .card .card-body {
  padding-right: 1.5625rem;
  padding-left: 1.5625rem;
}

.accordion-title {
  display: inline-block;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
}

.accordion-minimal .card {
  margin-bottom: 0;
  border-radius: 0;
}

.accordion-minimal .card:last-child {
  border-bottom: 2px solid rgba(0, 0, 0, 0.09);
}

.accordion-minimal .card .card-header {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  background-color: transparent;
  border-top: 2px solid rgba(0, 0, 0, 0.09);
  /* stylelint-disable-next-line declaration-no-important */
  border-radius: 0 !important;
}

.accordion-minimal .card .card-body {
  margin-bottom: 0.1875rem;
}

.accordion-minimal .card .accordion-title {
  color: #202020;
}

.accordion-light-1.accordion-minimal .card:last-child,
.accordion-light-1.accordion-minimal .card-header {
  border-color: rgba(255, 255, 255, 0.15);
}

.accordion-light-1.accordion-minimal .accordion-title {
  color: #fff;
}

.accordion-light-2.accordion-minimal .card:last-child,
.accordion-light-2.accordion-minimal .card-header {
  border-color: rgba(255, 255, 255, 0.4);
}

.accordion-light-2.accordion-minimal .accordion-title {
  color: #fff;
}

.plain-card {
  background-color: transparent;
}

.plain-card .card-header, .plain-card .card-body, .plain-card .card-footer, .plain-card .list-group-item {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.plain-card .card-img-wrapper {
  box-shadow: 0 0.125rem 0.3rem rgba(23, 32, 42, 0.095);
  border-radius: 0.3125rem;
}

.plain-card .card-blog-img, .plain-card .card-feed-img,
.plain-card .card-feed-img {
  box-shadow: 0 0.125rem 0.3rem rgba(23, 32, 42, 0.095);
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.plain-card .card-header {
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.plain-card .card-footer {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.plain-card .list-group-item {
  background-color: transparent;
}

.breadcrumb-item {
  font-size: 0.6875rem;
  font-weight: 500;
}

.breadcrumb-item a {
  color: #a1a1a1;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.breadcrumb-item a:hover {
  color: #2196f3;
}

.breadcrumb-item.active {
  color: #a1a1a1;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  padding-right: 0.3125rem;
  padding-left: 0.3125rem;
  margin-right: 0.5rem;
  color: rgba(0, 0, 0, 0.5);
}

.breadcrumb-light-1 .breadcrumb-item.active,
.breadcrumb-light-1 .breadcrumb-item a, .breadcrumb-light-1 .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.5);
}

.breadcrumb-light-1 .breadcrumb-item a:hover {
  color: #2196f3;
}

.breadcrumb-light-2 .breadcrumb-item.active,
.breadcrumb-light-2 .breadcrumb-item a, .breadcrumb-light-2 .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.85);
}

.breadcrumb-light-2 .breadcrumb-item a:hover {
  /* stylelint-disable-next-line declaration-no-important */
  color: rgba(255, 255, 255, 0.85) !important;
  opacity: 0.8;
}

.page-link {
  min-width: 1.5625rem;
  font-size: 0.8125rem;
  text-align: center;
  background-color: transparent;
  border: none;
  border-radius: 0.1875rem;
  transition: all 0.2s ease-in-out;
}

.page-link:focus {
  background-color: #ecf0f1;
  outline: 0;
  box-shadow: none;
}

.page-item {
  margin: 0.1875rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}

.page-item.active .page-link {
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.24);
}

.page-item.disabled .page-link {
  background-color: transparent;
  opacity: 0.65;
}

.pagination-round .page-link {
  min-width: 1.625rem;
  border-radius: 0.8125rem;
}

.pagination-round .page-item:first-child .page-link {
  border-top-left-radius: 0.8125rem;
  border-bottom-left-radius: 0.8125rem;
}

.pagination-round .page-item:last-child .page-link {
  border-top-right-radius: 0.8125rem;
  border-bottom-right-radius: 0.8125rem;
}

.pagination-lg .page-link {
  min-width: 2.25rem;
  padding: 0.3125rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
}

.pagination-lg .page-item {
  margin: 0.25rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}

.pagination-lg .page-item.active .page-link {
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.23);
}

.pagination-lg.pagination-round .page-link {
  min-width: 2.3125rem;
  border-radius: 1.125rem;
}

.pagination-lg.pagination-round .page-item:first-child .page-link {
  border-top-left-radius: 1.125rem;
  border-bottom-left-radius: 1.125rem;
}

.pagination-lg.pagination-round .page-item:last-child .page-link {
  border-top-right-radius: 1.125rem;
  border-bottom-right-radius: 1.125rem;
}

.pagination-sm .page-link {
  min-width: 1.1875rem;
  padding: 0.125rem 0.3125rem;
  font-size: 0.6875rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
}

.pagination-sm .page-item {
  margin: 0.15625rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}

.pagination-sm .page-item.active .page-link {
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.24);
}

.pagination-sm.pagination-round .page-link {
  min-width: 1.25rem;
  border-radius: 0.625rem;
}

.pagination-sm.pagination-round .page-item:first-child .page-link {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

.pagination-sm.pagination-round .page-item:last-child .page-link {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.pagination-light-1 .page-link {
  color: rgba(255, 255, 255, 0.5);
}

.pagination-light-1 .page-link:hover {
  background-color: rgba(255, 255, 255, 0.06);
}

.pagination-light-1 .page-link:focus {
  color: rgba(255, 255, 255, 0.75);
  background-color: rgba(255, 255, 255, 0.12);
}

.pagination-light-1 .page-item.active .page-link {
  background-color: #2196f3;
}

.pagination-light-1 .page-item.disabled .page-link {
  color: rgba(255, 255, 255, 0.5);
}

.pagination-light-2 .page-link {
  color: #fff;
}

.pagination-light-2 .page-link:hover {
  background-color: rgba(255, 255, 255, 0.14);
}

.pagination-light-2 .page-link:focus {
  background-color: rgba(255, 255, 255, 0.25);
}

.pagination-light-2 .page-item.active .page-link {
  background-color: #2196f3;
}

.pagination-light-2 .page-item.disabled .page-link {
  color: #fff;
}

.badge {
  padding: 0.38em 0.95em 0.29em;
  line-height: inherit;
  text-transform: uppercase;
  border-radius: 0.1875rem;
  transition: background-color 0.2s ease-in-out;
}

a.badge:hover, a.badge:focus {
  box-shadow: none;
}

.btn .badge {
  padding: 0.38em 0.6em 0.24em 0.6em;
  line-height: 1;
}

.btn-sm .badge, .btn-group-sm > .btn .badge {
  top: 0;
}

.btn-lg .badge, .btn-group-lg > .btn .badge {
  top: -2px;
}

.badge-pill {
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #2196f3;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #45a7f5;
}

.badge-secondary {
  color: #fff;
  background-color: #4db6ac;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #68c1b9;
}

.badge-success {
  color: #fff;
  background-color: #66bb6a;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #80c784;
}

.badge-info {
  color: #fff;
  background-color: #81d4fa;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #a6e1fb;
}

.badge-warning {
  color: #fff;
  background-color: #fbc02d;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #fccb53;
}

.badge-danger {
  color: #fff;
  background-color: #e53935;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #e95a57;
}

.badge-light {
  color: #2c3e50;
  background-color: #f7f9f9;
}

.badge-light[href]:hover, .badge-light[href]:focus {
  color: #2c3e50;
  text-decoration: none;
  background-color: white;
}

.badge-dark {
  color: #fff;
  background-color: #1c2833;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #2a3b4c;
}

.badge-indigo {
  color: #fff;
  background-color: #1565c0;
}

.badge-indigo[href]:hover, .badge-indigo[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1977e2;
}

.badge-purple {
  color: #fff;
  background-color: #9c27b0;
}

.badge-purple[href]:hover, .badge-purple[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #b82ecf;
}

.badge-pink {
  color: #fff;
  background-color: #f06292;
}

.badge-pink[href]:hover, .badge-pink[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #f385aa;
}

.badge-orange {
  color: #fff;
  background-color: #fb8c00;
}

.badge-orange[href]:hover, .badge-orange[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #ff9d22;
}

.badge-sky {
  color: #fff;
  background-color: #64b5f6;
}

.badge-sky[href]:hover, .badge-sky[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #88c6f8;
}

.badge-navy {
  color: #fff;
  background-color: #0d47a1;
}

.badge-navy[href]:hover, .badge-navy[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1057c4;
}

.badge-berry {
  color: #fff;
  background-color: #b71c1c;
}

.badge-berry[href]:hover, .badge-berry[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #d82121;
}

.badge-rose {
  color: #fff;
  background-color: #e91e63;
}

.badge-rose[href]:hover, .badge-rose[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #ec417b;
}

.badge-orchid {
  color: #fff;
  background-color: #ba68c8;
}

.badge-orchid[href]:hover, .badge-orchid[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #c784d2;
}

.badge-blush {
  color: #fff;
  background-color: #ef5350;
}

.badge-blush[href]:hover, .badge-blush[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #f27573;
}

.badge-scarlet {
  color: #fff;
  background-color: #d32f2f;
}

.badge-scarlet[href]:hover, .badge-scarlet[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #da4f4f;
}

.badge-carrot {
  color: #fff;
  background-color: #ef6c00;
}

.badge-carrot[href]:hover, .badge-carrot[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #ff7f16;
}

.badge-dark-gray {
  color: #fff;
  background-color: #202020;
}

.badge-dark-gray[href]:hover, .badge-dark-gray[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #333333;
}

.alert-primary {
  color: #fff;
  background-color: #2196f3;
  border-color: #9dd1fa;
}

.alert-primary hr {
  border-top-color: #85c6f9;
}

.alert-primary .alert-link {
  color: #e6e6e6;
}

.alert-secondary {
  color: #fff;
  background-color: #4db6ac;
  border-color: #b1dfda;
}

.alert-secondary hr {
  border-top-color: #9fd8d1;
}

.alert-secondary .alert-link {
  color: #e6e6e6;
}

.alert-success {
  color: #fff;
  background-color: #66bb6a;
  border-color: #bce1bd;
}

.alert-success hr {
  border-top-color: #aad9ac;
}

.alert-success .alert-link {
  color: #e6e6e6;
}

.alert-info {
  color: #fff;
  background-color: #81d4fa;
  border-color: #c8ecfd;
}

.alert-info hr {
  border-top-color: #afe4fc;
}

.alert-info .alert-link {
  color: #e6e6e6;
}

.alert-warning {
  color: #fff;
  background-color: #fbc02d;
  border-color: #fde3a3;
}

.alert-warning hr {
  border-top-color: #fcdb8a;
}

.alert-warning .alert-link {
  color: #e6e6e6;
}

.alert-danger {
  color: #fff;
  background-color: #e53935;
  border-color: #f4a8a6;
}

.alert-danger hr {
  border-top-color: #f1928f;
}

.alert-danger .alert-link {
  color: #e6e6e6;
}

.alert-light {
  color: #2c3e50;
  background-color: #f7f9f9;
  border-color: #e3e5e5;
}

.alert-light hr {
  border-top-color: #d6d9d9;
}

.alert-light .alert-link {
  color: #1a252f;
}

.alert-dark {
  color: #fff;
  background-color: #1c2833;
  border-color: #9ba0a5;
}

.alert-dark hr {
  border-top-color: #8e9399;
}

.alert-dark .alert-link {
  color: #e6e6e6;
}

.alert-indigo {
  color: #fff;
  background-color: #1565c0;
  border-color: #98bbe3;
}

.alert-indigo hr {
  border-top-color: #84aede;
}

.alert-indigo .alert-link {
  color: #e6e6e6;
}

.alert-purple {
  color: #fff;
  background-color: #9c27b0;
  border-color: #d3a0dc;
}

.alert-purple hr {
  border-top-color: #ca8dd5;
}

.alert-purple .alert-link {
  color: #e6e6e6;
}

.alert-pink {
  color: #fff;
  background-color: #f06292;
  border-color: #f8bacf;
}

.alert-pink hr {
  border-top-color: #f6a3bf;
}

.alert-pink .alert-link {
  color: #e6e6e6;
}

.alert-orange {
  color: #fff;
  background-color: #fb8c00;
  border-color: #fdcc8f;
}

.alert-orange hr {
  border-top-color: #fdc176;
}

.alert-orange .alert-link {
  color: #e6e6e6;
}

.alert-sky {
  color: #fff;
  background-color: #64b5f6;
  border-color: #bbdefb;
}

.alert-sky hr {
  border-top-color: #a3d2fa;
}

.alert-sky .alert-link {
  color: #e6e6e6;
}

.alert-navy {
  color: #fff;
  background-color: #0d47a1;
  border-color: #95aed6;
}

.alert-navy hr {
  border-top-color: #83a0cf;
}

.alert-navy .alert-link {
  color: #e6e6e6;
}

.alert-berry {
  color: #fff;
  background-color: #b71c1c;
  border-color: #df9b9b;
}

.alert-berry hr {
  border-top-color: #d98888;
}

.alert-berry .alert-link {
  color: #e6e6e6;
}

.alert-rose {
  color: #fff;
  background-color: #e91e63;
  border-color: #f59cba;
}

.alert-rose hr {
  border-top-color: #f385aa;
}

.alert-rose .alert-link {
  color: #e6e6e6;
}

.alert-orchid {
  color: #fff;
  background-color: #ba68c8;
  border-color: #e1bde7;
}

.alert-orchid hr {
  border-top-color: #d9aae0;
}

.alert-orchid .alert-link {
  color: #e6e6e6;
}

.alert-blush {
  color: #fff;
  background-color: #ef5350;
  border-color: #f8b3b2;
}

.alert-blush hr {
  border-top-color: #f69c9b;
}

.alert-blush .alert-link {
  color: #e6e6e6;
}

.alert-scarlet {
  color: #fff;
  background-color: #d32f2f;
  border-color: #eca3a3;
}

.alert-scarlet hr {
  border-top-color: #e88e8e;
}

.alert-scarlet .alert-link {
  color: #e6e6e6;
}

.alert-carrot {
  color: #fff;
  background-color: #ef6c00;
  border-color: #f8be8f;
}

.alert-carrot hr {
  border-top-color: #f7b077;
}

.alert-carrot .alert-link {
  color: #e6e6e6;
}

.alert-dark-gray {
  color: #fff;
  background-color: #202020;
  border-color: #9d9d9d;
}

.alert-dark-gray hr {
  border-top-color: #909090;
}

.alert-dark-gray .alert-link {
  color: #e6e6e6;
}

.alert {
  border: none;
  border-radius: 0.25rem;
}

.alert-link {
  transition: opacity 0.2s ease-in-out;
  /* stylelint-disable-next-line declaration-no-important */
  color: inherit !important;
}

.alert-link:hover {
  text-decoration: none;
  opacity: 0.8;
}

.alert-icon {
  padding-left: 3.6875rem;
}

.alert-icon i {
  position: absolute;
  top: 0;
  left: 0;
  width: 3.6875rem;
  font-size: 1.125rem;
  line-height: 3.6875rem;
  text-align: center;
}

.alert-dismissible .close {
  width: 3.6875rem;
  height: 3.6875rem;
}

.alert-dismissible .close span::before, .alert-dismissible .close span::after {
  background-color: #fff;
}

.alert-light .close span::before, .alert-light .close span::after {
  background-color: #2c3e50;
}

.progress-bar {
  width: 0;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  transition: width 2s ease;
}

.progress-title {
  display: none;
}

.progress-title + span {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.progress-thin {
  height: 4px;
  border-radius: 0.125rem;
}

.progress-label {
  margin-bottom: 0.4375rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: #414141;
}

.progress-thick {
  height: 28px;
  border-radius: 0.1875rem;
}

.progress-thick .progress-label {
  margin-bottom: 0;
  color: #fff;
}

.progress-thick .progress-bar {
  position: relative;
}

.progress-thick .progress-bar.bg-light .progress-label {
  color: #2c3e50;
}

.progress-thick.progress-round {
  border-radius: 14px;
}

.progress-thick.progress-round .progress-bar {
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
}

.progress-thick .progress-title {
  position: absolute;
  left: 1.25rem;
}

.progress-light-1.progress {
  background-color: rgba(255, 255, 255, 0.12);
}

.progress-light-2.progress {
  background-color: rgba(255, 255, 255, 0.2);
}

.list-group {
  padding: 1.25rem 0;
  border-radius: 0.1875rem;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  background-color: transparent;
}

.list-group-item-action:hover::before, .list-group-item-action:focus::before {
  opacity: 1;
}

.list-group-item {
  margin-bottom: 0;
  border: none;
}

.list-group-item::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  content: "";
  background-color: #2196f3;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.list-group-item.disabled, .list-group-item:disabled {
  opacity: 0.65;
}

.list-group-item.disabled::before, .list-group-item:disabled::before {
  display: none;
}

.list-group-item.active {
  color: #717171;
  background-color: transparent;
  border-color: transparent;
}

.list-group-item.active::before {
  opacity: 1;
}

.list-group-item-primary {
  /* stylelint-disable */
  color: #fff !important;
  background-color: #2196f3 !important;
  /* stylelint-enable */
}

.list-group-item-primary::before {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.list-group-item-secondary {
  /* stylelint-disable */
  color: #fff !important;
  background-color: #4db6ac !important;
  /* stylelint-enable */
}

.list-group-item-secondary::before {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.list-group-item-success {
  /* stylelint-disable */
  color: #fff !important;
  background-color: #66bb6a !important;
  /* stylelint-enable */
}

.list-group-item-success::before {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.list-group-item-info {
  /* stylelint-disable */
  color: #fff !important;
  background-color: #81d4fa !important;
  /* stylelint-enable */
}

.list-group-item-info::before {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.list-group-item-warning {
  /* stylelint-disable */
  color: #fff !important;
  background-color: #fbc02d !important;
  /* stylelint-enable */
}

.list-group-item-warning::before {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.list-group-item-danger {
  /* stylelint-disable */
  color: #fff !important;
  background-color: #e53935 !important;
  /* stylelint-enable */
}

.list-group-item-danger::before {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.list-group-item-light {
  /* stylelint-disable */
  color: #2c3e50 !important;
  background-color: #f7f9f9 !important;
  /* stylelint-enable */
}

.list-group-item-light::before {
  background-color: #2196f3 !important;
}

.list-group-item-dark {
  /* stylelint-disable */
  color: #fff !important;
  background-color: #1c2833 !important;
  /* stylelint-enable */
}

.list-group-item-dark::before {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.list-group-item-indigo {
  /* stylelint-disable */
  color: #fff !important;
  background-color: #1565c0 !important;
  /* stylelint-enable */
}

.list-group-item-indigo::before {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.list-group-item-purple {
  /* stylelint-disable */
  color: #fff !important;
  background-color: #9c27b0 !important;
  /* stylelint-enable */
}

.list-group-item-purple::before {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.list-group-item-pink {
  /* stylelint-disable */
  color: #fff !important;
  background-color: #f06292 !important;
  /* stylelint-enable */
}

.list-group-item-pink::before {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.list-group-item-orange {
  /* stylelint-disable */
  color: #fff !important;
  background-color: #fb8c00 !important;
  /* stylelint-enable */
}

.list-group-item-orange::before {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.list-group-item-sky {
  /* stylelint-disable */
  color: #fff !important;
  background-color: #64b5f6 !important;
  /* stylelint-enable */
}

.list-group-item-sky::before {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.list-group-item-navy {
  /* stylelint-disable */
  color: #fff !important;
  background-color: #0d47a1 !important;
  /* stylelint-enable */
}

.list-group-item-navy::before {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.list-group-item-berry {
  /* stylelint-disable */
  color: #fff !important;
  background-color: #b71c1c !important;
  /* stylelint-enable */
}

.list-group-item-berry::before {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.list-group-item-rose {
  /* stylelint-disable */
  color: #fff !important;
  background-color: #e91e63 !important;
  /* stylelint-enable */
}

.list-group-item-rose::before {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.list-group-item-orchid {
  /* stylelint-disable */
  color: #fff !important;
  background-color: #ba68c8 !important;
  /* stylelint-enable */
}

.list-group-item-orchid::before {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.list-group-item-blush {
  /* stylelint-disable */
  color: #fff !important;
  background-color: #ef5350 !important;
  /* stylelint-enable */
}

.list-group-item-blush::before {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.list-group-item-scarlet {
  /* stylelint-disable */
  color: #fff !important;
  background-color: #d32f2f !important;
  /* stylelint-enable */
}

.list-group-item-scarlet::before {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.list-group-item-carrot {
  /* stylelint-disable */
  color: #fff !important;
  background-color: #ef6c00 !important;
  /* stylelint-enable */
}

.list-group-item-carrot::before {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.list-group-item-dark-gray {
  /* stylelint-disable */
  color: #fff !important;
  background-color: #202020 !important;
  /* stylelint-enable */
}

.list-group-item-dark-gray::before {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.close span::before, .close span::after {
  position: absolute;
  content: "";
  background-color: #313131;
}

.close {
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
}

.close:hover {
  opacity: 0.8;
}

.close:focus {
  outline: 0;
}

.close span {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
}

.close span::before {
  top: 0;
  left: 50%;
  width: 2px;
  height: 18px;
  margin-left: -1px;
}

.close span::after {
  top: 50%;
  left: 0;
  width: 18px;
  height: 2px;
  margin-top: -1px;
}

.close-white span::before, .close-white span::after {
  background-color: #fff;
}

.toast {
  border-radius: 0.3125rem;
}

.modal-content {
  overflow: hidden;
  border: none;
  box-shadow: 0 1rem 3.2rem rgba(0, 0, 0, 0.2);
  border-radius: 0.3125rem;
}

.modal-content .close {
  position: absolute;
  top: 0.375rem;
  right: 0.375rem;
  z-index: 1;
  padding: 0.9375rem;
  margin: 0;
}

.modal-header {
  padding: 0.9375rem 1.875rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: none;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.modal-bg-image {
  background: url("../images/modal-bg-image.jpg") no-repeat center;
  background-size: cover;
}

.modal-footer {
  padding: 0.9375rem 1.875rem;
  border-top: none;
}

@media (min-width: 576px) {
  .modal-dialog-signup {
    max-width: 420px;
  }
}

.tooltip-inner {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.14);
  border-radius: 0.1875rem;
}

.tooltip-light .tooltip-inner {
  color: #717171;
  background-color: #f5f7f7;
}

.tooltip-light.bs-tooltip-top .arrow::before, .tooltip-light.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #f5f7f7;
}

.tooltip-light.bs-tooltip-right .arrow::before, .tooltip-light.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #f5f7f7;
}

.tooltip-light.bs-tooltip-bottom .arrow::before, .tooltip-light.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #f5f7f7;
}

.tooltip-light.bs-tooltip-left .arrow::before, .tooltip-light.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #f5f7f7;
}

.popover {
  border: none;
  box-shadow: 0 0.3125rem 1.22rem rgba(0, 0, 0, 0.12);
  border-radius: 0.1875rem;
}

.popover .arrow::before {
  display: none;
}

.popover-header {
  position: relative;
  padding: 1.25rem 1.25rem 0.5rem;
  font-size: 0.9375rem;
  background-color: transparent;
  border-bottom: none;
  border-top-left-radius: calc(0.3125rem - 1px);
  border-top-right-radius: calc(0.3125rem - 1px);
}

.popover-header::before {
  visibility: hidden;
}

.popover-body {
  padding: 0.5rem 1.25rem 1.25rem;
}

.popover-dark.popover {
  background-color: #202020;
}

.popover-dark .popover-header {
  color: #fff;
}

.popover-dark .popover-body {
  color: rgba(255, 255, 255, 0.5);
}

.popover-dark.bs-popover-top .arrow::after, .popover-dark.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: #202020;
}

.popover-dark.bs-popover-right .arrow::after, .popover-dark.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: #202020;
}

.popover-dark.bs-popover-bottom .arrow::after, .popover-dark.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #202020;
}

.popover-dark.bs-popover-left .arrow::after, .popover-dark.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: #202020;
}

.carousel-inner {
  border-radius: 0.3125rem;
}

.carousel-control-prev,
.carousel-control-next {
  transition: opacity 0.2s ease-in-out;
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  /* stylelint-disable-next-line declaration-no-important */
  opacity: 1 !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 30px;
  height: 60px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.carousel-control-prev-icon {
  background-image: url("../svg/chevron-left-white.svg");
}

.carousel-control-next-icon {
  background-image: url("../svg/chevron-right-white.svg");
}

.carousel-nav-dark .carousel-control-prev-icon {
  background-image: url("../svg/chevron-left.svg");
}

.carousel-nav-dark .carousel-control-next-icon {
  background-image: url("../svg/chevron-right.svg");
}

.carousel-nav-sm .carousel-control-prev-icon,
.carousel-nav-sm .carousel-control-next-icon {
  width: 24px;
}

.carousel-nav-lg .carousel-control-prev-icon,
.carousel-nav-lg .carousel-control-next-icon {
  width: 40px;
}

.carousel-indicators {
  bottom: 4px;
  -ms-flex-align: end;
  align-items: flex-end;
  height: 6px;
}

.carousel-indicators li {
  transition: all 0.2s ease-in-out;
}

.carousel-indicators .active {
  height: 6px;
}

.carousel-indicator-circle {
  bottom: 9px;
  -ms-flex-align: center;
  align-items: center;
  height: 16px;
}

.carousel-indicator-circle li {
  width: 6px;
  height: 6px;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 50%;
  transition: all 0s ease-in-out;
}

.carousel-indicator-circle .active {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-left: 5px;
  background-color: transparent;
  border: 3px solid #fff;
}

.carousel-caption {
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  padding: 35px 40px 48px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  background-repeat: repeat-x;
}

.carousel-caption,
.carousel-caption h1,
.carousel-caption h2,
.carousel-caption h3,
.carousel-caption h4,
.carousel-caption h5,
.carousel-caption h6 {
  color: #fff;
}

.bg-viridian-100 {
  background-color: #2c3e50 !important;
}

a.bg-viridian-100:hover, a.bg-viridian-100:focus,
button.bg-viridian-100:hover,
button.bg-viridian-100:focus {
  background-color: #1a252f !important;
}

.bg-viridian-200 {
  background-color: #273746 !important;
}

a.bg-viridian-200:hover, a.bg-viridian-200:focus,
button.bg-viridian-200:hover,
button.bg-viridian-200:focus {
  background-color: #151d25 !important;
}

.bg-viridian-300 {
  background-color: #212f3d !important;
}

a.bg-viridian-300:hover, a.bg-viridian-300:focus,
button.bg-viridian-300:hover,
button.bg-viridian-300:focus {
  background-color: #0f161c !important;
}

.bg-viridian-400 {
  background-color: #1c2833 !important;
}

a.bg-viridian-400:hover, a.bg-viridian-400:focus,
button.bg-viridian-400:hover,
button.bg-viridian-400:focus {
  background-color: #0a0e12 !important;
}

.bg-viridian-500 {
  background-color: #17202a !important;
}

a.bg-viridian-500:hover, a.bg-viridian-500:focus,
button.bg-viridian-500:hover,
button.bg-viridian-500:focus {
  background-color: #050709 !important;
}

.bg-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-fixed {
  /* stylelint-disable-next-line declaration-no-important */
  background-attachment: fixed !important;
}

/* stylelint-disable selector-no-id, declaration-no-important */
.border-top-enable {
  border-top-width: 1px !important;
}

.border-right-enable {
  border-right-width: 1px !important;
}

.border-bottom-enable {
  border-bottom-width: 1px !important;
}

.border-left-enable {
  border-left-width: 1px !important;
}

.border-top-disable {
  border-top-width: 0 !important;
}

.border-right-disable {
  border-right-width: 0 !important;
}

.border-bottom-disable {
  border-bottom-width: 0 !important;
}

.border-left-disable {
  border-left-width: 0 !important;
}

/* stylelint-enable selector-no-id, declaration-no-important */
@media (min-width: 576px) {
  /* stylelint-disable selector-no-id, declaration-no-important */
  .border-sm-top-enable {
    border-top-width: 1px !important;
  }
  .border-sm-right-enable {
    border-right-width: 1px !important;
  }
  .border-sm-bottom-enable {
    border-bottom-width: 1px !important;
  }
  .border-sm-left-enable {
    border-left-width: 1px !important;
  }
  .border-sm-top-disable {
    border-top-width: 0 !important;
  }
  .border-sm-right-disable {
    border-right-width: 0 !important;
  }
  .border-sm-bottom-disable {
    border-bottom-width: 0 !important;
  }
  .border-sm-left-disable {
    border-left-width: 0 !important;
  }
  /* stylelint-enable selector-no-id, declaration-no-important */
}

@media (min-width: 768px) {
  /* stylelint-disable selector-no-id, declaration-no-important */
  .border-md-top-enable {
    border-top-width: 1px !important;
  }
  .border-md-right-enable {
    border-right-width: 1px !important;
  }
  .border-md-bottom-enable {
    border-bottom-width: 1px !important;
  }
  .border-md-left-enable {
    border-left-width: 1px !important;
  }
  .border-md-top-disable {
    border-top-width: 0 !important;
  }
  .border-md-right-disable {
    border-right-width: 0 !important;
  }
  .border-md-bottom-disable {
    border-bottom-width: 0 !important;
  }
  .border-md-left-disable {
    border-left-width: 0 !important;
  }
  /* stylelint-enable selector-no-id, declaration-no-important */
}

@media (min-width: 992px) {
  /* stylelint-disable selector-no-id, declaration-no-important */
  .border-lg-top-enable {
    border-top-width: 1px !important;
  }
  .border-lg-right-enable {
    border-right-width: 1px !important;
  }
  .border-lg-bottom-enable {
    border-bottom-width: 1px !important;
  }
  .border-lg-left-enable {
    border-left-width: 1px !important;
  }
  .border-lg-top-disable {
    border-top-width: 0 !important;
  }
  .border-lg-right-disable {
    border-right-width: 0 !important;
  }
  .border-lg-bottom-disable {
    border-bottom-width: 0 !important;
  }
  .border-lg-left-disable {
    border-left-width: 0 !important;
  }
  /* stylelint-enable selector-no-id, declaration-no-important */
}

@media (min-width: 1280px) {
  /* stylelint-disable selector-no-id, declaration-no-important */
  .border-xl-top-enable {
    border-top-width: 1px !important;
  }
  .border-xl-right-enable {
    border-right-width: 1px !important;
  }
  .border-xl-bottom-enable {
    border-bottom-width: 1px !important;
  }
  .border-xl-left-enable {
    border-left-width: 1px !important;
  }
  .border-xl-top-disable {
    border-top-width: 0 !important;
  }
  .border-xl-right-disable {
    border-right-width: 0 !important;
  }
  .border-xl-bottom-disable {
    border-bottom-width: 0 !important;
  }
  .border-xl-left-disable {
    border-left-width: 0 !important;
  }
  /* stylelint-enable selector-no-id, declaration-no-important */
}

.rounded-xl {
  border-radius: 0.5rem !important;
}

.rounded-ultra {
  border-radius: 1.875rem !important;
}

.swatch-blue {
  color: #fff;
  background-color: #2196f3;
}

.swatch-indigo {
  color: #fff;
  background-color: #1565c0;
}

.swatch-purple {
  color: #fff;
  background-color: #9c27b0;
}

.swatch-pink {
  color: #fff;
  background-color: #f06292;
}

.swatch-red {
  color: #fff;
  background-color: #e53935;
}

.swatch-orange {
  color: #212529;
  background-color: #fb8c00;
}

.swatch-yellow {
  color: #212529;
  background-color: #fbc02d;
}

.swatch-green {
  color: #212529;
  background-color: #66bb6a;
}

.swatch-teal {
  color: #fff;
  background-color: #4db6ac;
}

.swatch-cyan {
  color: #212529;
  background-color: #81d4fa;
}

.swatch-white {
  color: #212529;
  background-color: #fff;
}

.swatch-gray {
  color: #fff;
  background-color: #6c757d;
}

.swatch-gray-dark {
  color: #fff;
  background-color: #343a40;
}

.swatch-light-gray {
  color: #212529;
  background-color: #f7f9f9;
}

.swatch-dark-viridian {
  color: #fff;
  background-color: #1c2833;
}

.swatch-sky {
  color: #212529;
  background-color: #64b5f6;
}

.swatch-navy {
  color: #fff;
  background-color: #0d47a1;
}

.swatch-berry {
  color: #fff;
  background-color: #b71c1c;
}

.swatch-rose {
  color: #fff;
  background-color: #e91e63;
}

.swatch-orchid {
  color: #fff;
  background-color: #ba68c8;
}

.swatch-blush {
  color: #fff;
  background-color: #ef5350;
}

.swatch-scarlet {
  color: #fff;
  background-color: #d32f2f;
}

.swatch-carrot {
  color: #fff;
  background-color: #ef6c00;
}

.swatch-dark-gray {
  color: #fff;
  background-color: #202020;
}

.swatch-gray-100 {
  color: #212529;
  background-color: #f8f9fa;
}

.swatch-gray-200 {
  color: #212529;
  background-color: #e9ecef;
}

.swatch-gray-300 {
  color: #212529;
  background-color: #dee2e6;
}

.swatch-gray-400 {
  color: #212529;
  background-color: #ced4da;
}

.swatch-gray-500 {
  color: #212529;
  background-color: #adb5bd;
}

.swatch-gray-600 {
  color: #fff;
  background-color: #6c757d;
}

.swatch-gray-700 {
  color: #fff;
  background-color: #495057;
}

.swatch-gray-800 {
  color: #fff;
  background-color: #343a40;
}

.swatch-gray-900 {
  color: #fff;
  background-color: #212529;
}

/* stylelint-disable-next-line declaration-no-important */
.shadow-md {
  box-shadow: 0 0.67rem 2.15rem rgba(23, 32, 42, 0.14) !important;
}

/* stylelint-disable selector-no-id, declaration-no-important */
.mt-up50 {
  margin-top: -50px !important;
}

.mt-up75 {
  margin-top: -75px !important;
}

.mt-up100 {
  margin-top: -100px !important;
}

.mt-up125 {
  margin-top: -125px !important;
}

.mt-up150 {
  margin-top: -150px !important;
}

/* stylelint-enable selector-no-id, declaration-no-important */
@media (min-width: 576px) {
  /* stylelint-disable selector-no-id, declaration-no-important */
  .mt-sm-up50 {
    margin-top: -50px !important;
  }
  .mt-sm-up75 {
    margin-top: -75px !important;
  }
  .mt-sm-up100 {
    margin-top: -100px !important;
  }
  .mt-sm-up125 {
    margin-top: -125px !important;
  }
  .mt-sm-up150 {
    margin-top: -150px !important;
  }
  /* stylelint-enable selector-no-id, declaration-no-important */
}

@media (min-width: 768px) {
  /* stylelint-disable selector-no-id, declaration-no-important */
  .mt-md-up50 {
    margin-top: -50px !important;
  }
  .mt-md-up75 {
    margin-top: -75px !important;
  }
  .mt-md-up100 {
    margin-top: -100px !important;
  }
  .mt-md-up125 {
    margin-top: -125px !important;
  }
  .mt-md-up150 {
    margin-top: -150px !important;
  }
  /* stylelint-enable selector-no-id, declaration-no-important */
}

@media (min-width: 992px) {
  /* stylelint-disable selector-no-id, declaration-no-important */
  .mt-lg-up50 {
    margin-top: -50px !important;
  }
  .mt-lg-up75 {
    margin-top: -75px !important;
  }
  .mt-lg-up100 {
    margin-top: -100px !important;
  }
  .mt-lg-up125 {
    margin-top: -125px !important;
  }
  .mt-lg-up150 {
    margin-top: -150px !important;
  }
  /* stylelint-enable selector-no-id, declaration-no-important */
}

@media (min-width: 1280px) {
  /* stylelint-disable selector-no-id, declaration-no-important */
  .mt-xl-up50 {
    margin-top: -50px !important;
  }
  .mt-xl-up75 {
    margin-top: -75px !important;
  }
  .mt-xl-up100 {
    margin-top: -100px !important;
  }
  .mt-xl-up125 {
    margin-top: -125px !important;
  }
  .mt-xl-up150 {
    margin-top: -150px !important;
  }
  /* stylelint-enable selector-no-id, declaration-no-important */
}

.font-weight-100 {
  /* stylelint-disable-next-line declaration-no-important */
  font-weight: 100 !important;
}

.font-weight-200 {
  /* stylelint-disable-next-line declaration-no-important */
  font-weight: 200 !important;
}

.font-weight-300 {
  /* stylelint-disable-next-line declaration-no-important */
  font-weight: 300 !important;
}

.font-weight-400 {
  /* stylelint-disable-next-line declaration-no-important */
  font-weight: 400 !important;
}

.font-weight-500 {
  /* stylelint-disable-next-line declaration-no-important */
  font-weight: 500 !important;
}

.font-weight-600 {
  /* stylelint-disable-next-line declaration-no-important */
  font-weight: 600 !important;
}

.font-weight-700 {
  /* stylelint-disable-next-line declaration-no-important */
  font-weight: 700 !important;
}

.font-weight-800 {
  /* stylelint-disable-next-line declaration-no-important */
  font-weight: 800 !important;
}

.font-weight-900 {
  /* stylelint-disable-next-line declaration-no-important */
  font-weight: 900 !important;
}

/* stylelint-disable declaration-no-important */
.line-height-sm {
  line-height: 1.45 !important;
}

.line-height-md {
  line-height: 1.7 !important;
}

.line-height-lg {
  line-height: 1.95 !important;
}

/* stylelint-enable */
/* stylelint-disable declaration-no-important */
.text-black-25 {
  color: rgba(0, 0, 0, 0.25) !important;
}

.text-black-75 {
  color: rgba(0, 0, 0, 0.75) !important;
}

.text-white-25 {
  color: rgba(255, 255, 255, 0.25) !important;
}

.text-white-75 {
  color: rgba(255, 255, 255, 0.75) !important;
}

/* stylelint-enable */
.text-hover-primary:hover, .text-hover-primary:focus {
  /* stylelint-disable-next-line declaration-no-important */
  color: #6ab8f7 !important;
}

.text-hover-white:hover, .text-hover-white:focus {
  /* stylelint-disable-next-line declaration-no-important */
  color: #fff !important;
}

/* stylelint-disable declaration-no-important */
.letter-spacing-sm {
  letter-spacing: 0.1em !important;
}

.letter-spacing-md {
  letter-spacing: 0.2em !important;
}

.letter-spacing-lg {
  letter-spacing: 0.3em !important;
}

/* stylelint-enable */
.z-index-0 {
  /* stylelint-disable-next-line declaration-no-important */
  z-index: 0 !important;
}

.z-index-1 {
  /* stylelint-disable-next-line declaration-no-important */
  z-index: 1 !important;
}

.z-index-2 {
  /* stylelint-disable-next-line declaration-no-important */
  z-index: 2 !important;
}

.z-index-3 {
  /* stylelint-disable-next-line declaration-no-important */
  z-index: 3 !important;
}

.z-index-4 {
  /* stylelint-disable-next-line declaration-no-important */
  z-index: 4 !important;
}

.z-index-5 {
  /* stylelint-disable-next-line declaration-no-important */
  z-index: 5 !important;
}

.z-index-6 {
  /* stylelint-disable-next-line declaration-no-important */
  z-index: 6 !important;
}

.z-index-7 {
  /* stylelint-disable-next-line declaration-no-important */
  z-index: 7 !important;
}

.z-index-8 {
  /* stylelint-disable-next-line declaration-no-important */
  z-index: 8 !important;
}

.z-index-9 {
  /* stylelint-disable-next-line declaration-no-important */
  z-index: 9 !important;
}

.main-content {
  padding: 80px 0;
}

.page-half-content {
  padding: 15px;
}

@media (min-width: 768px) {
  .page-half-content {
    padding: 30px;
  }
}

@media (min-width: 1280px) {
  .navik-header-container > .page-half-logo-fixed {
    position: fixed;
  }
  .page-half-sticky {
    position: fixed;
    width: 50%;
  }
  .page-half-content {
    padding: 40px;
  }
}

.navik-header .logo img {
  width: 146px;
}

.navik-menu ul {
  padding-right: 0;
  padding-left: 0;
  list-style: none;
}

.navik-menu > ul > li > a {
  font-weight: 600;
}

.navik-menu .mega-menu-heading {
  line-height: 1.6;
}

.navik-header .navik-menu.menu-hover-reset ul .current-menu > a {
  color: #202020;
}

.navik-header .navik-menu.menu-hover-reset ul li:hover > a {
  color: #202020;
}

.navik-header .navik-menu.menu-hover-reset ul ul li:hover > a {
  color: #202020;
}

.header-dark .navik-menu.menu-hover-reset ul .current-menu > a {
  color: #fff;
}

.header-dark .navik-menu.menu-hover-reset ul li:hover > a {
  color: #fff;
}

.header-dark .navik-menu.menu-hover-reset ul ul li:hover > a {
  color: #fff;
}

.overlay-center-menu .submenu-vertical > ul > li ul {
  padding-left: 0;
}

.navik-menu-fixed > ul {
  padding-left: 0;
}

.navik-menu-fixed > ul li {
  list-style: none;
}

.fixed-menu-wrap > .logo img {
  max-width: 100%;
}

.navik-side-content > .logo {
  height: 47px;
}

.navik-side-content > .logo img {
  max-height: 37px;
}

@media (min-width: 768px) {
  .navik-header-overlay .logo,
  .navik-header-overlay .burger-menu {
    top: 30px;
  }
  .navik-menu-fixed > ul ul .overlay-dropdown-plus {
    top: -1px;
  }
  .navik-menu-fixed .overlay-dropdown-plus {
    top: 2px;
  }
}

@media (min-width: 1280px) {
  .navik-header .navik-menu.menu-hover-reset ul .current-menu > a {
    color: #202020;
  }
  .navik-header .navik-menu.menu-hover-reset ul li:hover > a {
    color: rgba(32, 32, 32, 0.75);
  }
  .navik-header .navik-menu.menu-hover-reset ul ul li:hover > a {
    color: #818181;
  }
  .header-dark .navik-menu.menu-hover-reset ul .current-menu > a {
    color: #fff;
  }
  .header-dark .navik-menu.menu-hover-reset ul li:hover > a {
    color: rgba(255, 255, 255, 0.75);
  }
  .header-dark .navik-menu.menu-hover-reset ul ul li:hover > a {
    color: #fff;
  }
  .header-transparent:not(.sticky) .menu-hover-reset > ul > .current-menu > a,
  .header-opacity:not(.sticky) .menu-hover-reset > ul > .current-menu > a {
    color: #fff;
  }
  .header-transparent:not(.sticky) .menu-hover-reset > ul > li:hover > a,
  .header-opacity:not(.sticky) .menu-hover-reset > ul > li:hover > a {
    color: rgba(255, 255, 255, 0.75);
  }
  .navik-menu .mega-menu-box {
    margin-bottom: 20px;
  }
  .navik-header-overlay .logo,
  .navik-header-overlay .burger-menu {
    top: 40px;
  }
}

.section-title-1, .section-title-2 {
  margin-bottom: 1rem;
}

.section-title-1::before, .section-title-1::after {
  display: block;
  width: 1px;
  height: 50px;
  content: "";
  background-color: #202020;
  /* stylelint-disable */
  margin-left: auto !important;
  margin-right: auto !important;
  /* stylelint-enable */
}

.section-title-1 {
  /* stylelint-disable-next-line declaration-no-important */
  text-align: center !important;
}

.section-title-1::before {
  margin-bottom: 2.1875rem;
}

.section-title-1::after {
  margin-top: 2.1875rem;
}

.section-title-2::before, .section-title-2::after {
  display: inline-block;
  width: 50px;
  height: 1px;
  margin-bottom: 0.35em;
  content: "";
  background-color: #202020;
}

.section-title-2::before {
  margin-right: 1rem;
}

.section-title-2::after {
  margin-left: 1rem;
}

.section-title-2.text-left::before, .section-title-2.text-right::after {
  display: none;
}

.section-title-3 {
  margin-bottom: 1.625rem;
}

.section-title-3::after {
  display: block;
  width: 60px;
  height: 3px;
  margin: 1.25rem auto 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.15);
}

.section-title-3.text-left::after {
  margin-left: 0;
}

.section-title-3.text-right::after {
  margin-right: 0;
}

.title-divider-round, .title-divider-round::before, .title-divider-round::after {
  top: 0;
  display: block;
  width: 8px;
  height: 8px;
  content: "";
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 50%;
}

.title-divider-round {
  position: relative;
  margin: 1.4375rem auto 0;
}

.title-divider-round::before {
  position: absolute;
  left: -16px;
}

.title-divider-round::after {
  position: absolute;
  right: -16px;
}

.section-title-4.text-left .title-divider-round {
  margin-left: 16px;
}

.section-title-4.text-right .title-divider-round {
  margin-right: 16px;
}

.title-light-1 {
  color: #fff;
}

.title-light-1::before, .title-light-1::after {
  background-color: rgba(255, 255, 255, 0.75);
}

.title-light-1.section-title-3::after {
  background-color: rgba(255, 255, 255, 0.25);
}

.title-light-1 .title-divider-round, .title-light-1 .title-divider-round::before, .title-light-1 .title-divider-round::after {
  background-color: rgba(255, 255, 255, 0.25);
}

.title-light-2 {
  color: #fff;
}

.title-light-2::before, .title-light-2::after {
  background-color: #fff;
}

.title-light-2.section-title-3::after {
  background-color: #fff;
}

.title-light-2 .title-divider-round, .title-light-2 .title-divider-round::before, .title-light-2 .title-divider-round::after {
  background-color: #fff;
}

@media (max-width: 767.98px) {
  .section-title-2::before {
    display: none;
  }
  .section-title-2::after {
    /* stylelint-disable-next-line declaration-no-important */
    display: block !important;
    margin: 1.25rem auto 0;
  }
  .section-title-2.text-right::after {
    margin-right: inherit;
  }
  .section-title-2.text-left::after {
    margin-left: inherit;
  }
}

.divider-1, .divider-4, .divider-2, .divider-3 {
  width: 100%;
  margin-bottom: 2rem;
}

.divider-1, .divider-4 {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.12);
}

.divider-2 {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.15);
}

.divider-3 {
  height: 3px;
  background-color: rgba(0, 0, 0, 0.08);
}

.divider-4 {
  position: relative;
}

.divider-4::before {
  position: absolute;
  top: -1px;
  left: 50%;
  width: 60px;
  height: 3px;
  margin-left: -30px;
  content: "";
  background-color: #2196f3;
}

.divider-4.divider-align-left::before {
  left: 0;
  margin-left: 0;
}

.divider-4.divider-align-right::before {
  right: 0;
  left: inherit;
  margin-left: 0;
}

.divider-light-1 {
  background-color: rgba(255, 255, 255, 0.2);
}

.divider-light-1.divider-2 {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.2);
}

.divider-light-1.divider-3 {
  background-color: rgba(255, 255, 255, 0.16);
}

.divider-light-2 {
  background-color: rgba(255, 255, 255, 0.5);
}

.divider-light-2.divider-2 {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.5);
}

.divider-light-2.divider-3 {
  background-color: rgba(255, 255, 255, 0.35);
}

.divider-light-2.divider-4::before {
  background-color: #fff;
}

.zz-separator {
  background-repeat: repeat-x;
  background-position: left center;
  background-size: auto 100%;
  opacity: 0.15;
}

.zz-separator-wide {
  height: 8px;
  background-image: url("../svg/zig-zag-wide.svg");
}

.zz-separator-narrow {
  height: 13px;
  background-image: url("../svg/zig-zag-narrow.svg");
}

.zz-separator-sm.zz-separator-wide {
  height: 5px;
}

.zz-separator-sm.zz-separator-narrow {
  height: 8.125px;
}

.zz-separator-lg.zz-separator-wide {
  height: 12.6px;
}

.zz-separator-lg.zz-separator-narrow {
  height: 20.5px;
}

.zz-separator-color.zz-separator-wide {
  background-image: url("../svg/zig-zag-wide-color.svg");
}

.zz-separator-color.zz-separator-narrow {
  background-image: url("../svg/zig-zag-narrow-color.svg");
}

.zz-separator-light-gray {
  opacity: 0.2;
}

.zz-separator-light-gray.zz-separator-wide, .zz-separator-white.zz-separator-wide {
  background-image: url("../svg/zig-zag-wide-white.svg");
}

.zz-separator-light-gray.zz-separator-narrow, .zz-separator-white.zz-separator-narrow {
  background-image: url("../svg/zig-zag-narrow-white.svg");
}

.zz-separator-dark, .zz-separator-color, .zz-separator-white {
  opacity: 1;
}

.drop-cap {
  float: left;
  margin-right: 1rem;
  font-family: "Playfair Display", serif;
  font-size: 4.0625rem;
  line-height: 3.4375rem;
  color: #1c2833;
}

.custom-list li {
  margin-bottom: 0.5rem;
}

.custom-list li:last-child {
  margin-bottom: 0;
}

.owl-theme .owl-nav {
  margin-top: 1.875rem;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 1.875rem;
}

.owl-theme .owl-nav .owl-prev,
.owl-theme .owl-nav .owl-next {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  /* stylelint-disable-next-line declaration-no-important */
  background-size: contain !important;
}

.owl-theme .owl-nav .owl-prev:hover, .owl-theme .owl-nav .owl-prev:focus,
.owl-theme .owl-nav .owl-next:hover,
.owl-theme .owl-nav .owl-next:focus {
  background-color: transparent;
}

.owl-theme .owl-nav .owl-prev span,
.owl-theme .owl-nav .owl-next span {
  display: none;
}

.owl-theme .owl-nav .owl-prev {
  /* stylelint-disable-next-line declaration-no-important */
  background-image: url("../svg/chevron-left.svg") !important;
}

.owl-theme .owl-nav .owl-next {
  /* stylelint-disable-next-line declaration-no-important */
  background-image: url("../svg/chevron-right.svg") !important;
}

.owl-theme.owl-nav-light .owl-nav .owl-prev,
.owl-theme.owl-nav-light .owl-nav .owl-next {
  opacity: 0.75;
  transition: all 0.2s ease-in-out;
}

.owl-theme.owl-nav-light .owl-nav .owl-prev:hover, .owl-theme.owl-nav-light .owl-nav .owl-prev:focus,
.owl-theme.owl-nav-light .owl-nav .owl-next:hover,
.owl-theme.owl-nav-light .owl-nav .owl-next:focus {
  opacity: 1;
}

.owl-theme.owl-nav-light .owl-nav .owl-prev {
  /* stylelint-disable-next-line declaration-no-important */
  background-image: url("../svg/chevron-left-white.svg") !important;
}

.owl-theme.owl-nav-light .owl-nav .owl-next {
  /* stylelint-disable-next-line declaration-no-important */
  background-image: url("../svg/chevron-right-white.svg") !important;
}

.owl-theme .owl-dots {
  margin-top: 0.9375rem;
}

.owl-theme .owl-dots .owl-dot span {
  position: relative;
  width: 14px;
  height: 14px;
  margin-right: 6px;
  margin-left: 6px;
  background-color: transparent;
  border: 3px solid transparent;
  transition: all 0.1s ease-in-out;
}

.owl-theme .owl-dots .owl-dot span::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6px;
  height: 6px;
  content: "";
  background-color: #202020;
  border-radius: 50%;
  transition: all 0.1s ease-in-out;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.owl-theme .owl-dots .owl-dot.active span {
  background-color: transparent;
  border-color: #202020;
}

.owl-theme .owl-dots .owl-dot.active span::before {
  /* stylelint-disable-next-line declaration-no-important */
  background-color: transparent !important;
}

.owl-theme .owl-dots .owl-dot:hover span, .owl-theme .owl-dots .owl-dot:focus span {
  background-color: transparent;
}

.owl-theme.owl-dot-light-1 .owl-dots .owl-dot span::before {
  background-color: rgba(255, 255, 255, 0.25);
}

.owl-theme.owl-dot-light-1 .owl-dots .owl-dot.active span {
  border-color: #fff;
}

.owl-theme.owl-dot-light-2 .owl-dots .owl-dot span::before {
  background-color: #fff;
}

.owl-theme.owl-dot-light-2 .owl-dots .owl-dot.active span {
  border-color: #fff;
}

.carousel-fullwidth {
  position: relative;
}

@media (min-width: 1280px) {
  .owl-theme .owl-nav {
    margin-top: 0;
  }
  .owl-theme .owl-nav .owl-prev,
  .owl-theme .owl-nav .owl-next {
    position: absolute;
    top: 50%;
    margin-top: -10px;
  }
  .owl-theme .owl-nav .owl-prev {
    left: -50px;
  }
  .owl-theme .owl-nav .owl-next {
    right: -50px;
  }
  .owl-theme .owl-dots {
    margin-top: 1.875rem;
  }
  .carousel-component[data-dots="true"] .owl-theme .owl-nav .owl-prev,
  .carousel-component[data-dots="true"] .owl-theme .owl-nav .owl-next {
    margin-top: -40px;
  }
}

.add-animate {
  opacity: 0;
}

.owl-carousel .animated.slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

.owl-carousel .animated.slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.owl-carousel .animated.fast {
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
}

.owl-carousel .animated.faster {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

.hover-item {
  position: relative;
  overflow: hidden;
}

.hover-item .hover-transition {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.hover-item figure {
  margin: 0;
}

.hover-item .hover-down {
  bottom: 65%;
  padding: 0 1.875rem 0.9375rem 1.875rem;
}

.hover-item .hover-up {
  top: 65%;
  padding: 0.3125rem 1.875rem 0 1.875rem;
}

.hover-item:hover .hover-transition {
  opacity: 1;
}

.hover-item:hover .hover-down {
  bottom: 51%;
  opacity: 1;
}

.hover-item:hover .hover-up {
  top: 51%;
  opacity: 1;
}

.hover-down,
.hover-up {
  position: absolute;
  width: 100%;
  text-align: center;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.hover-icon,
.hover-title {
  color: #fff;
}

.hover-desc {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.75);
}

.hover-scale figure {
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.hover-scale:hover figure {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.hover-border .hover-transition {
  background-color: transparent;
  transition: all 0.3s ease-in-out;
}

.hover-border .hover-transition::after {
  position: absolute;
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%;
  z-index: -1;
  content: "";
  background-color: rgba(0, 0, 0, 0.65);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.hover-border .hover-down,
.hover-border .hover-up {
  transition: all 0.15s ease-in-out;
}

.hover-border:hover .hover-transition::after {
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  opacity: 1;
}

.hover-border:hover .hover-down,
.hover-border:hover .hover-up {
  transition: all 0.15s ease-in-out;
  transition-delay: 0.15s;
}

.hover-uncaption:hover .hover-down {
  bottom: 50%;
}

.hover-uncaption .hover-down {
  padding: 0 1.875rem;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.hover-uncaption .hover-up {
  display: none;
}

.hover-unicon:hover .hover-up {
  top: 50%;
}

.hover-unicon .hover-up {
  padding: 0 1.875rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.hover-unicon .hover-down {
  display: none;
}

.hover-flip-img {
  overflow: inherit;
}

.hover-flip-img .img-front {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.2s ease-in-out;
}

.hover-flip-img:hover .img-front {
  opacity: 0;
}

.hover-box-shadow {
  overflow: inherit;
  transition: all 0.3s ease-in-out;
}

.hover-box-shadow .hover-inner-wrap {
  transition: all 0.3s ease-in-out;
}

.hover-box-shadow:hover {
  z-index: 1;
}

.hover-box-shadow:hover .hover-inner-wrap {
  /* stylelint-disable-next-line declaration-no-important */
  box-shadow: 0 1.25rem 3.75rem -0.625rem rgba(23, 32, 42, 0.165) !important;
}

.arrow-overflow-container {
  position: relative;
}

.arrow-overflow-up, .arrow-overflow-down, .arrow-overflow-right, .arrow-overflow-left {
  position: absolute;
  z-index: 3;
}

.arrow-overflow-up {
  bottom: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.arrow-overflow-down {
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.arrow-overflow-right {
  top: 50%;
  left: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.arrow-overflow-left {
  top: 50%;
  right: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (min-width: 1280px) {
  .arrow-nav-overflow {
    overflow: hidden;
  }
  .arrow-nav-overflow .carousel-control-next {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    transition: all 0.2s ease-in-out;
  }
  .arrow-nav-overflow .carousel-control-prev {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    transition: all 0.2s ease-in-out;
  }
  .arrow-nav-overflow:hover .carousel-control-next {
    opacity: 0.75;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  .arrow-nav-overflow:hover .carousel-control-prev {
    opacity: 0.75;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.meta-item {
  margin-right: 1.25rem;
  font-size: 0.6875rem;
  font-weight: 500;
  text-transform: uppercase;
}

.meta-item:last-child {
  margin-right: 0;
}

.meta-item a {
  color: #717171;
  transition: all 0.2s ease-in-out;
}

.meta-item a:hover {
  color: #2196f3;
  text-decoration: none;
}

.meta-item i {
  margin-right: 0.375rem;
  font-size: 0.875rem;
}

.meta-author {
  width: 48px;
  margin-right: 0.75rem;
}

.meta-light-1 a {
  color: rgba(255, 255, 255, 0.5);
}

.meta-light-2 a {
  /* stylelint-disable-next-line declaration-no-important */
  color: #fff !important;
}

.meta-light-2 a:hover {
  opacity: 0.8;
}

.meta-entry {
  padding: 0;
  list-style: none;
}

.meta-entry-item {
  display: inline;
  font-size: 0.6875rem;
  font-style: italic;
  text-transform: uppercase;
}

.meta-entry-item a {
  color: #a1a1a1;
  transition: all 0.2s ease-in-out;
}

.meta-entry-item a:hover {
  color: #2196f3;
  text-decoration: none;
}

.meta-entry-item + .meta-entry-item {
  padding-left: 0.4375rem;
}

.meta-entry-item + .meta-entry-item::before {
  display: inline-block;
  padding-right: 0.125rem;
  padding-left: 0.125rem;
  margin-right: 0.4375rem;
  overflow: hidden;
  line-height: 0.75;
  color: rgba(0, 0, 0, 0.5);
  content: "/";
}

.meta-entry-light-1 .meta-entry-item a, .meta-entry-light-1 .meta-entry-item + .meta-entry-item::before {
  color: rgba(255, 255, 255, 0.5);
}

.meta-entry-light-1 .meta-entry-item a:hover {
  color: #2196f3;
}

.meta-entry-light-2 .meta-entry-item a, .meta-entry-light-2 .meta-entry-item + .meta-entry-item::before {
  color: rgba(255, 255, 255, 0.85);
}

.meta-entry-light-2 .meta-entry-item a:hover {
  opacity: 0.8;
}

.author-avatar {
  width: 100%;
  height: auto;
  border-radius: 50%;
  box-shadow: 0 12px 35px -10px rgba(0, 0, 0, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.icon-info-1 {
  display: table;
}

.icon-info-1 .icon-element {
  display: table-cell;
  width: 50px;
  line-height: 1.2;
  color: #b3b6b7;
  text-align: center;
  vertical-align: top;
}

.icon-info-1 .icon-info-text {
  display: table-cell;
  vertical-align: top;
}

.icon-info-2 {
  display: table;
}

.icon-info-2 .icon-element {
  display: table-cell;
  width: 90px;
  color: #fff;
  vertical-align: top;
}

.icon-info-2 .icon-element-inner {
  width: 90px;
  height: 90px;
  background-color: #979a9a;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}

.icon-info-2 .icon-info-text {
  display: table-cell;
  vertical-align: top;
}

.icon-info-2:hover .icon-element-inner {
  box-shadow: 0 0.67rem 2.15rem rgba(23, 32, 42, 0.14);
}

.icon-info-3 .icon-title i {
  color: #b3b6b7;
}

.icon-info-4 .icon-element {
  color: #b3b6b7;
}

.icon-info-5 .icon-element {
  width: 120px;
  height: 120px;
  margin-right: auto;
  margin-left: auto;
  color: #fff;
  background-color: #979a9a;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}

.icon-info-5:hover .icon-element {
  box-shadow: 0 1rem 3.2rem rgba(23, 32, 42, 0.165);
}

.icon-info-link {
  display: block;
  color: inherit;
}

.icon-info-link:hover {
  color: inherit;
}

.testimonial-1 .tesimonial-name, .testimonial-2 .tesimonial-name, .testimonial-3 .tesimonial-name {
  font-size: 1.125rem;
  font-weight: 600;
  color: #202020;
}

.testimonial-1 .testimonial-image {
  margin-bottom: 2.5rem;
}

.testimonial-1 .testimonial-image figure {
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.testimonial-1 .testimonial-image figure img {
  border-radius: 50%;
  box-shadow: 0 0.42rem 1.22rem rgba(23, 32, 42, 0.12);
}

.testimonial-1 .testimonial-details {
  position: relative;
  padding: 2.1875rem 2.5rem;
  background-color: #f4f6f7;
}

.testimonial-1 .testimonial-details::before {
  position: absolute;
  top: -20px;
  left: 0;
  width: 0;
  height: 0;
  content: "";
  border-bottom: 20px solid #e5e8e8;
  border-left: 25px solid transparent;
}

.testimonial-2 .testimonial-image {
  margin-bottom: 2.5rem;
}

.testimonial-2 .testimonial-image figure {
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.testimonial-2 .testimonial-image figure img {
  border-radius: 50%;
  box-shadow: 0 0.42rem 1.22rem rgba(23, 32, 42, 0.12);
}

.testimonial-2 .testimonial-details {
  position: relative;
  padding: 2.1875rem 2.5rem;
  box-shadow: 0 0 5.625rem -1.5625rem rgba(23, 32, 42, 0.19);
  background-image: linear-gradient(to bottom, #fff 0%, rgba(255, 255, 255, 0) 100%);
  background-repeat: repeat-x;
  border-radius: 0.3125rem;
}

.testimonial-2 .testimonial-details::before {
  position: absolute;
  top: -20px;
  left: 50%;
  width: 0;
  height: 0;
  content: "";
  border-color: transparent transparent #fff transparent;
  border-style: solid;
  border-width: 0 11.5px 20px 11.5px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.testimonial-3 {
  padding: 1rem 0;
  text-align: center;
}

.testimonial-3 .testimonial-quote {
  margin-bottom: 3.125rem;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 500;
}

.testimonial-3 .testimonial-image {
  margin-bottom: 2.5rem;
}

.testimonial-3 .testimonial-image figure {
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.testimonial-3 .testimonial-image figure img {
  border-radius: 50%;
  box-shadow: 0 0.42rem 1.22rem rgba(23, 32, 42, 0.12);
}

.testimonial-3 .tesimonial-name span {
  display: block;
  font-size: 70%;
  font-style: italic;
  font-weight: 400;
  color: #717171;
}

.testimonial-3 .testimonial-quote-icon {
  margin-bottom: 1.875rem;
  color: #b3b6b7;
}

.testimonial-light-1.testimonial-1 .testimonial-details {
  background-color: #2c3e50;
}

.testimonial-light-1.testimonial-1 .testimonial-details::before {
  border-bottom: 20px solid rgba(44, 62, 80, 0.6);
}

.testimonial-light-1.testimonial-2 .testimonial-details {
  box-shadow: 0 0 5.625rem -1.5625rem rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to bottom, #2c3e50 0%, rgba(44, 62, 80, 0) 100%);
  background-repeat: repeat-x;
}

.testimonial-light-1.testimonial-2 .testimonial-details::before {
  border-color: transparent transparent #2c3e50 transparent;
}

.testimonial-light-2.testimonial-1 .testimonial-details {
  background-color: #f7f9f9;
}

.testimonial-light-2.testimonial-1 .testimonial-details::before {
  border-bottom: 20px solid rgba(247, 249, 249, 0.5);
}

@media (min-width: 768px) {
  .testimonial-1 {
    display: table;
    width: 100%;
  }
  .testimonial-1 .testimonial-image {
    display: table-cell;
    width: 120px;
    padding: 0 40px 0 0;
    vertical-align: top;
  }
  .testimonial-1 .testimonial-details::before {
    top: 0;
    left: -20px;
    display: table-cell;
    vertical-align: top;
    border-bottom: 25px solid #e5e8e8;
    border-left: 20px solid transparent;
  }
  .testimonial-1.testimonial-light-1 .testimonial-details::before {
    border-bottom: 25px solid rgba(44, 62, 80, 0.6);
  }
  .testimonial-1.testimonial-light-2 .testimonial-details::before {
    border-bottom: 25px solid rgba(247, 249, 249, 0.5);
  }
  .testimonial-2 {
    display: table;
    width: 100%;
  }
  .testimonial-2 .testimonial-image {
    display: table-cell;
    width: 120px;
    padding: 0 40px 0 0;
    vertical-align: top;
  }
  .testimonial-2 .testimonial-details {
    box-shadow: -3.75rem 0 5.625rem -1.875rem rgba(23, 32, 42, 0.19);
    background-image: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%);
    background-repeat: repeat-x;
  }
  .testimonial-2 .testimonial-details::before {
    top: 30px;
    left: -20px;
    display: table-cell;
    vertical-align: top;
    border-color: transparent #fff transparent transparent;
    border-style: solid;
    border-width: 11.5px 20px 11.5px 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  .testimonial-2.testimonial-light-1 .testimonial-details {
    box-shadow: -3.75rem 0 5.625rem -1.875rem rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, #2c3e50 0%, rgba(44, 62, 80, 0) 100%);
    background-repeat: repeat-x;
  }
  .testimonial-2.testimonial-light-1 .testimonial-details::before {
    border-color: transparent #2c3e50 transparent transparent;
  }
}

.info-box-1, .info-box-2, .info-box-3, .info-box-4 {
  display: block;
  overflow: hidden;
}

.info-box-1::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #000;
  opacity: 0.25;
  transition: all 0.3s ease-in-out;
}

.info-box-1 .info-box-btn {
  transition: all 0.3s ease-in-out;
}

.info-box-1 .info-box-btn-inner {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(30%);
  transform: translateY(30%);
  transition: all 0.3s ease-in-out;
  transition-delay: 0;
}

.info-box-1:hover::before {
  opacity: 0.5;
}

.info-box-1:hover .info-box-btn {
  /* stylelint-disable-next-line declaration-no-important */
  margin-bottom: 0 !important;
}

.info-box-1:hover .info-box-btn-inner {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  transition: all 0.4s ease-in-out;
  transition-delay: 0.1s;
}

.info-box-2 .card-img-overlay {
  z-index: 1;
  padding: 0;
}

.info-box-2 .info-box-details {
  padding: 1rem 1.875rem;
  background-color: #2196f3;
}

.info-box-2::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.info-box-2 .info-box-btn,
.info-box-2 > img {
  transition: all 0.3s ease-in-out;
}

.info-box-2 .info-box-btn-inner {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transition-delay: 0;
}

.info-box-2:hover::after {
  opacity: 1;
}

.info-box-2:hover .info-box-btn {
  /* stylelint-disable-next-line declaration-no-important */
  margin-bottom: 0 !important;
}

.info-box-2:hover .info-box-btn-inner {
  visibility: visible;
  opacity: 1;
  transition: all 0.4s ease-in-out;
  transition-delay: 0.1s;
}

.info-box-2:hover > img {
  -webkit-transform: translateY(-10%);
  transform: translateY(-10%);
}

.info-box-3 .hover-transition, .info-box-3 .info-box-desc, .info-box-3 .info-box-btn-inner {
  transition: all 0.3s ease-in-out;
}

.info-box-3 .card-img-overlay {
  z-index: 3;
}

.info-box-3 .info-box-details {
  position: relative;
}

.info-box-3 .info-box-details::after {
  position: absolute;
  top: -30px;
  right: -30px;
  bottom: -30px;
  left: -30px;
  z-index: -1;
  content: "";
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  background-repeat: repeat-x;
  transition: opacity 0.2s ease-in-out;
}

.info-box-3 .info-box-title > span {
  display: block;
  font-size: 1rem;
}

.info-box-3 .card-text {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  -webkit-transform: translateX(15%);
  transform: translateX(15%);
}

.info-box-3 .info-box-btn-inner {
  opacity: 0;
}

.info-box-3:hover .info-box-details::after {
  opacity: 0;
}

.info-box-3:hover .info-box-desc {
  /* stylelint-disable-next-line declaration-no-important */
  margin-bottom: 0 !important;
}

.info-box-3:hover .card-text {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition: all 0.4s ease-in-out;
  transition-delay: 0.25s;
}

.info-box-3:hover .info-box-btn-inner {
  opacity: 1;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.3s;
}

.info-box-4 .card-title, .info-box-4 .card-text, .info-box-4 .info-box-btn {
  transition: all 0.2s ease-in-out;
}

.info-box-4 .info-box-caption {
  padding: 5rem 0;
}

.info-box-4 .card-title {
  opacity: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.info-box-4 .card-text {
  opacity: 0;
  -webkit-transform: translateX(15%);
  transform: translateX(15%);
}

.info-box-4 .info-box-btn {
  position: absolute;
  bottom: 1.875rem;
  left: 0;
  width: 100%;
  padding: 0 1.875rem;
  opacity: 0;
}

.info-box-4:hover .card-title {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
  transition-delay: 0.1s;
}

.info-box-4:hover .card-text {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition: all 0.3s ease-in-out;
  transition-delay: 0.15s;
}

.info-box-4:hover .info-box-btn {
  opacity: 1;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.1s;
}

.circle-progress-inner span, .circle-progress-label {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 1.875rem;
  text-align: center;
}

.circle-progress-inner {
  position: relative;
  text-align: center;
}

.circle-progress-inner canvas {
  max-width: 100%;
  max-height: 100%;
}

.circle-progress-inner span {
  bottom: 49%;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1em;
}

.circle-progress-label {
  top: 55%;
  font-size: 0.8125rem;
  font-weight: 500;
  color: #202020;
  text-transform: uppercase;
}

.progress-default span {
  color: #202020;
}

.progress-light-1 span,
.progress-light-2 span {
  color: #fff;
}

.progress-light-1 .circle-progress-label,
.progress-light-2 .circle-progress-label {
  color: rgba(255, 255, 255, 0.75);
}

.gallery-slides .gallery-slides-hover {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity 0.2s ease-in-out;
}

.gallery-slides:hover .gallery-slides-hover {
  opacity: 1;
}

.gallery-sync-main {
  position: relative;
}

.gallery-sync-main .gallery-sync-main-item {
  position: relative;
  overflow: hidden;
}

.gallery-sync-main .gallery-sync-main-item a::before {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity 0.2s ease-in-out;
}

.gallery-sync-main:hover .gallery-sync-main-item a::before {
  opacity: 1;
}

.gallery-sync-main .nav-hide {
  display: none;
}

.gallery-sync-thumb-item {
  position: relative;
  overflow: hidden;
}

.gallery-sync-thumb-item figure {
  margin: 0;
  cursor: pointer;
}

.gallery-sync-thumb-item figure::before {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity 0.2s ease-in-out;
}

.gallery-sync-thumb-item figure:hover::before {
  opacity: 1;
}

.current .gallery-sync-thumb-item figure::before {
  opacity: 1;
}

.play-btn {
  position: relative;
  z-index: 1;
  width: 70px;
  height: 70px;
  outline: none;
}

.play-btn svg {
  position: relative;
  z-index: 1;
  width: 70px;
  height: 70px;
}

.play-btn .play-stroke {
  stroke-dashoffset: 0;
  stroke-dasharray: 300;
  stroke-width: 4px;
  transition: stroke-dashoffset 1s ease, opacity 1s ease;
}

.play-btn .play-icon {
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform: scale(.9);
  transform: scale(.9);
}

.play-btn-sm {
  width: 40px;
  height: 40px;
}

.play-btn-sm svg {
  width: 40px;
  height: 40px;
}

.play-btn-lg {
  width: 100px;
  height: 100px;
}

.play-btn-lg svg {
  width: 100px;
  height: 100px;
}

.play-btn-hover-animate svg:hover .play-stroke {
  opacity: 1;
  stroke-dashoffset: 300;
}

.play-btn-hover-animate svg:hover .play-icon {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.play-btn-auto-animate::before, .play-btn-auto-animate::after {
  position: absolute;
  top: -35%;
  left: -35%;
  width: 170%;
  height: 170%;
  content: "";
  border: 2px solid #2196f3;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 1.8s playButtonAutoAnimate linear infinite;
  animation: 1.8s playButtonAutoAnimate linear infinite;
}

.play-btn-auto-animate::before {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

@-webkit-keyframes playButtonAutoAnimate {
  0% {
    opacity: 1;
    -webkit-transform: scale(.5);
    transform: scale(.5);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@keyframes playButtonAutoAnimate {
  0% {
    opacity: 1;
    -webkit-transform: scale(.5);
    transform: scale(.5);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

.lightbox-video {
  overflow: hidden;
}

.lightbox-video .lightbox-video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.3;
  transition: opacity 0.2s ease-in-out;
}

.lightbox-video .play-btn:hover + .lightbox-video-overlay {
  opacity: 0.45;
}

.blog-container .card-blog-img::after, .blog-container .card-feed-img::after {
  display: none;
}

.card-blog-entry .category-btn {
  position: relative;
  z-index: 2;
  margin-top: -0.9375rem;
}

.blog-vertical .blog-entry {
  margin-bottom: 4.375rem;
}

.blog-vertical .blog-entry:last-child {
  margin-bottom: 0;
}

.blog-vertical .blog-entry .plain-card .card-body,
.blog-vertical .blog-entry .plain-card .card-footer {
  padding-right: 2.25rem;
  padding-left: 2.25rem;
}

.blog-vertical .blog-entry .card-title {
  font-size: 1.75rem;
}

.recent-blogs {
  padding: 0;
  list-style: none;
}

.recent-blogs .blog-title a {
  color: #202020;
}

.recent-blogs .blog-title a:hover {
  color: #6ab8f7;
}

@media (max-width: 767.98px) {
  .blog-comment {
    display: block;
    text-align: center;
  }
  .blog-comment-desc {
    text-align: left;
  }
}

.portfolio-split {
  margin-bottom: 3.75rem;
}

.portfolio-split:last-child {
  margin-bottom: 0;
}

.portfolio-split .portfolio-link {
  font-size: 0.9375rem;
  color: #202020;
  text-transform: inherit;
  transition: color 0.2s ease-in-out;
}

.portfolio-split .portfolio-link:hover {
  color: #2196f3;
}

.portfolio-split-img.hover-item .hover-transition {
  background-color: rgba(0, 0, 0, 0.65);
}

.portfolio-info {
  padding-right: 1.875rem;
  padding-left: 1.875rem;
  margin-top: -3.125rem;
}

.portfolio-info-wrapper {
  position: relative;
  z-index: 2;
  padding: 3.125rem 2.4375rem;
}

.portfolio-link {
  font-style: italic;
  font-weight: 600;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .portfolio-info {
    padding-right: 2.4375rem;
    padding-left: 2.4375rem;
    margin-top: -4.375rem;
  }
  .portfolio-info-wrapper {
    padding: 4.375rem;
  }
}

@media (min-width: 992px) {
  .portfolio-split {
    margin-bottom: 7.5rem;
  }
  .portfolio-split .portfolio-info {
    padding: 0;
    margin-top: 0;
    margin-left: -4.375rem;
  }
  .portfolio-split:nth-child(even) .portfolio-info {
    margin-right: -4.375rem;
    margin-left: 0;
  }
}

.timeline-wrapper {
  overflow: hidden;
}

.timeline-container {
  position: relative;
  padding: 2rem 0;
}

.timeline-container::before {
  position: absolute;
  top: 0;
  left: 1.125rem;
  width: 4px;
  height: 100%;
  content: "";
  background-color: #e5e8e8;
  border-radius: 2px;
}

.timeline-block {
  position: relative;
  z-index: 1;
  margin-bottom: 3.125rem;
}

.timeline-block:last-child {
  margin-bottom: 0;
}

.timeline-marker {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  color: #fff;
  border: 4px solid #fff;
  border-radius: 50%;
}

.timeline-content {
  position: relative;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0 1.25rem;
  background-color: #fff;
}

.timeline-content::before {
  position: absolute;
  top: 1rem;
  right: 100%;
  width: 0;
  height: 0;
  content: "";
  border: 9px solid transparent;
  border-right-color: #fff;
}

.timeline-date {
  font-size: 0.6875rem;
  font-style: italic;
}

.timeline-date::before {
  position: relative;
  margin-right: 0.3125rem;
  content: "-";
}

.timeline-dark::before {
  background-color: rgba(255, 255, 255, 0.25);
}

.timeline-dark .timeline-content {
  background-color: #2c3e50;
}

.timeline-dark .timeline-content::before {
  border-right-color: #2c3e50;
}

@media (max-width: 991.98px) {
  .timeline-marker,
  .timeline-content,
  .timeline-date {
    -webkit-animation-name: none;
    animation-name: none;
  }
}

@media (min-width: 768px) {
  .timeline-date {
    font-size: 0.875rem;
  }
}

@media (min-width: 992px) {
  .timeline-container::before {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .timeline-block:nth-child(odd) .timeline-content::before {
    right: auto;
    left: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-left-color: #fff;
  }
  .timeline-block:nth-child(even) {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .timeline-block:nth-child(even) .timeline-marker {
    margin-right: calc(7% - 50px);
  }
  .timeline-block:nth-child(even) .timeline-content {
    margin-right: 1.25rem;
    margin-left: 0;
  }
  .timeline-block:nth-child(even) .timeline-date {
    right: 122%;
    left: auto;
    text-align: right;
  }
  .timeline-marker {
    width: 60px;
    height: 60px;
    margin-left: calc(7% - 50px);
  }
  .timeline-marker > i {
    font-size: 1.33em;
  }
  .timeline-content {
    -ms-flex-positive: 0;
    flex-grow: 0;
    width: 43%;
    margin-right: 0;
  }
  .timeline-content::before {
    top: 1.25rem;
  }
  .timeline-date {
    position: absolute;
    top: 20px;
    left: 122%;
    width: 100%;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
  }
  .timeline-date::before {
    display: none;
  }
  .timeline-dark .timeline-block:nth-child(odd) .timeline-content::before {
    border-left-color: #2c3e50;
  }
}

.client-box figure::before, .client-box figure::after {
  position: absolute;
  right: 50%;
  bottom: 0;
  left: 50%;
  z-index: 1;
  height: 4px;
  content: "";
  background-color: #2196f3;
  transition: all 0.2s ease-in-out;
}

.client-box figure:hover::before {
  left: 0;
}

.client-box figure:hover::after {
  right: 0;
}

.client-grayscale img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
}

.client-grayscale:hover img {
  -webkit-filter: none;
  filter: none;
  opacity: 1;
}

.contact-box .divider-4::before {
  background-color: #fff;
}

.contact-info-list .contact-info-item {
  margin-bottom: 2rem;
}

.contact-info-list .contact-info-item:last-child {
  margin-bottom: 0;
}

.contact-info-list .contact-info-item a:hover {
  text-decoration: underline;
}

.map-wrapper {
  position: relative;
}

.map-container {
  height: 300px;
}

.map-container > iframe {
  border: none;
}

@media (min-width: 768px) {
  .map-container {
    height: 460px;
  }
}

@media (min-width: 992px) {
  .map-wrapper {
    height: 570px;
  }
  .map-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .map-info-box {
    position: absolute;
    top: 50%;
    width: 100%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.widget {
  margin-bottom: 3.125rem;
}

.widget:last-child {
  margin-bottom: 0;
}

.widget-title {
  margin-bottom: 2rem;
}

.sidebar .widget:not(:last-child) {
  margin-bottom: 5rem;
}

.sidebar .widget-title {
  margin-bottom: 3rem;
}

.widget-gallery-feed {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.widget-gallery-feed .gallery-feed-item {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-bottom: 1rem;
}

.go-to-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 99999;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  -webkit-transform: translate3d(100px, 0, 0);
  transform: translate3d(100px, 0, 0);
}

.go-to-top a {
  display: block;
  width: 43px;
  height: 43px;
  font-size: 16px;
  line-height: 43px;
  color: #fff;
  text-align: center;
  background-color: #2196f3;
  border-radius: 0.1875rem;
  box-shadow: 0 12px 35px -10px rgba(33, 150, 243, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
  transition: all 0.2s ease-in-out;
}

.go-to-top a:hover, .go-to-top a:focus {
  background-color: #45a7f5;
  box-shadow: 0 1px 9px 0 rgba(33, 150, 243, 0.5);
}

.go-to-top.go-top-fadeInRight {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.go-to-top-dark a {
  background-color: #202020;
  box-shadow: 0 12px 35px -10px rgba(32, 32, 32, 0.55), 0 8px 10px -5px rgba(0, 0, 0, 0.09), 0 4px 25px -2px rgba(0, 0, 0, 0.14);
}

.go-to-top-dark a:hover, .go-to-top-dark a:focus {
  background-color: #333333;
  box-shadow: 0 1px 9px 0 rgba(32, 32, 32, 0.5);
}

@media (min-width: 768px) {
  .go-to-top {
    right: 40px;
    bottom: 40px;
  }
}

.preloader-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.preloader-wrapper {
  position: relative;
  width: 100px;
  height: 100px;
}

.preloader-border {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px rgba(33, 150, 243, 0.1);
}

.preloader-line-mask {
  position: absolute;
  width: 50%;
  height: 100%;
  overflow: hidden;
  -webkit-transform-origin: 50px 50px;
  transform-origin: 50px 50px;
  -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), black);
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), black);
  -webkit-animation: preloadSpinner 1.2s infinite linear;
  animation: preloadSpinner 1.2s infinite linear;
}

.preloader-line {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px #2196f3;
}

@-webkit-keyframes preloadSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes preloadSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.preloader-dark .preloader-border {
  box-shadow: inset 0 0 0 2px rgba(32, 32, 32, 0.1);
}

.preloader-dark .preloader-line {
  box-shadow: inset 0 0 0 2px rgba(32, 32, 32, 0.7);
}

.fancybox-container {
  z-index: 999999;
}

.page-header-block-height {
  /* stylelint-disable-next-line declaration-no-important */
  min-height: 360px !important;
}

.banner-slides-wrapper {
  width: 100%;
}

.banner-slides-container .owl-item:not(.active) .slide-animate {
  -webkit-animation-name: none;
  animation-name: none;
}

.banner-slides-container .owl-item img {
  width: auto;
}

.banner-slides-container .owl-dots {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0 0 25px;
}

.banner-slides-container .owl-nav {
  margin-top: 0;
}

.banner-slides-container .owl-nav .owl-prev,
.banner-slides-container .owl-nav .owl-next {
  display: none;
}

@media (min-width: 768px) {
  .page-header-block-height {
    /* stylelint-disable-next-line declaration-no-important */
    min-height: 600px !important;
  }
}

@media (min-width: 1280px) {
  .page-header-block-height {
    /* stylelint-disable-next-line declaration-no-important */
    min-height: 800px !important;
  }
}

.page-title {
  position: relative;
  min-height: 240px;
}

.page-title::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
}

.page-title.page-title-no-overlay::after {
  display: none;
}

.page-title-container {
  position: relative;
  z-index: 1;
}

@media (min-width: 768px) {
  .page-title {
    min-height: 360px;
  }
}

@media (min-width: 992px) {
  .page-title {
    min-height: 420px;
  }
}

.footer-bottom {
  position: relative;
}

.footer-bottom::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(0, 0, 0, 0.3);
}

.footer-bottom.footer-bottom-no-overlay::after {
  display: none;
}

.footer-bottom-light::after {
  background-color: rgba(0, 0, 0, 0.03);
}

.footer-bottom-container {
  position: relative;
  z-index: 1;
}
/*# sourceMappingURL=style.css.map */
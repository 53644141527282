//
// Layouts
//

.main-content {
  padding: $content-section-py 0;
}

//
// Page half layout
//

.page-half-content {
  padding: $page-half-content-p-sm;
}

//
// Media query breakpoint
//

@include media-breakpoint-up(md) {

  .page-half-content {
    padding: $page-half-content-p-md;
  }

}

@include media-breakpoint-up(xl) {

  .navik-header-container {
    > .page-half-logo-fixed {
      position: fixed;
    }
  }

  .page-half-sticky {
    position: fixed;
    width: 50%;
  }

  .page-half-content {
    padding: $page-half-content-p-xl;
  }
}
